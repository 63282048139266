import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { Logo } from "../../components/Logo";

import { selectLoginData, selectLoginStatus, selectLoginError, initializeAsync } from "./loginSlice";

import {
    Button,
    CssBaseline,
    TextField,
    Box,
    Typography,
    Container,
    Paper,
    Divider,
    Link,
    Snackbar,
    Alert,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";

const Login = (props) => {
    const data = useSelector(selectLoginData);
    const status = useSelector(selectLoginStatus);
    const error = useSelector(selectLoginError);
    const dispatch = useDispatch();
    const [openToster, setOpenToster] = useState(false);
    const [tosterVariant, setTosterVariant] = useState("error");
    const [tosterMessage, setTosterMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const { application } = props;

    const { passwordLessLogin, login, isAuthenticated, user, getUserDetailsNotAsync } = useAuth();

    const [authenticated, setAuthenticated] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (status === "idle") {
            dispatch(initializeAsync());
        }
    }, [status, dispatch]);

    useEffect(() => {
        const checkAuth = async () => {
            setLoaded(false);
            try {
                let value = await isAuthenticated();
                setAuthenticated(value);
            } catch {
                setAuthenticated(false);
            } finally {
                setLoaded(true);
            }
        };
        checkAuth();
    }, [location.pathname]);

    if (!loaded) {
        return null; // You can render a loading spinner or message here
    }

    if (authenticated) {
        let userData = getUserDetailsNotAsync();
        if (user && authenticated) {
            navigate("/", {
                replace: true,
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        if (data.authenticationMode === "OTP") {
            passwordLessLogin({
                email: formData.get("email"),
                callback: (failed, errorMessage) => {
                    if (failed) {
                        setTosterVariant("error");
                        setTosterMessage(errorMessage ? errorMessage : `Sorry, we don't recognize this email address`);
                        setOpenToster(true);
                    }
                    setLoading(false);
                },
                application: application,
            });
        } else {
            login({ email: formData.get("email"), password: formData.get("password") });
        }
    };

    const handleTosterClose = () => {
        setOpenToster(false);
    };

    let body = (
        <Box
            component="main"
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(249, 250, 252)",
            }}>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexGrow: 1,
                    minHeight: "100%",
                }}>
                <Container maxWidth="sm">
                    <CssBaseline />
                    <Paper
                        elevation={16}
                        sx={{
                            backgroundColor: "rgb(255, 255, 255)",
                            color: "rgb(18, 24, 40)",
                            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            borderRadius: "8px",
                            boxShadow: "rgb(100 116 139 / 12%) 0px 10px 15px",
                            backgroundImage: "none",
                            overflow: "hidden",
                            padding: "32px",
                        }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box>
                                <a>
                                    <Logo
                                        sx={{
                                            height: 40,
                                            width: 40,
                                        }}
                                    />
                                </a>
                            </Box>
                            <Typography component="h1" variant="h4">
                                Sign in
                            </Typography>
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="body1"
                                margin="1rem"
                                fontSize="0.875rem">
                                {application === "preview"
                                    ? "Sign in on the WSB Preview Portal"
                                    : application === "speaker"
                                    ? "Sign in on the WSB Speaker Portal"
                                    : "Welcome to the WSB Client Portal. Enter your email to log in. We'll send you a one-time-passcode to your email."}
                            </Typography>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} validate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                sx={{ borderRadius: "8px" }}
                                variant="outlined"
                            />
                            {data.authenticationMode !== "OTP" ? (
                                <TextField
                                    fullWidth
                                    required
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    type="password"
                                    id="password"
                                    variant="outlined"
                                />
                            ) : (
                                ""
                            )}
                            <LoadingButton
                                size="small"
                                type="submit"
                                color="primary"
                                fullWidth
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, height: "45px" }}>
                                Sign In
                            </LoadingButton>
                            {/* <Button
                                color="primary"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, height: "45px" }}
                            >
                                Sign In
                            </Button> */}

                            {data.authenticationMode !== "OTP" ? (
                                <>
                                    <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                                    <Box sx={{ textAlign: "center", margin: "1rem" }}>
                                        <Link
                                            to="/forgot-password"
                                            variant="subtitle2"
                                            underline="hover"
                                            sx={{
                                                cursor: "pointer",
                                                margin: "1rem",
                                                align: "center",
                                            }}>
                                            Forgot password
                                        </Link>
                                    </Box>
                                </>
                            ) : (
                                ""
                            )}
                        </Box>
                    </Paper>
                </Container>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openToster}
                autoHideDuration={6000}
                onClose={handleTosterClose}>
                <Alert onClose={handleTosterClose} severity={tosterVariant} variant="filled">
                    {tosterMessage}
                </Alert>
            </Snackbar>
        </Box>
    );

    let content;
    if (status === "loading") {
        content = <p>Loading...</p>;
    } else if (status === "succeeded") {
        content = body;
    } else if (status === "failed") {
        content = <p>Error : {error}</p>;
    }

    return content;
};

export { Login };
