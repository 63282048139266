import { createSlice } from "@reduxjs/toolkit";
import { update, create, query } from "../../services/salesforce";
import { post } from "../../api";

export const requestToBookSlice = createSlice({
    // Slice name, this should be always as page name.
    name: "requestToBook",

    // Initial state for the slice
    initialState: {
        data: {},
        status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },

    // Reducers will mutate the state, put all the state mutation method over here
    reducers: {
        reset: (state, action) => {
            state.data = {};
            state.error = null;
            state.status = "idle";
        },

        error: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },

        loading: (state, action) => {
            state.status = action.payload ? "loading" : "succeeded";
        },

        saveRequestData: (state, action) => {
            state.data[action.payload.key] = {
                ...state.data[action.payload.key],
                ...(action.payload.data || {}),
            };
            state.status = "succeeded";
        },
    },
});

export default requestToBookSlice.reducer;

// Export all the reducers actions here
export const { error, loading, saveRequestData, reset } = requestToBookSlice.actions;

// Public Selectors
export const selectRequestToBookData = (state) => state.requestToBook.data;
export const selectRequestToBookStatus = (state) => state.requestToBook.status;
export const selectRequestToBookError = (state) => state.requestToBook.error;

export const getProposedSpeakerAsync = (props) => async (dispatch) => {
    try {
        dispatch(loading(true));

        let result;
        let tourStatus;
        result = await query("proposedSpeaker", "proposedSpeakers", {
            recordId: props.opportunityId,
            proposalId: props.proposalId,
            orderByField: "Proposal_Group__r.Order__c, Order__c",
            application: props.application,
            contactId: props.contactId,
        });

        if (result.isSuccess) {
            dispatch(
                saveRequestData({
                    data: {
                        records: result.data,
                        tourStatus: tourStatus,
                    },
                    key: props.opportunityId,
                })
            );
        } else {
            dispatch(error(result.errorMessage));
        }
    } catch (err) {
        dispatch(error(err.message || "Something went wrong."));
    }
};

export const getRequestDataAsync = (props) => async (dispatch) => {
    try {
        dispatch(loading(true));

        const result = await post("opportunity/deltaRequestToBook", {
            calendarEntryId: props.calendarEntryId, //item.id,
            opportunityId: props.opportunityId,
        });

        if (result.isSuccess) {
            dispatch(
                saveRequestData({
                    data: {
                        calendarDataEntry: result.data,
                    },
                    key: props.opportunityId,
                })
            );
        } else {
            dispatch(error(result.errorMessage));
        }
    } catch (err) {
        dispatch(error(err.message || "Something went wrong."));
    }
};

export const saveRequestDataAsync = (props) => async (dispatch) => {
    try {
        dispatch(loading(true));

        if (props.data.id) {
            const result = await update("calendarEntry", props.data);
            if (result.isSuccess) {
                props.callback(result);
                dispatch(
                    saveRequestData({
                        data: {
                            calendarDataEntry: result.data,
                        },
                        key: props.opportunityId,
                    })
                );
            } else {
                dispatch(error(result.errorMessage));
            }
        } else {
            const result = await create("calendarEntry", props.data);
            if (result.isSuccess) {
                props.callback(result);
                dispatch(
                    saveRequestData({
                        data: {
                            calendarDataEntry: result.data,
                        },
                        key: props.opportunityId,
                    })
                );
            } else {
                dispatch(error(result.errorMessage));
            }
        }
    } catch (err) {
        dispatch(error(err.message || "Something went wrong."));
    }
};

export const submitRequestDataAsync = (props) => async (dispatch) => {
    dispatch(loading(true));

    const result = await update("proposedSpeaker", props.data);
    if (!result.isSuccess) {
        dispatch(error(result.errorMessage));
    }
};
