import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPageContent, getTimeMe, setLocalStorage, getLocalStorage } from "../../utils";
import { useTracking } from "react-tracking";
import { query, save } from "../../services/object";
import { Helmet } from "react-helmet";
import {
    selectProposalData,
    selectProposalStatus,
    selectProposalError,
    selectProposalHasAccess,
    initializeAsync,
    saveProposalAsync,
    updateOpportunityPollDetailsAsync,
    saveGroupAsync,
    updateProposalGroupAsync,
    saveRequestAvailabilityAsync,
    getProposedSpeakerAsync,
} from "./proposalSlice";

// Add custom imports here
import {
    Box,
    Card,
    Button,
    TextField,
    Typography,
    CardContent,
    MenuItem,
    Divider,
    Drawer,
    Menu,
    FormControl,
    InputLabel,
    Select,
    ToggleButtonGroup,
    ToggleButton,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Tabs,
    Tab,
    Tooltip,
    IconButton,
    CardMedia,
    Zoom,
    Fab,
    Switch,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { shadows } from "@mui/system";
import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCommentIcon from "@mui/icons-material/AddComment";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import ShareIcon from "@mui/icons-material/Share";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArticleIcon from "@mui/icons-material/Article";
import HelpIcon from "@mui/icons-material/Help";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal } from "../../components/Modal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { encrypt } from "../../services/utils";

import { config, styles } from "../../style";
import { skeleton } from "./skeleton";
import Skeleton from "@mui/material/Skeleton";
import { Speaker } from "../Speaker";
import { CalendarEntry } from "./calendarEntry";
import { useAuth } from "../../hooks/useAuth";
import { Analytics } from "aws-amplify";
import Joyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from "react-joyride";
import { CopyClipboardText } from "../../components/CopyClipboardText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { get, post } from "../../api";
import pollImage from "../../assets/images/poll.png";
import "./style.css";

import { getCanadaStates, getUSStates } from "./utils";
import { describe } from "../../services/salesforce";

//Authentication library for purpose of grabbing tour information
import { Auth } from "aws-amplify";
import { Badge } from "../../components/Badge";
import { ArrowOutward, Info, InfoOutlined, OpenInFull, RateReview, Try } from "@mui/icons-material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { RequestToBook } from "./requestToBook";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { getFileUrl } from "../../services/s3";
import { ProposalDocxGenerator } from "../../components/ProposalDocxGenarator";
import { ShareProposal } from "./shareProposal";
import { Poll } from "./poll";
import { PollResult } from "./pollResult";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TourIcon from "@mui/icons-material/Tour";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

async function updateUserTourStatus(status) {
    let tourInfo = {
        status: status,
        tourVersion: "1",
        tourDate: Date.now().toString(),
    };
    const data = {
        "custom:tour_status": status,
        "custom:tour_complete": Date.now().toString(),
        "custom:tour_version": "1",
    };
    saveCustomAttributes(status, tourInfo, data);
}
async function updateShortlistTourStatus(status) {
    const data = {
        "custom:shortlist_status": status,
    };
    saveCustomAttributes(status, "", data);
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        shortListTourCompleted = true;
    }
}

async function updateBookingTourStatus(status) {
    const data = {
        "custom:booking_status": status,
    };
    saveCustomAttributes(status, "", data);
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        bookingTourCompleted = true;
    }
}

async function saveCustomAttributes(status, tourInfo, data) {
    if (!sharedProposal) {
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, data);

            if (tourInfo) {
                Analytics.record({
                    name: "tourEvent",
                    attributes: tourInfo,
                }).then((result) => {
                    // console.log('Analytics Record: ', result)
                });
            }
        }
    }
}
let shortListTourCompleted = false;
let bookingTourCompleted = false;
let sharedProposal;
const Proposal = (props) => {
    let { shared, application } = props;
    sharedProposal = shared;
    const data = useSelector(selectProposalData);
    const status = useSelector(selectProposalStatus);
    const error = useSelector(selectProposalError);
    const hasAccess = useSelector(selectProposalHasAccess);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { recordId, proposalId } = useParams();
    const { getUserDetailsNotAsync } = useAuth();

    const { Track, trackEvent } = useTracking({ page: "Proposal" });

    const [speakerFilter, setSpeakerFilter] = useState("all");
    const [openShareLinkModal, setOpenShareLinkModal] = useState(false);
    const [sharedUrl, setSharedUrl] = useState("");
    const [loginRequiredUrl, setLoginRequiredUrl] = useState("");
    const [openSpeakerScreen, setOpenSpeakerScreen] = useState(false);
    const [selectedSpeakerId, setSelectedSpeakerId] = useState("");
    const [selectedSpeaker, setSelectedSpeaker] = useState({});
    const [openRequestAvailabilityModal, setOpenRequestAvailabilityModal] = useState(false);
    const [requestAvailabilitySpeakers, setRequestAvailabilitySpeakers] = useState(undefined);
    const [joyRideState, setJoyRideState] = useState();
    const [joyRideShortlistState, setJoyRideShortlistState] = useState();
    const [joyRideBookingState, setJoyRideBookingState] = useState();

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState(false);
    const [openConfirmBookingScreen, setOpenConfirmBookingScreen] = useState(false);
    const [eventType, setEventType] = useState("");
    const [confirmBookingRecord, setConfirmBookingRecord] = useState(undefined);
    const [unreadComments, setUnreadComments] = useState([]);
    const [bookingConfirmNote, setBookingConfirmNote] = useState("");
    const calendarEntryRef = useRef();
    const [linkCopied, setLinkCopied] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterOpen = Boolean(anchorEl);
    const [tabValue, setTabValue] = React.useState("1");
    const [calendarEntryRecord, setCalendarEntryRecord] = useState(undefined);
    const [displayShortlistJoyride, setDisplayShortlistJoyride] = useState(false);
    const [displayBookingJoyride, setDisplayBookingJoyride] = useState(false);
    const [requestAvailabilityLoadingButton, setRequestAvailabilityLoadingButton] = useState(false);
    const [confirmBookingLoadingButton, setConfirmBookingLoadingButton] = useState(false);
    const [confirmBookingCompleted, setConfirmBookingCompleted] = useState(false);
    const [saveBookingLoadingButton, setSaveBookingLoadingButton] = useState(false);
    const [requestToBookLoading, setRequestToBookLoading] = useState(false);
    const [downloadingDocument, setDownloadingDocument] = useState(false);

    const [openCommnetModal, setOpenCommnetModal] = useState(false);
    const [openPollModal, setOpenPollModal] = useState(false);
    const [shareProposalDetails, setShareProposalDetails] = useState({});

    const [loadingShareProposal, setLoadingShareProposal] = useState(false);
    const [displayPollResultModal, setDisplayPollResultModal] = useState(false);

    const [countryPicklist, setCountryPicklist] = useState();
    const handleFilterMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (filterValue) => {
        setSpeakerFilter(filterValue);
        setAnchorEl(null);
        // trackEvent(getTrackEventData('click', 'page', `Clicked on ${filterValue} filter`, {}));
    };
    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const [anchorActionEl, setAnchorActionEl] = React.useState(null);
    const openAction = Boolean(anchorActionEl);
    const handleActionClick = (event) => {
        setAnchorActionEl(event.currentTarget);
    };
    const handleActionClose = () => {
        setAnchorActionEl(null);
    };

    const ref = useRef(null);
    const shareProposalRef = useRef(null);
    const userDetails = getUserDetailsNotAsync();
    const documentGeneratorRef = useRef();
    //alert(userDetails?.userType);

    let userType = userDetails?.userType;

    if (userDetails.userType === "shared" && userDetails.participant) {
        userType = userDetails.participant?.accessLevel === "View" ? "public" : "anonymous";
        shared = userDetails.participant?.accessLevel === "View" ? true : shared;
    }

    if (
        userDetails.userType === "shared" &&
        userDetails.participant &&
        userDetails.participant?.accessLevel === "ContactRole"
    ) {
        userType = "";
        shared = false;
    }

    const opportunityData = useSelector((state) => {
        let data = state.proposal.data[recordId];
        let mapRecords = {};
        let notSelected = 0;
        if (data) {
            console.log(data?.records);
            if (data?.records?.length > 0) {
                let shortListedRecords = [];
                let rejectedRecords = [];
                let requestedAvailabilityRecords = [];
                let availableRecords = [];
                let unavailableRecords = [];
                let bookedRecords = [];
                let bookingApprovedRecords = [];

                for (let record of data?.records) {
                    if (record?.speakerDetails?.filter((item) => item.type === "Fee").length > 0) {
                        if (record.status === "Request To Book Approved") {
                            bookedRecords.push(record);
                        } else if (record.status === "Request To Book") {
                            bookedRecords.push(record);
                        } else if (record.status === "Availability Requested" && record.availability === "Requested") {
                            requestedAvailabilityRecords.push(record);
                        } else if (record.status === "Availability Requested" && record.availability === "Approved") {
                            requestedAvailabilityRecords.push(record);
                        } else if (record.status === "Availability Requested" && record.availability === "Denied") {
                            requestedAvailabilityRecords.push(record);
                        } else if (
                            record.shortlistStatus === "Shortlist" &&
                            record.status !== "Availability Requested"
                        ) {
                            shortListedRecords.push(record);
                        } else if (record.shortlistStatus === "Removed" && record.status !== "Availability Requested") {
                            rejectedRecords.push(record);
                        } else {
                            let key = "x";
                            notSelected += 1;
                            if (record.proposalGroupId) {
                                if (record.proposalGroupName !== "Uncategorized") {
                                    key = record.proposalGroupId;
                                }
                            }
                            if (!mapRecords[key]) {
                                mapRecords[key] = {
                                    group: {
                                        id: record.proposalGroupId,
                                        name: record.proposalGroupName,
                                        description: record.proposalGroupDescription,
                                    },
                                    records: [],
                                };
                            }
                            mapRecords[key]?.records?.push(record);
                        }
                    }
                }

                // console.log('shortListedRecords', shortListedRecords)

                if (requestedAvailabilityRecords.length > 0) {
                    mapRecords["Availability Requested"] = {
                        group: {
                            id: "Availability Requested",
                            name: "Availability Requested",
                            description: "Speakers with pending availability checks.",
                        },
                        records: requestedAvailabilityRecords,
                    };
                }
                if (availableRecords.length > 0) {
                    mapRecords["Available"] = {
                        group: {
                            id: "Available",
                            name: "Available Speakers",
                            description: "Speakers with confirmed availability for the dates you requested.",
                        },
                        records: availableRecords,
                    };
                }
                if (unavailableRecords.length > 0) {
                    mapRecords["Unavailable"] = {
                        group: {
                            id: "Unavailable",
                            name: "Unavailable Speakers",
                            description: "The following speakers do not have availability for the dates you requested.",
                        },
                        records: unavailableRecords,
                    };
                }
                if (bookedRecords.length > 0) {
                    mapRecords["Booked"] = {
                        group: {
                            id: "Booked",
                            name: "Booking Requests",
                        },
                        records: bookedRecords,
                    };
                }
                if (bookingApprovedRecords.length > 0) {
                    mapRecords["Booked Speakers"] = {
                        group: {
                            id: "Booked Speakers",
                            name: "Booked Speakers",
                        },
                        records: bookingApprovedRecords,
                    };
                }

                if (shortListedRecords.length > 0) {
                    mapRecords["Shortlist"] = {
                        group: {
                            id: "Shortlist",
                            name: "Shortlisted Speakers",
                            description: "Speakers you have shortlisted.",
                        },
                        records: shortListedRecords,
                    };
                }
                if (rejectedRecords.length > 0) {
                    mapRecords["Rejected"] = {
                        group: {
                            id: "Rejected",
                            name: "Removed Speakers",
                            description: "Speakers you removed from the proposal",
                        },
                        records: rejectedRecords,
                    };
                }
                if (data && !data?.tourStatus && !joyRideState) {
                    // console.log('setting up the tour')
                    setTimeout(() => {
                        if (!joyRideState) {
                            let joyRide = {
                                steps: [
                                    {
                                        target: `#${document.querySelector('[id^="agent-note"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                        content: "Your agent has included a note about your proposal. Click to open.",
                                        placement: "top",
                                    },
                                    {
                                        target: `#${document.querySelector('[id^="card"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                        content: "Click each card for more information about the speaker.",
                                        placement: "top",
                                    },
                                    {
                                        target: `#${document.querySelector('[id^="shortlist"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                        content: "Add speakers to your shortlist.",
                                        placement: "top",
                                    },
                                    {
                                        target: `#${document.querySelector('[id^="reject"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                        content: "Remove speakers from your proposal.",
                                        placement: "top",
                                    },
                                    {
                                        target: `#leave-comment-fab`,
                                        content: "Click here to leave a comment for your agent. ",
                                    },
                                    {
                                        target: `#request-speakers`,
                                        content: "Click here to request more speakers from your agent.",
                                    },
                                    {
                                        target: "#back-to-proposal",
                                        content: "Click here to view all of your proposals. ",
                                        placement: "right-end",
                                    },
                                ],
                            };
                            if (document.querySelector('[id^="add-tooltip-icon-"]')?.id) {
                                joyRide.steps.splice(3, 0, {
                                    target: `#${document.querySelector('[id^="add-tooltip-icon-"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                    content: "Learn more about what is included in the speaker's fee.",
                                    placement: "top",
                                });
                            }
                            setJoyRideState(joyRide);
                        }
                    }, 2000);
                }
            }

            return {
                ...data,
                groupedData: mapRecords,
                notSelectedRecordCount: notSelected,
            };
        }

        return data;
    });
    // console.log('opportunityData', opportunityData)

    if (opportunityData) {
        const event = new CustomEvent("dispalyOpportunityOwner", {
            detail: opportunityData,
        });

        document.dispatchEvent(event);
    }

    useEffect(() => {
        get("proposal/shareProposalDetails", {
            contactId: userDetails?.contact?.id,
            accountId: userDetails?.contact?.accountId,
            opportunityId: recordId,
        }).then((result) => {
            if (result.isSuccess) {
                setShareProposalDetails(result.data);
            }
        });
    }, []);

    useEffect(() => {
        let pageLoadTimeMe;
        document.addEventListener("closeSpeakerDrawer", handleCloseSpeakerDrawer);
        document.addEventListener("speakerAction", handleSpeakerAction);
        if (!data[recordId]) {
            dispatch(
                initializeAsync({
                    recordId,
                    shared,
                    trackEvent,
                    getTrackEventData,
                    application: userDetails?.userType,
                    contactId: userDetails?.contact?.id,
                })
            );
            dispatch(
                getProposedSpeakerAsync({
                    recordId,
                    proposalId,
                    shared,
                    application: userDetails?.userType,
                    contactId: userDetails?.contact?.id,
                })
            );
            if (!shared) {
                getUnreadCommentsPrivate();
            }
        } else {
            pageLoadTimeMe = getTimeMe();
            pageLoadTimeMe.initialize({ currentPageName: "Proposal" });
        }
        return () => {
            //alert(pageLoadTimeMe.getTimeOnPageInSeconds('Proposal'))
            if (pageLoadTimeMe) {
                trackEvent(
                    getTrackEventData(
                        "duration",
                        "page",
                        `User spent ${pageLoadTimeMe.getTimeOnPageInSeconds("Proposal")} seconds viewieng proposal`,
                        {},
                        pageLoadTimeMe.getTimeOnPageInSeconds("Proposal"),
                        "proposal"
                    )
                );
                pageLoadTimeMe.stopAllTimers();
            }

            document.removeEventListener("closeSpeakerDrawer", handleCloseSpeakerDrawer);
            document.removeEventListener("speakerAction", handleSpeakerAction);
        };
    }, [dispatch, data, getUserDetailsNotAsync, recordId, trackEvent, proposalId, opportunityData]);

    useEffect(() => {
        if (!shared) {
            getCalendarEntryDescribe();
        }
    }, []);

    const getCalendarEntryDescribe = async () => {
        const result = await describe("calendarEntry", { cacheable: true });
        if (result.isSuccess) {
            console.log(result);
            for (let field of result?.data?.fields) {
                if (field.name === "Location_Country_Global__c") {
                    setCountryPicklist(
                        field.picklistValues.filter((item) => item.label !== "Virtual / Remote Broadcast")
                    );
                }
            }
        }
    };

    // const setUser = async () => {
    //     let userDetails = await getUserDetails()
    //     setUserDetails(userDetails)
    // }

    const getUnreadCommentsPrivate = () => {
        get("comment/getUnreadCommentsPrivate", {
            applicationType: "client",
            accountId: userDetails?.contact?.accountId,
            parentId: recordId,
            loggedInUesrId: userDetails?.username,
        }).then((result) => {
            if (result.isSuccess) {
                setUnreadComments(result.data);
                //setUnreadComments([{a: 2}, {a: 4}])
            }
        });
    };

    const handleAboutClick = (event, id, record) => {
        event.stopPropagation();
        setSelectedSpeaker(record);
        setSelectedSpeakerId(id);
        setOpenSpeakerScreen(true);
        trackEvent(getTrackEventData("click", "speaker-details", `Viewed ${record.speakerName}'s profile`, record));
    };

    const getTrackEventData = (action, type, description, record, duration, page) => {
        return {
            action: action,
            type: type,
            description: description
                ? description + (shared || userType === "anonymous" ? " from Share Link" : "")
                : "",
            parentId: recordId,
            parantName: opportunityData?.opportunity?.eventName,
            speakerId: record?.speakerId,
            speakerName: record?.speakerName,
            userDetails: userDetails,
            duration: duration,
            page: page,
            shared: shared,
        };
    };

    const handleRequestAvailabilityClick = (event, id, record) => {
        event.stopPropagation();
        setSelectedSpeaker(record);
        setSelectedSpeakerId(id);
        setOpenRequestAvailabilityModal(true);
        setRequestAvailabilityLoadingButton(false);
    };

    const handleRequestAvailabilityModalClose = () => {
        setOpenRequestAvailabilityModal(false);
        setRequestAvailabilitySpeakers(undefined);
    };

    const handleSpeakerAction = (event) => {
        let record = event.detail.value;
        const action = event.detail.action;

        if (action === "shortlist") {
            setSelectedSpeaker({
                ...selectedSpeaker,
                status: "Shortlist",
                shortlistStatus: "Shortlist",
            });
            shortListProposal(event, record);
            // trackEvent(getTrackEventData('click', 'page', `Clicked on shortlist for ${record.speakerName}`, record));
        } else if (action === "remove") {
            setSelectedSpeaker({
                ...selectedSpeaker,
                status: "Remove",
                shortlistStatus: "Shortlist",
            });
            removeProposal(event, record);
            // trackEvent(getTrackEventData('click', 'page', `Clicked on remove for ${record.speakerName}`, record));
        }
    };

    const handleCloseSpeakerDrawer = (event) => {
        event.stopPropagation();
        setOpenSpeakerScreen(false);
        setSelectedSpeakerId("");
    };

    const handleClosePopoverComment = () => {
        setOpenCommnetModal(false);
        const hideEvent = new CustomEvent("hideDrawer");
        document.dispatchEvent(hideEvent);
    };

    const handlePopoverComment = (target, subject, speakerName, type, speakerId) => {
        // if (openCommnetModal) {
        //     const hideEvent = new CustomEvent("hideDrawer");
        //     document.dispatchEvent(hideEvent);
        //     setOpenCommnetModal(false);
        //     return;
        // }

        setUnreadComments([]);
        setOpenCommnetModal(true);
        const event = new CustomEvent("openDrawer", {
            detail: {
                target: target ? target : document.querySelector("#leave-comment-fab"),
                parentId: recordId,
                parentName: opportunityData?.opportunity?.name,
                subject: subject,
                type: type,
                speakerName: speakerName,
                speakerId: speakerId,
                type: "popover",
                onClose: () => {
                    setOpenCommnetModal(false);
                },
            },
        });
        document.dispatchEvent(event);
    };

    const handleOpenComment = (subject, speakerName, type, speakerId) => {
        setUnreadComments([]);
        // console.log(subject, speakerName, type);
        const event = new CustomEvent("openDrawer", {
            detail: {
                parentId: recordId,
                parentName: opportunityData?.opportunity?.name,
                subject: subject,
                type: type,
                speakerName: speakerName,
                speakerId: speakerId,
            },
        });
        document.dispatchEvent(event);
        // trackEvent(getTrackEventData('click', 'comment', `Clicked on comment ${speakerName ? `for ${speakerName}, ${subject}` : ''}`, { speakerName: speakerName, speakerId: speakerId }));
    };

    const getAgentNotes = (record) => {
        if (record?.speakerDetails?.filter((item) => item.type === "Agent Notes")?.length > 0) {
            return record?.speakerDetails?.filter((item) => item.type === "Agent Notes")[0].agentNotes;
        }
        return "";
    };

    const formatTimeWithZone = (dateToFormat) => {
        // console.log('TIME ZONE')
        // console.log(dateToFormat)
        let num = new Date().getTimezoneOffset();

        if (num < 0) {
            num = Math.abs(num);
            const hours = String(Math.floor(num / 60)).padStart(2, "0");
            const minutes = String(Math.floor(num % 60)).padStart(2, "0");
            let timeZone = `${hours}:${minutes}`;
            return format(new Date(dateToFormat + "T05:00:00+" + timeZone), "MMMM d, yyyy");
        }
        const hours = String(Math.floor(num / 60)).padStart(2, "0");
        const minutes = String(Math.floor(num % 60)).padStart(2, "0");
        let timeZone = `${hours}:${minutes}`;
        // console.log(dateToFormat + 'T05:00:00-' + timeZone)
        return format(new Date(dateToFormat + "T05:00:00-" + timeZone), "MMMM d, yyyy");
    };

    const isDateMoreThan90DaysOld = (inputDate) => {
        const currentDate = new Date();
        const givenDate = new Date(inputDate);
        const differenceInMilliseconds = currentDate - givenDate;
        const daysDifference = differenceInMilliseconds / (1000 * 3600 * 24);
        return daysDifference > 90;
    };

    const getFeeDetail = (item) => {
        let amount = item.feeAmount ? item.feeAmount : 0;
        let tooltip = "";
        let plusToolTip = "";
        if (!item.feeTBD) {
            if (item.flatTravel && item.flatTravel > 0 && item.flatTravelDisplay === "Display Flat Travel Amount") {
                amount = item.feeAmount + (item.flatTravel ? parseFloat(item.flatTravel) : 0);
                tooltip = (
                    <Tooltip
                        title={`This fee is inclusive of a ${new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                        }).format(item.flatTravel ? item.flatTravel : 0)} flat fee to cover expenses.`}
                        sx={{ mt: "-2px" }}>
                        <IconButton size="small">
                            <Info fontSize="inherit"></Info>
                        </IconButton>
                    </Tooltip>
                );
                plusToolTip = (
                    <>
                        {item.feeCustomClientExtentWithFlatTravel && (
                            <Tooltip
                                title={"Client to provide " + item.feeCustomClientExtentWithFlatTravel}
                                sx={{ mt: "-2px" }}>
                                <IconButton size="small">
                                    <AddCircleOutlineIcon
                                        id={"add-tooltip-icon-" + item.id}
                                        fontSize="inherit"></AddCircleOutlineIcon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            } else if (
                item.flatTravel &&
                item.flatTravel > 0 &&
                item.flatTravelDisplay === "Display Flat Travel Option Language (No Amount)"
            ) {
                amount = item.feeAmount;
                tooltip = (
                    <Tooltip title={`A fixed flat fee is available to cover travel expenses.`} sx={{ mt: "-2px" }}>
                        <IconButton size="small">
                            <Info fontSize="inherit"></Info>
                        </IconButton>
                    </Tooltip>
                );
                plusToolTip = (
                    <>
                        {item.feeClientExtent && (
                            <Tooltip title={"Client to provide " + item.feeClientExtent} sx={{ mt: "-2px" }}>
                                <IconButton size="small">
                                    <AddCircleOutlineIcon
                                        id={"add-tooltip-icon-" + item.id}
                                        fontSize="inherit"></AddCircleOutlineIcon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            } else if (item.flatTravel && item.flatTravel > 0 && item.flatTravelDisplay === "Hide Flat Travel") {
                amount = item.feeAmount;
                plusToolTip = (
                    <>
                        {item.feeClientExtent && (
                            <Tooltip title={"Client to provide " + item.feeClientExtent} sx={{ mt: "-2px" }}>
                                <IconButton size="small">
                                    <AddCircleOutlineIcon
                                        id={"add-tooltip-icon-" + item.id}
                                        fontSize="inherit"></AddCircleOutlineIcon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            } else {
                amount = item.feeAmount;
                plusToolTip = (
                    <>
                        {item.feeClientExtent && (
                            <Tooltip title={"Client to provide " + item.feeClientExtent} sx={{ mt: "-2px" }}>
                                <IconButton size="small">
                                    <AddCircleOutlineIcon
                                        id={"add-tooltip-icon-" + item.id}
                                        fontSize="inherit"></AddCircleOutlineIcon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            }
        }

        return (
            <Grid
                key={item.id}
                sx={{
                    m: "0 -10px 10px -10px",
                    p: "5px 10px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                }}
                container
                wrap="nowrap"
                spacing={0}
                justifyContent="space-between">
                <Grid xs={6} item>
                    {item.feeName}
                    {tooltip}
                </Grid>
                <Grid item>
                    {!item.feeTBD && (
                        <>
                            {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 0,
                            }).format(amount)}
                        </>
                    )}
                    {item.feeTBD && (
                        <Typography sx={{ fontSize: "11px", textAlign: "end" }}>
                            Fee to be confirmed upon interest.
                        </Typography>
                    )}
                    {plusToolTip}
                </Grid>
            </Grid>
        );
    };

    const displayFeeDetails = (record) => {
        let details = record?.speakerDetails?.filter((item) => item.type === "Fee");
        if (details?.length > 0) {
            let displayMore = details.length > 4;
            let displayRecords = displayMore ? details.slice(0, 3) : details;

            return (
                <>
                    {opportunityData?.opportunity?.isPortalFeesHistorical ? (
                        <Box>
                            <Alert severity="info" sx={{ mb: "10px" }}>
                                Fees in your proposal are over 90 days old. Please reach out to your WSB point of
                                contact for updated fees.
                            </Alert>
                        </Box>
                    ) : (
                        <>
                            {displayRecords.map((item) => (
                                <>{getFeeDetail(item)}</>
                            ))}
                            {displayMore && (
                                <Box
                                    sx={{
                                        marginTop: "10px",
                                        fontSize: "14px",
                                        color: config.errorColor,
                                    }}>
                                    Show more
                                </Box>
                            )}
                        </>
                    )}
                </>
            );
        } else {
            return "Speak to your WSB Agent for more information on fees.";
        }
    };

    const displayActionButtons = (record) => {
        return (
            <Box>
                {record.status === "Request To Book Approved" && (
                    <Box>
                        <Alert severity="success" sx={{ mb: "10px" }}>
                            Your request to book {record.speakerName} is approved.
                        </Alert>
                    </Box>
                )}

                {!record?.availability &&
                    record?.status !== "Availability Requested" &&
                    record?.shortlistStatus !== "Removed" && (
                        <Box>
                            <Button
                                onClick={(event) => shortListProposal(event, record)}
                                sx={{
                                    ...styles.outlinedSuccessButton,
                                    width: "100%",
                                    background: record?.shortlistStatus === "Shortlist" ? green[50] : "",
                                    mb: "10px",
                                }}
                                startIcon={<StarIcon fontSize="small" />}
                                variant="outlined"
                                data-amplify-analytics-on="click"
                                data-amplify-analytics-name="shortlistedSpeaker"
                                data-amplify-analytics-attrs={`speakerName:${record.speakerName},speakerId:${record.speakerId}`}
                                id={`shortlist-${record.speakerId}`}
                                disabled={userType === "user" || shared}>
                                {record?.shortlistStatus === "Shortlist" ? "Shortlisted" : "Shortlist"}
                            </Button>
                        </Box>
                    )}
                {!record?.availability &&
                    record?.status !== "Availability Requested" &&
                    record?.shortlistStatus !== "Shortlist" && (
                        <Box>
                            <Button
                                onClick={(event) => removeProposal(event, record)}
                                sx={{
                                    ...styles.outlinedErrorButton,
                                    width: "100%",
                                    background: record?.shortlistStatus === "Removed" ? red[50] : "",
                                    mb: "10px",
                                }}
                                disabled={userType === "user" || shared}
                                startIcon={<CloseIcon fontSize="small" />}
                                variant="outlined"
                                data-amplify-analytics-on="click"
                                data-amplify-analytics-name="rejectedSpeaker"
                                data-amplify-analytics-attrs={`speakerName:${record.speakerName},speakerId:${record.speakerId}`}
                                id={`reject-${record.speakerId}`}>
                                {record?.shortlistStatus === "Removed" ? "Restore Speaker" : "Remove From Proposal"}
                            </Button>
                        </Box>
                    )}
                {((record?.shortlistStatus !== "Removed" &&
                    record?.status !== "Availability Requested" &&
                    record?.availability !== "Requested" &&
                    record.status !== "Request To Book" &&
                    record.status !== "Request To Book Approved" &&
                    record.status !== "Speaker Accepted" &&
                    record.shortlistStatus) ||
                    (record.availability === "Denied" && record.status === "Availability Requested")) && (
                    <Button
                        id={`button-request-availability-${record.speakerId}`}
                        onClick={(event) => handleRequestAvailabilityClick(event, record.speakerId, record)}
                        sx={{ ...styles.outlinedButton, width: "100%", mb: "10px" }}
                        startIcon={<EventAvailableIcon fontSize="small" sx={{ color: config.primaryColor }} />}
                        variant="outlined"
                        disabled={userType === "user" || shared}>
                        Request availability
                    </Button>
                )}
                <Button
                    onClick={() =>
                        handlePopoverComment(
                            "",
                            record.speakerName,
                            record.speakerName,
                            "speaker-comment",
                            record.speakerId
                        )
                    }
                    sx={{ ...styles.outlinedButton, width: "100%" }}
                    startIcon={<AddCommentIcon fontSize="small" sx={{ color: config.primaryColor }} />}
                    variant="outlined">
                    Comment
                </Button>
            </Box>
        );
    };

    const hasAvailabilityRecordsToDisplay = (record) => {
        let calenderEntries = data[recordId].calendarEntries;
        let records = calenderEntries?.filter((item) => item.speakerId === record.speakerId);
        // console.log('Has Availability Records', record)
        if (record.status === "Availability Requested") return true;
        if (records?.length > 0) {
            return records.some(
                (entry) =>
                    entry.type === "CONFIRMED AVAILABILITY" ||
                    entry.type === "REQUEST TO BOOK" ||
                    entry.type === "AVAILABILITY REQUEST" ||
                    entry.type === "NOT AVAILABLE" ||
                    entry.type === "REQUEST TO BOOK APPROVED"
            );
        }
        return false;
    };

    const displayAvailability = (record) => {
        let calenderEntries = data[recordId].calendarEntries;
        let records = calenderEntries?.filter((item) => item.speakerId === record.speakerId);
        if (records?.length > 0) {
            return records
                .filter(
                    (item) =>
                        item.type === "CONFIRMED AVAILABILITY" ||
                        item.type === "REQUEST TO BOOK" ||
                        item.type === "AVAILABILITY REQUEST" ||
                        item.type === "NOT AVAILABLE" ||
                        item.type === "REQUEST TO BOOK APPROVED"
                )
                .map((item) => (
                    <Box
                        sx={{
                            border: "1px solid #d0d5dd",
                            borderRadius: "5px",
                            padding: "5px",
                            marginBottom: "10px",
                        }}>
                        <Grid container alignItems="flex-start" justifyContent="space-between">
                            <Grid item xs={12} md={6}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <span>{formatTimeWithZone(item?.startDate)}</span>
                                        {item?.startDate !== item?.endDate && (
                                            <span style={{ marginLeft: "10px", marginRight: "10px" }}>-</span>
                                        )}
                                        {item?.startDate !== item?.endDate && (
                                            <span>{formatTimeWithZone(item?.endDate)}</span>
                                        )}
                                        {(item.startDateTime || item.endDateTime) && (
                                            <span style={{ marginLeft: "10px", marginRight: "10px" }}>|</span>
                                        )}
                                        {item.startDateTime && <span>{item.startDateTime}</span>}
                                        {item.startDateTime && item.endDateTime && (
                                            <span style={{ marginLeft: "10px", marginRight: "10px" }}>-</span>
                                        )}
                                        {item.endDateTime && <span>{item.endDateTime}</span>}
                                    </Grid>
                                    {item.agentNote && (
                                        <Grid item>
                                            <Typography variant="body2">{item.agentNote}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid>
                                <Box>
                                    {item.type !== "REQUEST TO BOOK APPROVED" && (
                                        <>
                                            <Chip
                                                label={availablityStatus(item)}
                                                color={
                                                    item.type === "CONFIRMED AVAILABILITY"
                                                        ? "success"
                                                        : item.type === "NOT AVAILABLE"
                                                        ? "error"
                                                        : "primary"
                                                }
                                            />
                                            <Button
                                                disabled={
                                                    item.type === "CONFIRMED AVAILABILITY" && !shared ? false : true
                                                }
                                                onClick={(event) => requestToBook(event, record, item)}
                                                sx={{
                                                    ...styles.outlinedSuccessButton,
                                                    display: { md: "inline-flex", xs: "flex" },
                                                    ml: { md: "15px", xs: "0px" },
                                                    mt: { md: "0px", xs: "15px" },
                                                }}
                                                startIcon={<TaskAltIcon fontSize="small" />}
                                                variant="outlined"
                                                id={`button-request-to-book-${record.speakerId}`}>
                                                Request To Book
                                            </Button>
                                        </>
                                    )}
                                    {item.type === "REQUEST TO BOOK APPROVED" && (
                                        <Button
                                            onClick={(event) => navigateToEvent(item.engagementId)}
                                            sx={{
                                                ...styles.outlinedSuccessButton,
                                                ml: { md: "15px", xs: "0px" },
                                            }}
                                            startIcon={<TaskAltIcon fontSize="small" />}
                                            variant="outlined"
                                            id={`button-request-to-book-${record.speakerId}`}
                                            disabled={userType === "user" || shared}>
                                            Complete Pre-Booking Questionnaire
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ));
        }
        return;
    };
    const availablityStatus = (item) => {
        if (item.type === "CONFIRMED AVAILABILITY") {
            return "Available";
        } else if (item.type === "REQUEST TO BOOK") {
            return "Requested To Book";
        } else if (item.type === "NOT AVAILABLE") {
            return "Unavailable";
        } else if (item.type === "REQUEST TO BOOK APPROVED") {
            return "Processing Booking Request";
        }
        return "Checking Availability";
    };

    const createMarkup = (text) => {
        return { __html: text };
    };

    const getPromotionalTitle = (record) => {
        if (record?.speakerDetails?.filter((item) => item.type === "Biographies")?.length > 0) {
            let detail = record?.speakerDetails?.filter((item) => item.type === "Biographies")[0];
            if (detail.includeSubheading) {
                return (
                    <Box>
                        <div dangerouslySetInnerHTML={createMarkup(detail.subHeading)}></div>
                    </Box>
                );
            }
        }
        return "";
    };
    const getThumbnail = (url) => {
        // Define the prefix to insert
        const prefix = "H-170";

        // Find the position to insert the prefix
        const urlParts = url.split("/");
        const insertIndex = 3; // After the third slash

        // Insert the prefix into the URL
        urlParts.splice(insertIndex, 0, prefix);

        // Join the parts back together
        const newUrl = urlParts.join("/");

        return newUrl;
    };

    const displayCard = (records) => {
        return (
            <Box sx={{ pb: "30px", borderBottom: "1px solid lightgray" }}>
                {records.map((record) => (
                    <Card sx={{ ...styles.card, mt: "15px" }} id={`card-${record.id}`} key={`card-${record.id}`}>
                        <CardContent elevation={0}>
                            <Grid container spacing={3} sx={{ flexGrow: 1, m: "-15px" }}>
                                <Grid
                                    xs={12}
                                    md={6}
                                    mdOffset={0}
                                    sx={{ borderRight: config.border }}
                                    onClick={(event) => handleAboutClick(event, record.speakerId, record)}>
                                    <Grid
                                        sx={{ m: "-10px", flexWrap: { xs: "wrap", md: "nowrap" } }}
                                        container
                                        spacing={0}
                                        justifyContent="start">
                                        <Grid item sx={{ mr: "20px", marginTop: "6px" }} xs={12} md={3}>
                                            {record.speakerImageURL && (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        objectFit: "cover",
                                                        width: "150px",
                                                        height: "177px",
                                                        objectPosition: "top",
                                                        borderRadius: "4px",
                                                        mr: "20px",
                                                    }}
                                                    src={`${getThumbnail(record.speakerImageURL)}`}></Box>
                                            )}
                                            {!record.speakerImageURL && (
                                                <Skeleton
                                                    variant="rounded"
                                                    width={150}
                                                    height={177}
                                                    sx={{ mr: "20px" }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            sx={{
                                                width: "100%",
                                                marginLeft: { xs: "0px", md: "40px" },
                                            }}
                                            item
                                            xs={12}
                                            md={9}>
                                            <Grid
                                                sx={{ m: "-10px" }}
                                                container
                                                wrap="nowrap"
                                                spacing={0}
                                                justifyContent="space-between">
                                                <Grid item>
                                                    <Typography sx={styles.cardHeading}>
                                                        {record.speakerName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {/* {record.speakerStatus === 'Exclusive' && <Typography sx={{ color: '#ef6b19', fontSize: '12px', fontSize: '12px', border: '1px solid gainsboro', padding: '0px 5px', borderRadius: '5px', background: '#fffbf3' }} >
                                                        WSB Exclusive Speaker
                                                    </Typography>} */}

                                                    {/* {record.speakerStatus === 'Exclusive' && <Badge subject="WSB Exclusive Speaker" color='purple'></Badge>} */}
                                                </Grid>
                                            </Grid>
                                            {getPromotionalTitle(record) && (
                                                <Box sx={{ mb: "10px", mt: "-5px" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "16px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "2",
                                                            WebkitBoxOrient: "vertical",
                                                        }}>
                                                        {getPromotionalTitle(record)}
                                                    </Typography>
                                                </Box>
                                            )}
                                            {/* <Box sx={{}}>
                                                <Button id={`about-${record.speakerId}`} onClick={(event) => handleAboutClick(event, record.speakerId, record)} sx={{ padding: '0px' }} >Learn More</Button>

                                            </Box> */}
                                            <Box>
                                                {getAgentNotes(record) && (
                                                    <div className="notes note-sent">
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}>
                                                            <h3>Agent notes</h3>
                                                        </Box>

                                                        <Grid
                                                            sx={{ m: "-10px" }}
                                                            container
                                                            wrap="nowrap"
                                                            spacing={0}
                                                            justifyContent="space-between">
                                                            <Grid item sx={{ height: "100%" }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        display: "-webkit-box",
                                                                        WebkitLineClamp: "3",
                                                                        WebkitBoxOrient: "vertical",
                                                                    }}>
                                                                    {getAgentNotes(record)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={3}
                                    sx={{ borderRight: config.border }}
                                    onClick={(event) => handleAboutClick(event, record.speakerId, record)}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}>
                                        <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Fees</Typography>
                                        <Tooltip title="Click to see full fee and travel details.">
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleAboutClick(event, record.speakerId, record)}>
                                                <Info fontSize="inherit"></Info>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    {displayFeeDetails(record)}
                                </Grid>
                                {
                                    <Grid xs={12} md={3} mdOffset={0}>
                                        {displayActionButtons(record)}
                                        {/* <Button id={`about-${record.speakerId}`} onClick={(event) => handleAboutClick(event, record.speakerId, record)} sx={{ ...styles.outlinedButton, width: '100%' }} startIcon={<PersonIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">About</Button> */}
                                    </Grid>
                                }

                                {hasAvailabilityRecordsToDisplay(record) && (
                                    <Grid xs={12} md={12} sx={{ borderTop: "1px solid #d0d5dd", padding: "0" }}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                    <Typography sx={{ color: "#5048E5" }}>
                                                        {record.speakerName}'s Availability
                                                    </Typography>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {record.availability === "Approved" &&
                                                    record.status === "Request To Book" && (
                                                        <Box>
                                                            <Alert severity="info" sx={{ mb: "10px" }}>
                                                                Your request to book {record.speakerName} is being
                                                                processed.
                                                            </Alert>
                                                        </Box>
                                                    )}
                                                {record.status === "Availability Requested" &&
                                                    record.availability === "Requested" && (
                                                        <Alert severity="info" sx={{ mb: "10px" }}>
                                                            Your availability request has been received.
                                                        </Alert>
                                                    )}
                                                {record.availability === "Approved" &&
                                                    record.status === "Availability Requested" && (
                                                        <Box>
                                                            <Alert severity="success" sx={{ mb: "10px" }}>
                                                                Congratulations! {record.speakerName} is available for
                                                                your event.
                                                            </Alert>

                                                            {/* <Button onClick={(event) => requestToBook(event, record)} sx={{ ...styles.outlinedSuccessButton, width: '100%', mb: '10px' }} startIcon={<TaskAltIcon fontSize='small' />} variant="outlined" id={`shortlist-${record.speakerId}`}>
        Request To Book
    </Button> */}
                                                        </Box>
                                                    )}
                                                {record.availability === "Denied" &&
                                                    record.status === "Availability Requested" && (
                                                        <Box>
                                                            <Alert severity="warning" sx={{ mb: "10px" }}>
                                                                Sorry! {record.speakerName} is not available for your
                                                                event.
                                                            </Alert>
                                                        </Box>
                                                    )}
                                                {displayAvailability(record)}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        );
    };

    const cardComponent = (records, group, showGroupName = false) => {
        if (records && records.length > 0) {
            return (
                <Box sx={{ mb: "50px" }}>
                    {showGroupName && group?.name && (
                        <Grid item lg={12} sx={{ mt: "15px" }}>
                            <Box sx={{ borderLeft: "5px solid #20335b", pl: "10px" }}>
                                <Box sx={{ fontSize: "14px", fontWeight: "500" }}>
                                    <h2>{group?.name}</h2>
                                </Box>
                                <Box sx={{ fontSize: "12px", color: "gray" }}>{group?.description}</Box>
                            </Box>
                        </Grid>
                    )}
                    {displayCard(records)}
                </Box>
            );
        }
        return "";
    };

    const requestToBook = (event, record, item) => {
        event.stopPropagation();
        setEventType(!item?.locationCity && !item?.locationState ? "Virtual" : "In-Person");
        setBookingConfirmNote(item.clientNote);
        setRequestToBookLoading(true);
        setConfirmBookingRecord(record);
        post("opportunity/deltaRequestToBook", {
            calendarEntryId: item.id,
            opportunityId: recordId,
        }).then((result) => {
            if (result.isSuccess) {
                setOpenConfirmBookingScreen(true);
                setCalendarEntryRecord(result.data);
            }
            setRequestToBookLoading(false);
        });
    };

    const handleSaveBooking = () => {
        setSaveBookingLoadingButton(true);
        let calenderEntryRecord = { ...calendarEntryRecord };
        calenderEntryRecord.clientNote = bookingConfirmNote;

        console.log("calenderEntryRecord............", calenderEntryRecord);
        dispatch(
            saveRequestAvailabilityAsync({
                recordId: recordId,
                data: calenderEntryRecord,
                callback: (result) => {
                    if (result.isSuccess) {
                        setBookingConfirmNote("");
                        setConfirmBookingRecord(undefined);
                        setCalendarEntryRecord(undefined);
                        setOpenConfirmBookingScreen(false);

                        const detailEvent = new CustomEvent("openToster", {
                            detail: {
                                variant: "warning",
                                message:
                                    "Your Booking Request has been save but not submitted. You can submit once you've provided all information",
                            },
                        });
                        document.dispatchEvent(detailEvent);
                    } else {
                        const detailEvent = new CustomEvent("openToster", {
                            detail: { variant: "error", message: result.errorMessage },
                        });
                        document.dispatchEvent(detailEvent);
                    }
                    setConfirmBookingLoadingButton(false);
                    setSaveBookingLoadingButton(false);
                },
            })
        );
    };

    const handleConfirmBooking = () => {
        console.log(calendarEntryRecord);
        setConfirmBookingLoadingButton(true);
        dispatch(
            saveProposalAsync({
                recordId,
                data: { ...confirmBookingRecord, status: "Request To Book" },
                callback: (result) => {
                    console.log("request to book sent");
                    setSnackBarMessage("Request to book sent to WSB!");
                    setSnackBarOpen(true);
                    trackEvent(
                        getTrackEventData(
                            "submit",
                            "request-to-book",
                            `Requested to book ${confirmBookingRecord.speakerName}`,
                            confirmBookingRecord
                        )
                    );
                },
            })
        );

        let calenderEntryRecord = { ...calendarEntryRecord };
        calenderEntryRecord.clientNote = bookingConfirmNote;
        calenderEntryRecord.type = "REQUEST TO BOOK";
        calenderEntryRecord.name = (calenderEntryRecord?.speakerName + " - HOLD - REQUEST TO BOOK").substring(0, 79);
        // setCalendarEntryRecord(calenderEntryRecord)
        // console.log('The CALENDAR ENTRY', calenderEntryRecord)
        dispatch(
            saveRequestAvailabilityAsync({
                recordId: recordId,
                data: calenderEntryRecord,
                callback: (result) => {
                    if (result.isSuccess) {
                        // console.log('Succesfully sent request to book')
                        setBookingConfirmNote("");
                        setConfirmBookingRecord(undefined);
                        setCalendarEntryRecord(undefined);
                        setOpenConfirmBookingScreen(false);
                        setTabValue("7");
                    } else {
                        const detailEvent = new CustomEvent("openToster", {
                            detail: { variant: "error", message: result.errorMessage },
                        });
                        document.dispatchEvent(detailEvent);
                    }
                    setConfirmBookingLoadingButton(false);
                },
            })
        );

        // if (
        //     eventType === "Virtual" ||
        //     (calendarEntryRecord?.locationCity &&
        //         (!(
        //             calendarEntryRecord?.locationCountry === "United States" ||
        //             calendarEntryRecord?.locationCountry === "Canada"
        //         ) ||
        //             calendarEntryRecord?.locationState) &&
        //         calendarEntryRecord?.locationCountry)
        // ) {
        //     if (eventType === "Virtual") {
        //         calendarEntryRecord.locationState = "";
        //         calendarEntryRecord.locationCity = "";
        //     }
        //     setConfirmBookingLoadingButton(true);
        //     dispatch(
        //         saveProposalAsync({
        //             recordId,
        //             data: { ...confirmBookingRecord, status: "Request To Book" },
        //             callback: (result) => {
        //                 console.log("request to book sent");
        //                 setSnackBarMessage("Request to book sent to WSB!");
        //                 setSnackBarOpen(true);
        //                 trackEvent(
        //                     getTrackEventData(
        //                         "submit",
        //                         "request-to-book",
        //                         `Requested to book ${confirmBookingRecord.speakerName}`,
        //                         confirmBookingRecord
        //                     )
        //                 );
        //             },
        //         })
        //     );

        //     let calenderEntryRecord = { ...calendarEntryRecord };
        //     calenderEntryRecord.clientNote = bookingConfirmNote;
        //     calenderEntryRecord.type = "REQUEST TO BOOK";
        //     // setCalendarEntryRecord(calenderEntryRecord)
        //     // console.log('The CALENDAR ENTRY', calenderEntryRecord)
        //     dispatch(
        //         saveRequestAvailabilityAsync({
        //             recordId: recordId,
        //             data: calenderEntryRecord,
        //             callback: (result) => {
        //                 if (result.isSuccess) {
        //                     // console.log('Succesfully sent request to book')
        //                     setBookingConfirmNote("");
        //                     setConfirmBookingRecord(undefined);
        //                     setCalendarEntryRecord(undefined);
        //                     setOpenConfirmBookingScreen(false);
        //                     setTabValue("7");
        //                 } else {
        //                     const detailEvent = new CustomEvent("openToster", {
        //                         detail: { variant: "error", message: result.errorMessage },
        //                     });
        //                     document.dispatchEvent(detailEvent);
        //                 }
        //                 setConfirmBookingLoadingButton(false);
        //             },
        //         })
        //     );
        // } else {
        //     let errorMessage = "Country, State and City are required, please fill before submit";

        //     if (
        //         calendarEntryRecord?.locationCountry !== "United States" &&
        //         calendarEntryRecord?.locationCountry !== "Canada"
        //     ) {
        //         errorMessage = "Country and City are required, please fill before submit";
        //     }

        //     const detailEvent = new CustomEvent("openToster", {
        //         detail: { variant: "error", message: errorMessage },
        //     });
        //     document.dispatchEvent(detailEvent);
        //     setConfirmBookingLoadingButton(false);
        // }
    };

    const shortListProposal = (event, record) => {
        event.stopPropagation();
        dispatch(
            saveProposalAsync({
                recordId,
                data: {
                    ...record,
                    status: record.status === "Shortlist" ? "" : "Shortlist",
                    shortlistStatus: record.shortlistStatus === "Shortlist" ? "" : "Shortlist",
                },
                callback: (result) => {
                    console.log("speaker shortlisted");
                    setSnackBarMessage(
                        record.shortlistStatus === "Shortlist"
                            ? `${record.speakerName} removed from shortlist!`
                            : `${record.speakerName} shortlisted!`
                    );
                    setSnackBarOpen(true);
                },
            })
        );

        trackEvent(
            getTrackEventData(
                "click",
                "proposed-speaker-shortlisted",
                `${record.shortlistStatus === "Shortlist" ? "Unshortlisted" : "Shortlisted"} ${record.speakerName}`,
                record
            )
        );
        //setTabValue(record.status === 'Shortlist' ? '1' : '5')
    };

    const removeProposal = (event, record) => {
        event.stopPropagation();
        dispatch(
            saveProposalAsync({
                recordId,
                data: {
                    ...record,
                    status: record.status === "Removed" ? "" : "Removed",
                    shortlistStatus: record.shortlistStatus === "Removed" ? "" : "Removed",
                },
                callback: (result) => {
                    console.log("speaker rejected");
                    setSnackBarMessage(
                        record.shortlistStatus === "Removed"
                            ? `${record.speakerName} added to proposal!`
                            : `${record.speakerName} removed from proposal!`
                    );
                    setSnackBarOpen(true);
                },
            })
        );
        trackEvent(
            getTrackEventData(
                "click",
                "proposed-speaker-rejected",
                `${record.shortlistStatus === "Removed" ? "Unrejected" : "Rejected"} ${record.speakerName}`,
                record
            )
        );
        //setTabValue(record.status === 'Removed' ? '1' : '6')
    };

    const sharedProposalClick = async () => {
        const expDays = 15;
        let date = new Date();
        date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
        const result = await encrypt({
            recordId: recordId,
            expires: date.toUTCString(),
        });
        let resultProposal;
        if (proposalId) {
            resultProposal = await encrypt({
                recordId: proposalId,
                expires: date.toUTCString(),
            });
        }

        if (result.isSuccess) {
            let url;
            let loginRequiredURL;
            if (proposalId && resultProposal.isSuccess) {
                url = `${window.location.origin}/shared/proposal/${recordId}/${proposalId}?type=public`;
                loginRequiredURL = `${window.location.origin}/shared/proposal/${recordId}/${proposalId}?type=anonymous`;
            } else {
                url = `${window.location.origin}/shared/proposal/${recordId}?type=public`;
                loginRequiredURL = `${window.location.origin}/shared/proposal/${recordId}?type=anonymous`;
            }

            console.log(url);
            setSharedUrl(url);
            setLoginRequiredUrl(loginRequiredURL);
            setOpenShareLinkModal(true);
        }
        trackEvent(getTrackEventData("click", "shared-proposal", `Shared proposal`, {}));
    };

    const handleShareLinkModalClose = () => {
        setSharedUrl("");
        setLoginRequiredUrl("");
        setOpenShareLinkModal(false);
        setLinkCopied(false);
        // trackEvent(getTrackEventData('click', 'page', `Closed share link modal`, {}));
    };

    const backToProposals = () => {
        navigate(`/my-proposals`);
        // trackEvent(getTrackEventData('click', 'page', `Clicked on back to proposal list`, {}));
    };

    const navigateToEvent = (recordId) => {
        navigate(`/event-detail/${recordId}`);
    };

    const handleRequestAvailabilitySave = () => {
        setRequestAvailabilityLoadingButton(true);
        let calendarEntryData = calendarEntryRef.current.getData();
        if (calendarEntryData && calendarEntryData.length > 0) {
            if (!requestAvailabilitySpeakers) {
                calendarEntryData = calendarEntryData.map((item) => {
                    return {
                        ...item,
                        accountId: opportunityData.opportunity.accountId,
                        opportunityId: recordId,
                        speakerId: selectedSpeaker.speakerId,
                        status: "Active",
                        type: "AVAILABILITY REQUEST",
                        salesAgentId: userDetails?.accountOwnerId,
                        createdById: opportunityData.opportunity.ownerId,
                    };
                });
                dispatch(
                    saveRequestAvailabilityAsync({
                        recordId: recordId,
                        data: calendarEntryData,
                        callback: (result) => {
                            setRequestAvailabilityLoadingButton(false);
                            setOpenRequestAvailabilityModal(false);
                            setSnackBarMessage("Availability request was submitted!");
                            trackEvent(
                                getTrackEventData(
                                    "submit",
                                    "availability-check",
                                    `Requested availability for ${selectedSpeaker.speakerName}`,
                                    selectedSpeaker
                                )
                            );
                            dispatch(
                                saveProposalAsync({
                                    recordId,
                                    data: {
                                        ...selectedSpeaker,
                                        status: "Availability Requested",
                                        availability: "Requested",
                                    },
                                    callback: (result) => {
                                        console.log("availability requested updated");
                                    },
                                })
                            );
                            setSnackBarOpen(true);
                        },
                    })
                );
                setTabValue("4");
            } else {
                let nonAppliedSpeakers = requestAvailabilitySpeakers.filter((item) => item.status === "Shortlist");
                if (nonAppliedSpeakers.length > 0) {
                    let records = [];
                    for (let selectedSpeaker of nonAppliedSpeakers) {
                        records = [
                            ...records,
                            calendarEntryData.map((item) => {
                                return {
                                    ...item,
                                    name: selectedSpeaker?.speakerName + " - Event",
                                    accountId: opportunityData.opportunity.accountId,
                                    opportunityId: recordId,
                                    speakerId: selectedSpeaker.speakerId,
                                    status: "Active",
                                    type: "AVAILABILITY REQUEST",
                                    salesAgentId: userDetails?.accountOwnerId,
                                };
                            }),
                        ];
                    }

                    dispatch(
                        saveRequestAvailabilityAsync({
                            recordId: recordId,
                            data: records,
                            callback: (result) => {
                                setOpenRequestAvailabilityModal(false);
                                setRequestAvailabilityLoadingButton(false);
                                setSnackBarMessage("Availability request submitted for all shortlisted speakers!");
                                for (let selectedSpeaker of nonAppliedSpeakers) {
                                    trackEvent(
                                        getTrackEventData(
                                            "submit",
                                            "availability-check",
                                            `Requested availability for ${selectedSpeaker.speakerName}`,
                                            selectedSpeaker
                                        )
                                    );
                                    dispatch(
                                        saveProposalAsync({
                                            recordId,
                                            data: {
                                                ...selectedSpeaker,
                                                status: "Availability Requested",
                                                availability: "Requested",
                                            },
                                            callback: (result) => {
                                                // console.log('availability requested updated')
                                            },
                                        })
                                    );
                                }

                                setSnackBarOpen(true);
                                setRequestAvailabilitySpeakers(undefined);
                            },
                        })
                    );
                } else {
                    setRequestAvailabilityLoadingButton(false);
                    setOpenRequestAvailabilityModal(false);
                    setSnackBarMessage("Availability request submitted for all shortlisted speakers!");
                    setRequestAvailabilitySpeakers(undefined);
                }
            }
        } else {
            setRequestAvailabilityLoadingButton(false);
        }
    };

    const handleRequestAvailabilityAllClick = (event, records) => {
        event.stopPropagation();
        setRequestAvailabilitySpeakers(records);
        setOpenRequestAvailabilityModal(true);
    };

    const handleLinkCopy = () => {
        trackEvent(getTrackEventData("click", "copied-share-link", `Copied proposal share link`, {}));
    };

    const [filterToApply, setFilterToApply] = React.useState("");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);

        if (newValue === "5") {
            if (
                !shortListTourCompleted &&
                opportunityData?.tourStatus &&
                !opportunityData?.tourStatus["custom:shortlist_status"]
            ) {
                setDisplayShortlistJoyride(true);
                setTimeout(() => {
                    let joyRide = {
                        steps: [
                            {
                                target: `#${document.querySelector('[id^="button-request-availability-"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                content: "Click here to request a speaker’s availability.",
                                placement: "top",
                            },
                        ],
                    };
                    setJoyRideShortlistState(joyRide);
                }, 1000);
            }
        } else if (newValue === "4") {
            if (
                !bookingTourCompleted &&
                opportunityData?.tourStatus &&
                !opportunityData?.tourStatus["custom:booking_status"]
            ) {
                setDisplayBookingJoyride(true);
                setTimeout(() => {
                    let joyRide = {
                        steps: [
                            {
                                target: `#${document.querySelector('[id^="button-request-to-book-"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                                content:
                                    "Once a speaker's availablity is confirmed, you'll be able to click here to request to book the speaker.",
                                placement: "top",
                            },
                        ],
                    };
                    setJoyRideBookingState(joyRide);
                }, 1000);
            }
        } else {
            setJoyRideShortlistState({});
            setDisplayShortlistJoyride(false);
            setDisplayBookingJoyride(false);
        }
    };

    const speakerActionsMatch = (groups, totalSpeakers) => {
        // console.log('SPeaker Actions', groups['Shortlist'].records.length)
        let countOfActed = 0;
        if (groups["Available"]) {
            countOfActed += groups["Available"].records.length;
        }
        if (groups["Availability Requested"]) {
            countOfActed += groups["Availability Requested"].records.length;
        }
        if (groups["Shortlist"]) {
            countOfActed += groups["Shortlist"].records.length;
        }
        if (groups["Rejected"]) {
            countOfActed += groups["Rejected"].records.length;
        }
        if (groups["Booked"]) {
            countOfActed += groups["Booked"].records.length;
        }
        if (groups["Booked Speakers"]) {
            countOfActed += groups["Booked Speakers"].records.length;
        }
        console.log("ACTION COUNT: ", countOfActed, totalSpeakers);

        return countOfActed === totalSpeakers;
    };

    const displayNotes = () => {
        let openNotes = false;
        let noteStatus = getLocalStorage(recordId + "-" + userDetails?.username + "-NoteStatus");
        if (!noteStatus) {
            openNotes = true;
            setLocalStorage(recordId + "-" + userDetails?.username + "-NoteStatus", true, 120 * 24 * 60 * 60 * 1000);
        }

        return (
            <Accordion
                defaultExpanded={openNotes}
                sx={{
                    borderRadius: "0",
                    boxShadow: "none",
                    borderBottom: "1px solid #e7e8f0",
                }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <ArticleIcon sx={{ color: "#5048E5", mr: "15px" }} fontSize="small" id="agent-note"></ArticleIcon>
                    <Typography sx={{ color: "#5048E5" }}>{`A Note from ${opportunityData?.opportunity?.ownerName
                        .split(" ")
                        .slice(0, -1)
                        .join(" ")}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        {opportunityData?.opportunity?.proposalIntroduction && (
                            <Box
                                sx={{
                                    backgroundColor: styles.pageBackgroundColor,
                                    borderRadius: "4px",
                                    fontSize: "16px",
                                }}>
                                <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
                                    {/* <Typography sx={{ fontWeight: '700' }}>A Note from {opportunityData?.opportunity?.ownerName.split(' ').slice(0, -1).join(' ')}</Typography> */}
                                </Box>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: opportunityData?.opportunity?.proposalIntroduction,
                                    }}
                                />
                            </Box>
                        )}
                        {opportunityData?.proposalVideo && (
                            <Box sx={{ minWidth: "200px", width: "200px" }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: opportunityData?.proposalVideo.html,
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    };

    const getOpportunitySteps = () => {
        let opportunitySteps = {
            currentStep: 1,
            steps: [
                {
                    id: "step1",
                    stepName: "Shortlisting",
                    stepDescription: "You can request availability once you have selected a top list of speakers.",
                },
                {
                    id: "step2",
                    stepName: "Request Availability",
                    stepDescription:
                        "Request the availability of speakers you’re interested in. Your agent will reach out and see if they’re available for your event.",
                },
                {
                    id: "step3",
                    stepName: "Request to Book",
                    stepDescription:
                        "Ready to book a speaker? Let us know and we’ll start preparing a draft contract for you to review and sign.",
                },
            ],
        };

        if (opportunityData?.opportunity?.stageName === "Active Proposal") {
            opportunitySteps.currentStep = 1;
        } else if (
            opportunityData?.opportunity?.stageName === "Shortlisting" ||
            opportunityData?.opportunity?.stageName === "Availability Checks"
        ) {
            opportunitySteps.currentStep = 2;
        } else if (
            opportunityData?.opportunity?.stageName === "Request To Book" ||
            opportunityData?.opportunity?.stageName === "Create Engagements"
        ) {
            opportunitySteps.currentStep = 3;
        }

        return opportunitySteps;
    };

    const displayOpportunityStep = () => {
        let opportunitySteps = getOpportunitySteps();
        return (
            <>
                {opportunitySteps && (
                    <Box sx={{ py: "20px", borderBottom: "1px solid #e7e8f0 !important" }}>
                        <Typography sx={{ textAlign: "center", my: "20px" }}>
                            {opportunitySteps.steps[opportunitySteps.currentStep - 1].stepDescription}
                        </Typography>
                        <Stepper alternativeLabel sx={{}} activeStep={opportunitySteps.currentStep - 1}>
                            {opportunitySteps.steps.map((step) => (
                                <Step key={step.id} expanded>
                                    <StepLabel>{step.stepName}</StepLabel>
                                    {/* <StepContent>{step.stepDescription}</StepContent> */}
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                )}
            </>
        );
    };

    const restartProposalTourClick = () => {
        setJoyRideState(undefined);
        setJoyRideBookingState(undefined);
        setJoyRideShortlistState(undefined);
        setDisplayShortlistJoyride(false);
        setDisplayBookingJoyride(false);
        setTimeout(() => {
            let joyRide = {
                steps: [
                    {
                        target: `#${document.querySelector('[id^="agent-note"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                        content: "Your agent has included a note about your proposal. Click to open.",
                        placement: "top",
                    },
                    {
                        target: `#${document.querySelector('[id^="card"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                        content: "Click each card for more information about the speaker.",
                        placement: "top",
                    },
                    {
                        target: `#${document.querySelector('[id^="shortlist"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                        content: "Add speakers to your shortlist.",
                        placement: "top",
                    },
                    {
                        target: `#${document.querySelector('[id^="reject"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                        content: "Remove speakers from your proposal.",
                        placement: "top",
                    },
                    {
                        target: `#leave-comment-fab`,
                        content: "Click here to leave a comment for your agent. ",
                    },
                    {
                        target: `#request-speakers`,
                        content: "Click here to request more speakers from your agent.",
                    },
                    {
                        target: "#back-to-proposal",
                        content: "Click here to view all of your proposals. ",
                        placement: "right-end",
                    },
                ],
            };
            if (document.querySelector('[id^="add-tooltip-icon-"]')?.id) {
                joyRide.steps.splice(3, 0, {
                    target: `#${document.querySelector('[id^="add-tooltip-icon-"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                    content: "Learn more about what is included in the speaker's fee.",
                    placement: "top",
                });
            }
            setJoyRideState(joyRide);

            joyRide = {
                steps: [
                    {
                        target: `#${document.querySelector('[id^="button-request-to-book-"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                        content:
                            "Once a speaker's availablity is confirmed, you'll be able to click here to request to book the speaker",
                        placement: "top",
                    },
                ],
            };
            setJoyRideBookingState(joyRide);

            joyRide = {
                steps: [
                    {
                        target: `#${document.querySelector('[id^="button-request-availability-"]')?.id}`, //   `#card-${data?.records[0]?.id}`,
                        content: "Click here to request a speaker’s availability.",
                        placement: "top",
                    },
                ],
            };
            setJoyRideShortlistState(joyRide);
        });
    };

    const displayRequestToBook = () => {
        return (
            <>
                <RequestToBook
                    shared={shared}
                    calendarEntryRecord={calendarEntryRecord}
                    setCalendarEntryRecord={setCalendarEntryRecord}
                    confirmBookingRecord={confirmBookingRecord}
                    setBookingConfirmNote={setBookingConfirmNote}
                    bookingConfirmNote={bookingConfirmNote}
                    setConfirmBookingCompleted={setConfirmBookingCompleted}></RequestToBook>
            </>
        );
    };

    const downloadProposalDocument = async () => {
        // if (fileId) {
        //     const { isSuccess, data } = await getFileUrl({ fileId: fileId });
        //     if (isSuccess) {
        //         downloadFile(data, opportunityData.opportunity.clientName + " _ " + opportunityData.opportunity.name);
        //     }
        // }
        if (!downloadingDocument) {
            setDownloadingDocument(true);
            get("opportunity/getSpeakerDetailForDocument", {
                opportunityId: opportunityData.opportunity?.id,
                proposalId: proposalId,
            }).then((result) => {
                if (result.isSuccess) {
                    console.log(result.data);
                    documentGeneratorRef.current.generateDocument(
                        result.data,
                        opportunityData.records,
                        opportunityData.opportunity
                    );
                    setDownloadingDocument(false);
                }
            });
        }
    };

    const handleShareProposalDetailsChange = (value) => {
        setShareProposalDetails({ ...value });
    };

    const handleShareProposal = () => {
        setLoadingShareProposal(true);
        let pollValues = shareProposalRef.current.getPollDetail();
        get("proposal/updateShareProposal", {
            opportunityId: recordId,
            noOfVotes: pollValues?.noOfVote,
            pollEnabled: pollValues?.pollEnabled,
        }).then((result) => {
            if (result.isSuccess) {
                dispatch(
                    updateOpportunityPollDetailsAsync({
                        recordId,
                        data: pollValues,
                    })
                );
                setOpenShareLinkModal(false);
                //setOriginalPollDetail(pollDetail);
            }
            setLoadingShareProposal(false);
        });
    };

    let body = (
        <Track>
            <Helmet>
                <title>{opportunityData?.opportunity?.eventName}</title>
            </Helmet>
            {!hasAccess && (!opportunityData || !opportunityData.opportunity) ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100%",
                        textAlign: "center",
                        marginTop: "-3rem",
                    }}>
                    <NewReleasesIcon sx={{ fontSize: "13rem", color: "#fe8282", width: "100px" }}></NewReleasesIcon>
                    <Typography variant="h2">Sorry!</Typography>
                    <Typography variant="h6" sx={{ mt: "10px", color: "gray", fontWeight: "300" }}>
                        You don't have access for this proposal, please contact your agent for more information.
                    </Typography>
                </Box>
            ) : (
                <div>
                    <ProposalDocxGenerator ref={documentGeneratorRef}></ProposalDocxGenerator>
                    {joyRideState && (
                        <Joyride
                            steps={joyRideState?.steps}
                            showProgress
                            showSkipButton
                            continuous
                            callback={(action) => updateUserTourStatus(action.status)}
                        />
                    )}
                    {displayShortlistJoyride && (
                        <Joyride
                            steps={joyRideShortlistState?.steps}
                            showProgress
                            showSkipButton
                            continuous
                            callback={(action) => updateShortlistTourStatus(action.status)}
                        />
                    )}
                    {displayBookingJoyride && (
                        <Joyride
                            steps={joyRideBookingState?.steps}
                            showProgress
                            showSkipButton
                            continuous
                            callback={(action) => updateBookingTourStatus(action.status)}
                        />
                    )}
                    <Snackbar
                        sx={{ left: { lg: "calc(50% + 140px)" } }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" variant="filled">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                    {shared && (
                        <Alert
                            severity="warning"
                            sx={{
                                color: "white",
                                backgroundColor: "rgb(239 107 25)",
                                mb: "10px",
                            }}
                            icon={<InfoIcon fontSize="inherit" sx={{ color: "white" }} />}>
                            This link provides view only access.
                        </Alert>
                    )}
                    {userType === "anonymous" && (
                        <Alert
                            severity="warning"
                            sx={{
                                color: "white",
                                backgroundColor: "rgb(239 107 25)",
                                mb: "10px",
                            }}
                            icon={<InfoIcon fontSize="inherit" sx={{ color: "white" }} />}>
                            Use this to collaborate on the review of this proposal with a co-worker.
                        </Alert>
                    )}
                    {userType === "user" && (
                        <Alert
                            severity="warning"
                            sx={{
                                color: "white",
                                backgroundColor: "rgb(239 107 25)",
                                mb: "10px",
                            }}
                            icon={<InfoIcon fontSize="inherit" sx={{ color: "white" }} />}>
                            You are currently previewing the proposal. You cannot take action on the proposal from this
                            view.
                        </Alert>
                    )}
                    {!shared && userType !== "user" && (
                        <Box sx={{ mb: "1rem" }}>
                            <Button
                                id="back-to-proposal"
                                onClick={backToProposals}
                                variant="outlined"
                                sx={{
                                    border: "none",
                                    padding: "0",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#ef6b19",
                                    mt: ".5rem",
                                    "&:hover": {
                                        background: "none !important",
                                        padding: "0 !important",
                                        border: "none !important",
                                    },
                                }}
                                startIcon={<ArrowCircleLeftIcon />}>
                                Back to My Proposals
                            </Button>
                        </Box>
                    )}
                    <Box>
                        <Card>
                            <Box
                                sx={{
                                    position: "relative",
                                    minHeight: { xs: "400px", md: "300px" },
                                    height: { xs: "auto", md: "300px" },
                                }}>
                                {/* <CardMedia component="img" height="300px" image="https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"></CardMedia> */}
                                <Box
                                    sx={{
                                        height: "100%",
                                        display: "flex",
                                        minHeight: { xs: "400px", md: "300px" },
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background:
                                            "linear-gradient(180deg, rgba(26,40,71,1) 0%, rgba(94,30,92,1) 100%)",
                                        color: "white",
                                        position: "relative",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        p: "40px 15px",
                                    }}>
                                    <Typography variant="h5">{opportunityData?.opportunity?.clientName}</Typography>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            textAlign: "center",
                                            fontSize: { xs: "2.5rem", md: "3.5rem" },
                                        }}>
                                        {opportunityData?.opportunity?.eventName}
                                    </Typography>
                                    {opportunityData?.opportunity?.venueName && (
                                        <Box
                                            sx={{
                                                display: "inline-flex",
                                                verticalAlign: "text-bottom",
                                                boxSizing: "inherit",
                                                textAlign: "center",
                                                alignItems: "center",
                                                fontSize: "16px",
                                            }}>
                                            <LocationOnIcon fontSize="small" sx={{ color: "gray", width: "15px" }} />
                                            <span style={{ marginLeft: "5px" }}>
                                                {opportunityData?.opportunity?.venueName}
                                            </span>
                                        </Box>
                                    )}
                                    <Typography variant="p1">
                                        {opportunityData?.opportunity?.startPlayDate ? (
                                            <Box
                                                sx={{
                                                    display: "inline-flex",
                                                    verticalAlign: "text-bottom",
                                                    boxSizing: "inherit",
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    fontSize: "16px",
                                                }}>
                                                <CalendarMonthIcon
                                                    fontSize="medium"
                                                    sx={{ color: "white", width: "15px" }}
                                                />
                                                <span style={{ marginLeft: "5px" }}>
                                                    {formatTimeWithZone(opportunityData?.opportunity?.startPlayDate)}
                                                </span>
                                                {opportunityData?.opportunity?.endPlayDate &&
                                                    opportunityData?.opportunity?.startPlayDate !==
                                                        opportunityData?.opportunity?.endPlayDate && (
                                                        <span style={{ marginLeft: "5px" }}>
                                                            {" "}
                                                            -{" "}
                                                            {formatTimeWithZone(
                                                                opportunityData?.opportunity?.endPlayDate
                                                            )}
                                                        </span>
                                                    )}
                                            </Box>
                                        ) : (
                                            ""
                                        )}
                                    </Typography>
                                    {!shared && (
                                        <Box
                                            sx={{
                                                position: { md: "absolute", xs: "relative" },
                                                bottom: "15px",
                                                right: { xs: "0px", md: "10px" },
                                                width: "100%",
                                                mt: { md: "0px", xs: "15px" },
                                            }}>
                                            <Grid container spacing={3} sx={{ flexGrow: 1, my: "3px" }}>
                                                <Grid
                                                    xs={12}
                                                    md={6}
                                                    mdOffset="auto"
                                                    sx={{
                                                        pb: "0px",
                                                        display: "flex",
                                                        justifyContent: { xs: "center", md: "flex-end" },
                                                    }}>
                                                    {downloadingDocument && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginRight: "10px",
                                                                marginTop: "4px",
                                                            }}>
                                                            Loading document
                                                            <CircularProgress
                                                                size={"small"}
                                                                sx={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    color: "white",
                                                                    ml: "10px",
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    <div style={{ position: "relative" }}>
                                                        <Button
                                                            sx={{
                                                                ...styles.outlinedButton,
                                                                ...styles.buttonHoverFilled,
                                                                mr: { xs: "0px", md: "20px" },
                                                            }}
                                                            id="action-customized-button"
                                                            aria-controls={
                                                                openAction ? "action-customized-menu" : undefined
                                                            }
                                                            aria-haspopup="true"
                                                            aria-expanded={openAction ? "true" : undefined}
                                                            variant="outlined"
                                                            disableElevation
                                                            onClick={handleActionClick}
                                                            endIcon={<KeyboardArrowDownIcon />}>
                                                            Actions
                                                        </Button>
                                                        <StyledMenu
                                                            id="action-customized-menu"
                                                            MenuListProps={{
                                                                "aria-labelledby": "action-customized-button",
                                                            }}
                                                            anchorEl={anchorActionEl}
                                                            open={openAction}
                                                            onClose={handleActionClose}>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    downloadProposalDocument();
                                                                    handleActionClose();
                                                                }}
                                                                disableRipple>
                                                                <CloudDownloadIcon />
                                                                Download Proposal
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    sharedProposalClick();
                                                                    handleActionClose();
                                                                }}
                                                                disableRipple>
                                                                <ShareIcon />
                                                                Share Proposal
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    handlePopoverComment(
                                                                        "",
                                                                        "Request more speakers",
                                                                        "",
                                                                        "request-speakers"
                                                                    );
                                                                    handleActionClose();
                                                                }}
                                                                disableRipple>
                                                                <AddCircleOutlineIcon />
                                                                Request More Speakers
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    sharedProposalClick();
                                                                    handleActionClose();
                                                                }}
                                                                disableRipple>
                                                                <PollOutlinedIcon />
                                                                Create a Speaker Poll
                                                            </MenuItem>
                                                        </StyledMenu>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Box>

                                {
                                    <Tooltip title="Restart Proposal Tour">
                                        <IconButton
                                            aria-label="Restart Proposal Tour"
                                            onClick={restartProposalTourClick}
                                            sx={{ position: "absolute", top: "10px", right: "10px" }}>
                                            <TourIcon
                                                sx={{ color: "white", "&:hover": { color: "#F56856" } }}
                                                fontSize="medium"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Box>
                            {opportunityData?.opportunity?.pollEnabled && (
                                <>
                                    <Box
                                        sx={{
                                            p: ".8rem",
                                        }}>
                                        <Box
                                            sx={{
                                                minHeight: "138px",

                                                border: "solid 1.5px transparent",
                                                borderRadius: "8px",
                                                backgroundImage:
                                                    "linear-gradient(#fff4ff, #f4f4f4), linear-gradient(#98A93C, #E59735, #EE6AC7, #5AA7DB)",
                                                borderWidth: "1.5px",
                                                borderStyle: "solid",

                                                backgroundOrigin: "border-box",
                                                backgroundClip: "content-box, border-box",
                                            }}>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: { xs: "column-reverse", md: "row" },
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    p: "1rem",
                                                    minHeight: "130px",
                                                }}>
                                                <Box>
                                                    <Typography sx={{ textAlign: { xs: "center", md: "" } }}>
                                                        Your team is currently running a speaker poll for this event.
                                                        Click on “View Poll” to cast your votes.
                                                    </Typography>
                                                    <Button
                                                        startIcon={<VisibilityIcon />}
                                                        variant="outlined"
                                                        color="gray"
                                                        onClick={() => setOpenPollModal(true)}
                                                        sx={{
                                                            mt: ".8rem",
                                                            fontWeight: "500",
                                                            width: { xs: "100%", md: "auto" },
                                                        }}>
                                                        View Poll
                                                    </Button>
                                                </Box>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            borderRadius: "22.177px",
                                                            background: "white",
                                                            p: "20px 10px",
                                                            width: "110px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}>
                                                        <PollOutlinedIcon style={{ width: "68px", height: "68px" }} />
                                                        {/* <img src={pollImage} alt="poll" style={{ width: "68px" }}></img> */}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ my: "0rem" }}></Divider>
                                </>
                            )}

                            {(opportunityData?.opportunity?.hasActiveProposal || userType === "user") && (
                                <>
                                    {opportunityData?.opportunity?.ownerName &&
                                        (opportunityData?.opportunity?.proposalIntroduction ||
                                            opportunityData?.proposalVideo) && (
                                            <Box className="note">{displayNotes()}</Box>
                                        )}
                                    {displayOpportunityStep()}
                                    <CardContent sx={{ padding: "0" }}>
                                        {status === "initalLoading" ? (
                                            skeleton
                                        ) : (
                                            <div style={styles.container}>
                                                <Box sx={{ pt: 0, mt: "0" }}>
                                                    {/* {
                                speakerFilter === 'Shortlisted' && opportunityData?.groupedData && Object.keys(opportunityData?.groupedData).map((key) => (
                                    cardComponent(opportunityData?.groupedData[key]?.records.filter(item => item.shortlistStatus === 'Shortlist'), opportunityData?.groupedData[key]?.group)
                                ))
                            }

                            {
                                speakerFilter === 'Rejected' && opportunityData?.groupedData && Object.keys(opportunityData?.groupedData).map((key) => (
                                    cardComponent(opportunityData?.groupedData[key]?.records.filter(item => item.shortlistStatus === 'Removed'), opportunityData?.groupedData[key]?.group)
                                ))
                            } */}

                                                    {opportunityData?.groupedData && (
                                                        <Box sx={{ width: "100%", typography: "body1" }}>
                                                            <TabContext value={tabValue}>
                                                                <Box
                                                                    sx={{
                                                                        borderBottom: 1,
                                                                        borderColor: "divider",
                                                                        mb: "10px",
                                                                    }}>
                                                                    <TabList
                                                                        onChange={handleChange}
                                                                        variant="scrollable"
                                                                        scrollButtons="auto">
                                                                        <Tab
                                                                            label="Proposed Speakers"
                                                                            icon={
                                                                                <Chip
                                                                                    size="small"
                                                                                    label={
                                                                                        opportunityData?.notSelectedRecordCount
                                                                                    }
                                                                                />
                                                                            }
                                                                            iconPosition="end"
                                                                            value="1"
                                                                        />
                                                                        {opportunityData?.groupedData["Shortlist"]
                                                                            ?.records && (
                                                                            <Tab
                                                                                label="Shortlisted"
                                                                                icon={
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            opportunityData
                                                                                                ?.groupedData[
                                                                                                "Shortlist"
                                                                                            ]?.records.length
                                                                                        }
                                                                                    />
                                                                                }
                                                                                iconPosition="end"
                                                                                value="5"
                                                                            />
                                                                        )}
                                                                        {opportunityData?.groupedData[
                                                                            "Availability Requested"
                                                                        ]?.records && (
                                                                            <Tab
                                                                                label="Availability Requests"
                                                                                icon={
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            opportunityData
                                                                                                ?.groupedData[
                                                                                                "Availability Requested"
                                                                                            ]?.records.length
                                                                                        }
                                                                                    />
                                                                                }
                                                                                iconPosition="end"
                                                                                value="4"
                                                                            />
                                                                        )}
                                                                        {opportunityData?.groupedData["Available"]
                                                                            ?.records && (
                                                                            <Tab
                                                                                label="AVAILABILITY CONFIRMED"
                                                                                icon={
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            opportunityData
                                                                                                ?.groupedData[
                                                                                                "Available"
                                                                                            ]?.records.length
                                                                                        }
                                                                                    />
                                                                                }
                                                                                iconPosition="end"
                                                                                value="3"
                                                                            />
                                                                        )}
                                                                        {opportunityData?.groupedData["Booked"]
                                                                            ?.records && (
                                                                            <Tab
                                                                                label="BOOKING REQUESTS"
                                                                                icon={
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            opportunityData
                                                                                                ?.groupedData["Booked"]
                                                                                                ?.records.length
                                                                                        }
                                                                                    />
                                                                                }
                                                                                iconPosition="end"
                                                                                value="7"
                                                                            />
                                                                        )}
                                                                        {opportunityData?.groupedData["Booked Speakers"]
                                                                            ?.records && (
                                                                            <Tab
                                                                                label="BOOKED SPEAKERS"
                                                                                icon={
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            opportunityData
                                                                                                ?.groupedData[
                                                                                                "Booked Speakers"
                                                                                            ]?.records.length
                                                                                        }
                                                                                    />
                                                                                }
                                                                                iconPosition="end"
                                                                                value="9"
                                                                            />
                                                                        )}
                                                                        {opportunityData?.groupedData["Rejected"]
                                                                            ?.records && (
                                                                            <Tab
                                                                                label="Removed"
                                                                                icon={
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={
                                                                                            opportunityData
                                                                                                ?.groupedData[
                                                                                                "Rejected"
                                                                                            ]?.records.length
                                                                                        }
                                                                                    />
                                                                                }
                                                                                iconPosition="end"
                                                                                value="6"
                                                                            />
                                                                        )}
                                                                    </TabList>
                                                                </Box>
                                                                <TabPanel value="1" sx={{ px: "20px" }}>
                                                                    {speakerActionsMatch(
                                                                        opportunityData?.groupedData,
                                                                        opportunityData?.records?.length
                                                                    ) && (
                                                                        <Alert severity="success">
                                                                            Great! You've taken action on all the
                                                                            currently proposed speakers!
                                                                        </Alert>
                                                                    )}

                                                                    {/* <Typography variant="h2" sx={{ fontSize: '25px' }}>Proposed Speakers For Your Event</Typography> */}
                                                                    {opportunityData?.groupedData &&
                                                                        Object.keys(opportunityData?.groupedData).map(
                                                                            (group) => {
                                                                                // cardComponent(opportunityData?.groupedData[group]?.records, opportunityData?.groupedData[group]?.group)
                                                                                let excludeStatuses = [
                                                                                    "Availability Requested",
                                                                                    "Available",
                                                                                    "Rejected",
                                                                                    "Shortlist",
                                                                                    "Unavailable",
                                                                                    "Booked",
                                                                                    "Booked Speakers",
                                                                                    "x",
                                                                                ];
                                                                                if (!excludeStatuses.includes(group))
                                                                                    return cardComponent(
                                                                                        opportunityData?.groupedData[
                                                                                            group
                                                                                        ]?.records,
                                                                                        opportunityData?.groupedData[
                                                                                            group
                                                                                        ]?.group,
                                                                                        true
                                                                                    );
                                                                            }
                                                                        )}

                                                                    {opportunityData?.groupedData["x"]?.records && (
                                                                        <Box sx={{}}>
                                                                            {cardComponent(
                                                                                opportunityData?.groupedData["x"]
                                                                                    ?.records,
                                                                                opportunityData?.groupedData["x"]
                                                                                    ?.group,
                                                                                false
                                                                            )}
                                                                        </Box>
                                                                    )}
                                                                </TabPanel>
                                                                <TabPanel value="3" sx={{ px: "20px" }}>
                                                                    {opportunityData?.groupedData["Available"]
                                                                        ?.records &&
                                                                        Object.keys(
                                                                            opportunityData?.groupedData
                                                                        ).indexOf("Available") > -1 &&
                                                                        cardComponent(
                                                                            opportunityData?.groupedData["Available"]
                                                                                ?.records,
                                                                            opportunityData?.groupedData["Available"]
                                                                                ?.group
                                                                        )}
                                                                </TabPanel>
                                                                <TabPanel value="4" sx={{ px: "20px" }}>
                                                                    {opportunityData?.groupedData[
                                                                        "Availability Requested"
                                                                    ]?.records &&
                                                                        Object.keys(
                                                                            opportunityData?.groupedData
                                                                        ).indexOf("Availability Requested") > -1 &&
                                                                        cardComponent(
                                                                            opportunityData?.groupedData[
                                                                                "Availability Requested"
                                                                            ]?.records,
                                                                            opportunityData?.groupedData[
                                                                                "Availability Requested"
                                                                            ]?.group
                                                                        )}
                                                                </TabPanel>
                                                                <TabPanel value="7" sx={{ px: "20px" }}>
                                                                    {opportunityData?.groupedData["Booked"]?.records &&
                                                                        Object.keys(
                                                                            opportunityData?.groupedData
                                                                        ).indexOf("Booked") > -1 &&
                                                                        cardComponent(
                                                                            opportunityData?.groupedData["Booked"]
                                                                                ?.records,
                                                                            opportunityData?.groupedData["Booked"]
                                                                                ?.group
                                                                        )}
                                                                </TabPanel>
                                                                <TabPanel value="9" sx={{ px: "20px" }}>
                                                                    {opportunityData?.groupedData["Booked Speakers"]
                                                                        ?.records &&
                                                                        Object.keys(
                                                                            opportunityData?.groupedData
                                                                        ).indexOf("Booked Speakers") > -1 &&
                                                                        cardComponent(
                                                                            opportunityData?.groupedData[
                                                                                "Booked Speakers"
                                                                            ]?.records,
                                                                            opportunityData?.groupedData[
                                                                                "Booked Speakers"
                                                                            ]?.group
                                                                        )}
                                                                </TabPanel>
                                                                <TabPanel value="5" sx={{ px: "20px" }}>
                                                                    {opportunityData?.groupedData["Shortlist"]
                                                                        ?.records &&
                                                                        Object.keys(
                                                                            opportunityData?.groupedData
                                                                        ).indexOf("Shortlist") > -1 && (
                                                                            <>
                                                                                {/* {opportunityData?.groupedData['Shortlist']?.records?.filter(item => item.status === 'Shortlist').length > 0 && <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                        <Button onClick={(event) => handleRequestAvailabilityAllClick(event, opportunityData?.groupedData['Shortlist']?.records)} sx={{ ...styles.outlinedButton, mb: '10px' }} startIcon={<EventAvailableIcon fontSize='small' sx={{ color: config.primaryColor }} />} variant="outlined">Request availability for all shortlisted speakers</Button>
                                                                    </Box> } */}

                                                                                {cardComponent(
                                                                                    opportunityData?.groupedData[
                                                                                        "Shortlist"
                                                                                    ]?.records,
                                                                                    opportunityData?.groupedData[
                                                                                        "Shortlist"
                                                                                    ]?.group
                                                                                )}
                                                                            </>
                                                                        )}
                                                                </TabPanel>
                                                                <TabPanel value="6" sx={{ px: "20px" }}>
                                                                    {opportunityData?.groupedData["Rejected"]
                                                                        ?.records &&
                                                                        Object.keys(
                                                                            opportunityData?.groupedData
                                                                        ).indexOf("Rejected") > -1 &&
                                                                        cardComponent(
                                                                            opportunityData?.groupedData["Rejected"]
                                                                                ?.records,
                                                                            opportunityData?.groupedData["Rejected"]
                                                                                ?.group
                                                                        )}
                                                                </TabPanel>
                                                            </TabContext>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </div>
                                        )}
                                    </CardContent>
                                </>
                            )}

                            {opportunityData &&
                                userType !== "user" &&
                                opportunityData.opportunity &&
                                !opportunityData.opportunity.hasActiveProposal && (
                                    <Box
                                        sx={{
                                            py: "20px",
                                            borderBottom: "1px solid #e7e8f0 !important",
                                        }}>
                                        <Typography sx={{ textAlign: "center", my: "20px" }}>
                                            This proposal is pending. Please reach out to{" "}
                                            {opportunityData?.opportunity?.ownerFirstName} with any questions.
                                        </Typography>
                                    </Box>
                                )}
                        </Card>
                    </Box>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={requestToBookLoading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Modal
                        open={openPollModal}
                        title="Speaker Poll"
                        onClose={() => setOpenPollModal(false)}
                        hideActions
                        fullWidth
                        maxWidth="lg">
                        <Box sx={{ minHeight: "70vh" }}>
                            {opportunityData?.opportunity && (
                                <Poll
                                    onFinish={() => {
                                        setOpenPollModal(false);
                                    }}
                                    handleAboutClick={handleAboutClick}
                                    displayFeeDetails={displayFeeDetails}
                                    speakers={opportunityData.records}
                                    opportunity={opportunityData?.opportunity}
                                    userDetails={userDetails}
                                    setDisplayPollResultModal={setDisplayPollResultModal}></Poll>
                            )}
                        </Box>
                    </Modal>
                    <Modal
                        open={displayPollResultModal}
                        title="Poll Result"
                        onClose={() => setDisplayPollResultModal(false)}
                        hideActions
                        fullWidth
                        maxWidth="sm">
                        <Box sx={{ minHeight: "70vh" }}>
                            {opportunityData?.opportunity && (
                                <Box>
                                    <PollResult
                                        shareProposalDetails={shareProposalDetails}
                                        speakers={opportunityData.records}
                                        opportunity={opportunityData?.opportunity}
                                        userDetails={userDetails}></PollResult>
                                </Box>
                            )}
                        </Box>
                    </Modal>

                    <Modal
                        open={openShareLinkModal}
                        title="Share Proposal Link"
                        onClose={handleShareLinkModalClose}
                        actions={
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    flexDirection: { xs: "column-reverse", md: "row" },
                                }}>
                                <Box>
                                    {/* <Button
                                        startIcon={<ContentCopyIcon />}
                                        variant="outlined"
                                        color="gray"
                                        sx={{ height: "41px", mt: { xs: "10px", md: "0px" } }}>
                                        Copy Proposal Link
                                    </Button> */}
                                </Box>

                                <Box>
                                    <Button
                                        variant="outlined"
                                        color="gray"
                                        sx={{ height: "41px", mr: "8px" }}
                                        onClick={() => {
                                            setOpenShareLinkModal(false);
                                        }}>
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        loading={loadingShareProposal}
                                        variant="contained"
                                        sx={{ height: "41px", background: "#4F46E5" }}
                                        onClick={handleShareProposal}>
                                        Share Proposal
                                    </LoadingButton>
                                </Box>
                            </Box>
                        }
                        fullWidth
                        maxWidth="sm">
                        <Box sx={{ minHeight: "70vh" }}>
                            {opportunityData?.opportunity && (
                                <ShareProposal
                                    ref={shareProposalRef}
                                    shareProposalDetails={shareProposalDetails}
                                    onChange={handleShareProposalDetailsChange}
                                    pollDetail={{
                                        noOfVote: opportunityData?.opportunity?.numberOfVotesPerParticipant
                                            ? opportunityData?.opportunity?.numberOfVotesPerParticipant
                                            : 1,
                                        pollEnabled: opportunityData?.opportunity?.pollEnabled
                                            ? opportunityData?.opportunity?.pollEnabled
                                            : false,
                                    }}
                                    userDetails={userDetails}
                                    opportunityId={recordId}></ShareProposal>
                            )}

                            {/* <Box sx={{ mb: "10px" }}>This link provides view only access.</Box>
                            <CopyClipboardText
                                sx={{ mb: "40px" }}
                                label="View Only Link"
                                value={sharedUrl}
                                onCopy={handleLinkCopy}
                                tooltip=""></CopyClipboardText>
                            <Box sx={{ mb: "10px" }}>
                                Use this to collaborate on the review of this proposal with a co-worker.
                            </Box>
                            <CopyClipboardText
                                label="Collaboration Link"
                                value={loginRequiredUrl}
                                onCopy={handleLinkCopy}></CopyClipboardText> */}
                        </Box>
                    </Modal>
                    <Modal
                        open={openSpeakerScreen}
                        title="Speaker details"
                        onClose={() => setOpenSpeakerScreen(false)}
                        hideActions
                        maxWidth="lg"
                        removeTabIndex
                        sx={{ padding: "0px" }}
                        contentSx={{ overflowY: "hidden" }}>
                        <Box
                            sx={{
                                height: "calc(100vh - 130px)",
                                width: {
                                    xs: "300px",
                                    md: "590px",
                                    lg: "auto",
                                },
                            }}>
                            <Speaker
                                shared={shared}
                                actionButtons={displayActionButtons(selectedSpeaker)}
                                userDetails={userDetails}
                                opportunityId={recordId}
                                opportunityName={opportunityData?.opportunity?.eventName}
                                opportunityCreatedDate={opportunityData?.opportunity?.createdDate}
                                speakerId={selectedSpeakerId}
                                speakerRecord={selectedSpeaker}
                                onRequestAvailabilityClick={handleRequestAvailabilityClick}
                                feeDetails={displayFeeDetails(selectedSpeaker)}
                                opportunity={opportunityData?.opportunity}
                                removeActions={openPollModal}></Speaker>
                        </Box>
                    </Modal>
                    <Modal
                        open={openConfirmBookingScreen}
                        title="Confirm Booking Request Details"
                        onClose={() => {
                            setOpenConfirmBookingScreen(false);
                            setConfirmBookingRecord(undefined);
                            setCalendarEntryRecord(undefined);
                        }}
                        maxWidth="lg"
                        actions={
                            <>
                                <LoadingButton
                                    id="request-to-book-save"
                                    sx={{ width: { lg: "auto", xs: "100%" } }}
                                    loading={saveBookingLoadingButton}
                                    onClick={handleSaveBooking}>
                                    Save
                                </LoadingButton>
                                <LoadingButton
                                    id="request-to-book-submit"
                                    sx={{ width: { lg: "auto", xs: "100%" } }}
                                    loading={confirmBookingLoadingButton}
                                    onClick={handleConfirmBooking}>
                                    Submit
                                </LoadingButton>

                                {/* <Button onClick={handleConfirmBooking}>
                            Submit
                        </Button> */}
                            </>
                        }>
                        <Box
                            sx={{
                                minWidth: {
                                    xs: "100%",
                                    md: "590px",
                                    lg: "950px",
                                },
                            }}>
                            {calendarEntryRecord && displayRequestToBook()}
                        </Box>
                    </Modal>
                    <Modal
                        open={openRequestAvailabilityModal}
                        title="Request Availability"
                        onClose={handleRequestAvailabilityModalClose}
                        maxWidth="lg"
                        actions={
                            <>
                                <LoadingButton
                                    loading={requestAvailabilityLoadingButton}
                                    onClick={handleRequestAvailabilitySave}
                                    sx={{ width: { lg: "auto", xs: "100%" } }}>
                                    Submit
                                </LoadingButton>
                            </>
                        }
                        header={
                            <>
                                <Grid sx={{ m: "-10px" }} container wrap="nowrap" spacing={0} justifyContent="start">
                                    <Grid item sx={{ marginTop: "6px", marginLeft: "12px" }}>
                                        {selectedSpeaker.speakerImageURL && (
                                            <Box
                                                component="img"
                                                sx={{
                                                    objectFit: "cover",
                                                    width: "80px",
                                                    height: "100px",
                                                    objectPosition: "top",
                                                    borderRadius: "4px",
                                                }}
                                                src={`${selectedSpeaker.speakerImageURL}`}></Box>
                                        )}
                                        {!selectedSpeaker.speakerImageURL && (
                                            <Skeleton variant="rounded" width={80} height={100} />
                                        )}
                                    </Grid>
                                    <Grid
                                        sx={{
                                            width: "100%",
                                            marginLeft: "34px",
                                            marginTop: "10px",
                                            fontSize: "20px",
                                        }}
                                        item>
                                        {`${selectedSpeaker.speakerName} Availability Request`}
                                    </Grid>
                                </Grid>
                            </>
                        }>
                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "590px",
                                    lg: "1110px",
                                },
                            }}>
                            {openRequestAvailabilityModal ? (
                                <CalendarEntry
                                    ref={calendarEntryRef}
                                    accountName={userDetails?.contact?.accountName}
                                    record={selectedSpeaker}
                                    opportunity={opportunityData?.opportunity}></CalendarEntry>
                            ) : (
                                ""
                            )}
                        </Box>
                    </Modal>
                    {
                        <Fab
                            aria-describedby="leave-comment-fab-modal"
                            id="leave-comment-fab"
                            sx={{
                                zIndex: "100000",
                                position: "fixed",
                                bottom: "15px",
                                right: "15px",
                                width: "66px",
                                height: "66px",
                            }}
                            color="success"
                            aria-label="add"
                            onClick={(event) =>
                                openCommnetModal
                                    ? handleClosePopoverComment()
                                    : handlePopoverComment(event.currentTarget)
                            }>
                            {unreadComments.length > 0 && (
                                <span className="unread-commnet-count">{unreadComments.length}</span>
                            )}
                            {!openCommnetModal && <AddCommentIcon sx={{ width: "25px" }}></AddCommentIcon>}
                            {openCommnetModal && <CloseIcon sx={{ width: "25px" }}></CloseIcon>}
                        </Fab>
                    }
                </div>
            )}
        </Track>
    );

    return getPageContent(body, status, error);
};

export { Proposal };
