import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPageContent } from '../../utils';

import {
    Box,
    Card,
    Button,
    TextField,
    Typography,
    CardContent,
    MenuItem,
    Divider,
    Drawer,
    Menu,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import {
    selectDashboardData,
    selectDashboardStatus,
    selectDashboardError,
    initializeAsync,
    createSpeakerListAsync
} from './dashboardSlice';
import { config, styles } from '../../style';
import Grid from '@mui/material/Unstable_Grid2';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CheckIcon from '@mui/icons-material/Check';
import { DashboardList } from '../../components/DashboardList';
import { Modal } from '../../components/Modal'

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useAuth } from '../../hooks/useAuth';

// Add custom imports here

const Dashboard = () => {
    const data = useSelector(selectDashboardData);
    const status = useSelector(selectDashboardStatus);
    const error = useSelector(selectDashboardError);
    const dispatch = useDispatch();

    // Add Variables

    const { getUserDetailsNotAsync } = useAuth();
    const userDetails = getUserDetailsNotAsync();

    const [selectedList, setSelectedList] = useState(undefined);
    const [openAddListModal, setOpenAddListModal] = useState(false);
    

    useEffect(() => {
        if(status === 'idle'){
            dispatch(initializeAsync({contactId: userDetails?.contact?.id}));
        }
    }, [status, dispatch]);

    const handleReccomendedListClick = (item) => {
        setSelectedList({ ...item, type: 'Private' });
        setOpenAddListModal(true)
    }

    const handleAddListModalClose = () => {
        setOpenAddListModal(false)
    }

    const handleAddListModalSave = () => {
        dispatch(createSpeakerListAsync({
            list: { ...selectedList, members: '', contactId: userDetails?.contact?.id, parentList: selectedList.id }, members: selectedList.members, callback: (result) => {
                if (result.isSuccess) {
                    const detailEvent = new CustomEvent('openToster', {detail: {variant: result.isSuccess ? 'success' : 'error', message: result.isSuccess ? 'Successfully created the speaker list' : result.errorMessage}})
                    document.dispatchEvent(detailEvent)
                }
        } }));
        setOpenAddListModal(false)
    }

    const existingUserList = (item) => {
        return data?.userListsParentId.indexOf(item.id) > -1;
    }

    let body = (
        <div style={styles.container}>
            <Card sx={styles.card}>
                <CardContent sx={{ }}>
                    <Grid container>
                        <Grid item>
                            <Typography sx={styles.cardHeading}>
                                Overview
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mx: '-15px', my: '10px' }} />
                    <Grid container spacing={1} sx={{marginTop: '10px'}}>
                        <Grid item lg={3}>
                            <Card sx={{ ...styles.innerCard, p: '10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box>
                                        <Box sx={{borderRadius: '50px', padding: '4px 5px 1px 5px', background: '#EEF4FF'}}>
                                            <PeopleIcon fontSize="small" sx={{ color: config.primaryColor }}></PeopleIcon>
                                        </Box>
                                    </Box>
                                    <Box sx={{ml: '15px'}}>
                                        <Typography sx={{ ...styles.cardHeading, fontSize: '15px' }}>
                                            345
                                        </Typography>
                                        <Typography sx={styles.subHeading}>
                                            Booked Speakers
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item lg={3}>
                            <Card sx={{ ...styles.innerCard, p: '10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box>
                                        <Box sx={{borderRadius: '50px', padding: '4px 5px 1px 5px', background: '#EEF4FF'}}>
                                            <EventIcon fontSize="small" sx={{ color: config.primaryColor }}></EventIcon>
                                        </Box>
                                    </Box>
                                    <Box sx={{ml: '15px'}}>
                                        <Typography sx={{ ...styles.cardHeading, fontSize: '15px' }}>
                                            32
                                        </Typography>
                                        <Typography sx={styles.subHeading}>
                                            Events
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item lg={3}>
                            <Card sx={{ ...styles.innerCard, p: '10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box>
                                        <Box sx={{borderRadius: '50px', padding: '4px 5px 1px 5px', background: '#EEF4FF'}}>
                                            <PlaylistAddIcon fontSize="small" sx={{ color: config.primaryColor }}></PlaylistAddIcon>
                                        </Box>
                                    </Box>
                                    <Box sx={{ml: '15px'}}>
                                        <Typography sx={{ ...styles.cardHeading, fontSize: '15px' }}>
                                            54
                                        </Typography>
                                        <Typography sx={styles.subHeading}>
                                            Proposal Requested
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item lg={3} >
                            <Card sx={{ ...styles.innerCard, p: '10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box>
                                        <Box sx={{borderRadius: '50px', padding: '4px 5px 1px 5px', background: '#EEF4FF'}}>
                                            <VerifiedUserIcon fontSize="small" sx={{ color: config.primaryColor }}></VerifiedUserIcon>
                                        </Box>
                                    </Box>
                                    <Box sx={{ml: '15px'}}>
                                        <Typography sx={{ ...styles.cardHeading, fontSize: '15px' }}>
                                            53
                                        </Typography>
                                        <Typography sx={styles.subHeading}>
                                            Placeholder Metric
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid container spacing={3} sx={{mt: '10px'}}>
                <Grid lg={5}>
                    <Card sx={styles.card}>
                        <CardContent sx={{ }}>
                            <Grid container sx={{p: 0}}>
                                <Grid item>
                                    <Typography sx={styles.cardHeading}>
                                        WSB Recommended Speakers Lists
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ mx: '-15px', my: '10px' }} />
                            {
                                data?.reccomendedList?.map(item => (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'start', 
                                        mb: '8px', 
                                        border: '1px solid #ebecf0', 
                                        padding: '5px 10px',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            background: '#EEF4FF'
                                        }
                                    }}>
                                        <Box sx={{borderRadius: '50px', padding: '4px 5px 1px 5px', background: '#EEF4FF'}}>
                                            <FormatListBulletedIcon fontSize="small" sx={{ color: config.primaryColor, marginTop: '1px'}}></FormatListBulletedIcon>
                                        </Box>
                                        <Box sx={{ml: '10px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Box >
                                                <Typography sx={styles.subHeading}>
                                                    {item.name}
                                                </Typography>
                                                
                                            </Box>
                                            <Box sx={{ mt: '4px' }}>
                                                {!existingUserList(item) ?
                                                    <AddCircleIcon fontSize="small" sx={{ color: config.primaryColor }} onClick={() => handleReccomendedListClick(item)}></AddCircleIcon>
                                                    : <CheckIcon fontSize="small" sx={{ color: config.brandColor }}></CheckIcon>}    
                                                
                                            </Box>
                                        </Box>
                                        
                                    </Box>
                                ))
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid lg={7}>
                    <Card sx={{ ...styles.card, height: '100%' }}>
                        <CardContent sx={{textAlign: 'center' }}>
                            <Grid container sx={{p: 0}}>
                                <Grid item>
                                    <Typography sx={styles.cardHeading}>
                                        Number of events across time
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ mx: '-15px', my: '10px' }} />
                            No data
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box sx={{mt: '10px'}}>
                <DashboardList></DashboardList>
            </Box>
            
            <Modal open={openAddListModal} title="Shared Link" onClose={handleAddListModalClose} onSave={handleAddListModalSave} maxWidth="md" >
                <Box>
                    <b>This will create the new speaker list for you, you can find it on the speaker search page.</b>
                    <TextField sx={{ mt: '2rem', mb: '1rem' }} value={selectedList?.name} onChange={(event) => setSelectedList({ ...selectedList, name: event.target.value })} fullWidth id="outlined-basic" label="List name" variant="outlined" />
                    <FormControl sx={{ mb: '1rem'}} fullWidth>
                        <InputLabel id="type-label">Please select the type for the list</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            value={selectedList?.type}
                            label="Please select the type for the list"
                            onChange={(event) => setSelectedList({ ...selectedList, type: event.target.value })}
                        >
                            <MenuItem value='Private'>Private</MenuItem>
                            <MenuItem value='Public'>Public</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Modal>
        </div>
    )

    return getPageContent(body, status, error);
}

export { Dashboard }