import * as React from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ShareMenu = ({ id, defaultValue, onChange, disabled, displayDelete }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState(defaultValue ? defaultValue : "can view");
    React.useEffect(() => {
        setValue(defaultValue ? defaultValue : "can view");
    }, [defaultValue]);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        if (value !== "remove access") {
            setValue(value);
        }
        onChange(value);
        setAnchorEl(null);
    };
    return (
        <Box sx={{ minWidth: value === "can view" ? "80px" : value === "Owner" ? "68px" : "120px" }}>
            <Button
                sx={{ p: "0 5px", fontSize: "12px" }}
                id={id + "-button"}
                aria-controls={open ? id + "-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disabled={disabled}
                endIcon={<KeyboardArrowDownIcon sx={{ mt: "2px", width: "14px", color: "#4F46E5" }} />}>
                {value}
            </Button>
            <Menu
                id={id + "-menu"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": id + "-button",
                }}>
                <MenuItem onClick={() => handleSelect("can view")}>can view</MenuItem>
                <MenuItem onClick={() => handleSelect("can collaborate")}>can collaborate</MenuItem>
                {displayDelete && <MenuItem onClick={() => handleSelect("remove access")}>remove access</MenuItem>}
            </Menu>
        </Box>
    );
};

export { ShareMenu };
