import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Switch,
    Link,
} from "@mui/material";
import { post } from "../../api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { describe, query } from "../../services/salesforce";
import GoogleAutocomplete from "../../components/GoogleAutocomplete";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { RichTextArea } from "../../components/RichTextArea";
import RequestFormInputs from "./requestFormInputs";
import InfoIcon from "@mui/icons-material/Info";
import "./style.css";

const ResponsiveRequestForm = ({ showDataList = [] }) => {
    showDataList = showDataList.filter((cItem) => {
        if (cItem.label === "Selected Fees") {
            return cItem;
        }
        if (cItem) {
            return cItem.shouldNotShow ? false : cItem;
        } else {
            return false;
        }
    });

    if (showDataList.length <= 0) {
        return null;
    }

    return (
        <Box
            sx={{
                display: { xs: "block", sm: "none" },
            }}>
            {showDataList?.map((cDataItem) => {
                //
                if (cDataItem.label === "Selected Fees") {
                    return cDataItem.Section;
                }

                if (cDataItem.shouldNotShow) {
                    return null;
                }

                return (
                    <CustomAccordion label={cDataItem.label} isCompleted={cDataItem.isCompleted}>
                        {cDataItem.Section}
                    </CustomAccordion>
                );
            })}
        </Box>
    );
};

const CustomAccordion = (props) => {
    const [expanded, setExpanded] = useState(true);
    let { label, children, isCompleted } = props;

    let id = label.replaceAll(" ", "-").toLowerCase();

    const handleClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Accordion
                className="request-to-book"
                sx={{
                    mb: expanded ? "0px" : "1rem",
                    boxShadow: expanded ? "gray" : "none",
                }}
                defaultExpanded={true}
                expanded={expanded}>
                <AccordionSummary
                    sx={{
                        background: "#eeeeee",
                        minHeight: "auto !important",
                        borderRadius: expanded ? "7px 7px 0 0" : "7px 7px 7px 7px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleClick}
                    aria-controls="panel1a-content"
                    id={id}>
                    {!isCompleted && (
                        <RadioButtonUncheckedIcon
                            sx={{ mr: "10px", ml: "-8px", color: "#d4d4d4" }}></RadioButtonUncheckedIcon>
                    )}
                    {isCompleted && (
                        <CheckCircleOutlineIcon
                            sx={{ mr: "10px", ml: "-8px", color: "#189212" }}></CheckCircleOutlineIcon>
                    )}
                    <Typography>{label}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ py: "15px" }}>{children}</AccordionDetails>
            </Accordion>
        </>
    );
};

export const RequestToBook = forwardRef((props, ref) => {
    const {
        shared,
        calendarEntryRecord,
        setCalendarEntryRecord,
        confirmBookingRecord,
        setBookingConfirmNote,
        bookingConfirmNote,
        currentPage,
        initialCalendarDataEntry,
        pageHeading,
    } = props;
    const [countryPicklist, setCountryPicklist] = useState([]);
    const [heading, setHeading] = useState(pageHeading ? pageHeading : "Request to Book");
    const [eventTypePicklist, setEventTypePicklist] = useState([]);
    const [platformPicklist, setPlatformPicklist] = useState([]);
    const [mediaCoveragePicklist, setMediaCoveragePicklist] = useState([]);
    const [recordingTypePicklist, setRecordingTypePicklist] = useState([]);
    const [speechTopics, setSpeechTopics] = useState([]);
    const [hasEmailError, setHasEmailError] = useState(false);

    const [savingContactIssueTo, setSavingContactIssueTo] = useState(false);
    const [savingPreEventContact, setSavingPreEventContact] = useState(false);
    const [savingOnSiteContact, setSavingOnSiteContact] = useState(false);

    const [listItemLabel, setListItemLabel] = useState("");
    const [showAllData, setShowAllData] = useState(false);

    const [initialEntryRecord, setInitialEntryRecord] = useState(calendarEntryRecord || {});

    const savingContactRef = useRef(false);

    const checkboxOption = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ];

    const timerRef = useRef(undefined);

    useEffect(() => {
        if (!shared) {
            getCalendarEntryDescribe();
            getSpeakerSpeechTopic();
            setTimeout(() => {
                setBookingCompleted();
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (currentPage === "RequestToBookPage") {
            setInitialEntryRecord({ ...initialCalendarDataEntry });
        } else {
            setInitialEntryRecord({ ...calendarEntryRecord });
        }
    }, [initialCalendarDataEntry]);

    useImperativeHandle(ref, () => ({
        getData() {
            let records = [];

            return records;
        },
    }));

    const getSpeakerSpeechTopic = async () => {
        let result = await query("speechTopic", "getSpeakerRecords", {
            speakerId: calendarEntryRecord.speakerId,
            opportunityId: calendarEntryRecord.opportunityId,
        });
        if (result.isSuccess) {
            setSpeechTopics(result.data);
        }
    };

    const getCalendarEntryDescribe = async () => {
        const result = await describe("calendarEntry", { cacheable: true });
        if (result.isSuccess) {
            for (let field of result?.data?.fields) {
                if (field.name === "Location_Country_Global__c") {
                    setCountryPicklist(
                        field.picklistValues.filter((item) => item.label !== "Virtual / Remote Broadcast")
                    );
                } else if (field.name === "New_Event_Type__c") {
                    setEventTypePicklist(field.picklistValues);
                } else if (field.name === "Platform__c") {
                    setPlatformPicklist(field.picklistValues);
                } else if (field.name === "Media_Coverage__c") {
                    setMediaCoveragePicklist(field.picklistValues);
                } else if (field.name === "Recording_Type__c") {
                    setRecordingTypePicklist(field.picklistValues);
                }
            }
        }
    };

    const isVirtual = () => {
        return calendarEntryRecord.eventType === "Virtual";
    };

    const isOtherPlatform = () => {
        return calendarEntryRecord.platform === "Other";
    };

    const isCustomSpeechTopic = () => {
        return calendarEntryRecord.speechTopic === "custom";
    };

    const handleEmailChange = (value, fieldName, errorElementId) => {
        handleChange(value, fieldName);
        const errorElement = document.querySelector("#" + errorElementId);
        if (errorElement) {
            errorElement.style.display = "none";
        }
    };

    const validateAllEmail = () => {
        let record = { ...calendarEntryRecord };
        const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (
            (record["preEventContactEmail"] && !emailRegex?.test(record["preEventContactEmail"])) ||
            (record["contractIssuedToEmail"] && !emailRegex?.test(record["contractIssuedToEmail"])) ||
            (record["onsiteContactEmail"] && !emailRegex?.test(record["onsiteContactEmail"]))
        ) {
            document.querySelector("#request-to-book-save")?.setAttribute("disabled", true);
            document.querySelector("#request-to-book-save")?.classList.add("Mui-disabled");
        } else {
            if (!savingContactRef.current) {
                document.querySelector("#request-to-book-save")?.removeAttribute("disabled");
                document.querySelector("#request-to-book-save")?.classList.remove("Mui-disabled");
            }
        }
    };

    const handleChange = (value, fieldName) => {
        let record = { ...calendarEntryRecord };
        record[fieldName] = value;

        if (fieldName === "preEventContactSameAsContractIssue" && record[fieldName] === true) {
            record.preEventContact = "";
            record.preEventContactName = "";
            record.preEventContactTitle = "";
            record.preEventContactTelephone = "";
            record.preEventContactMobilePhone = "";
            record.preEventContactEmail = "";
            record.preEventContactStreet1 = "";
            record.preEventContactCity = "";
            record.preEventContactState = "";
            record.preEventContactCountry = "";
            record.preEventContactZip = "";
        } else if (fieldName === "onsiteContactSameAs" && record[fieldName] === true) {
            record.onsiteContact = "";
            record.onsiteContactName = "";
            record.onsiteContactTitle = "";
            record.onsiteContactTelephone = "";
            record.onsiteContactMobilePhone = "";
            record.onsiteContactEmail = "";
            record.onsiteContactStreet1 = "";
            record.onsiteContactCity = "";
            record.onsiteContactState = "";
            record.onsiteContactCountry = "";
            record.onsiteContactZip = "";
        }

        if (fieldName === "eventType" && record.eventType === "Virtual") {
            record.venue = "Platform";
            record.venueStreet1 = "Platform";
            record.venueStreet2 = "Platform";
            record.locationCity = "Platform";
            record.locationState = "Platform";
            record.venueCountry = "Platform";
            record.venueZip = "Platform";
            record.venueFaxNumber = "Platform";
            record.venuePhoneNumber = "Platform";
        }

        if (fieldName === "eventType" && record.eventType !== "Virtual") {
            record.venue = "";
            record.venueStreet1 = "";
            record.venueStreet2 = "";
            record.locationCity = "";
            record.locationState = "";
            record.venueCountry = "";
            record.venueZip = "";
            record.venueFaxNumber = "";
            record.venuePhoneNumber = "";
        }

        setCalendarEntryRecord({
            ...record,
        });
    };

    const handleBlur = () => {
        createContactRecord(calendarEntryRecord);
    };

    const createContactRecord = (record) => {
        if (validateContractIssuedTo() && !record.contractIssuedTo) {
            record.contractIssuedTo = "blank";

            setCalendarEntryRecord({
                ...record,
            });

            let contact = prepareName(record.contractIssuedToName);
            contact.Phone = record.contractIssuedToTelephone;
            contact.MobilePhone = record.contractIssuedToMobilePhone;
            contact.Email = record.contractIssuedToEmail;
            contact.MailingStreet =
                record.contractIssuedToStreet1 +
                (record.contractIssuedToStreet2 ? " " + record.contractIssuedToStreet2 : "");
            contact.MailingCity = record.contractIssuedToCity;
            contact.MailingCountry = record.contractIssuedToCountry;
            contact.MailingPostalCode = record.contractIssuedToZip;

            createIfNotExistContact(contact, "contractIssuedTo");
        }

        if (
            validatePrevEventContract() &&
            !calendarEntryRecord.preEventContactSameAsContractIssue &&
            !calendarEntryRecord.preEventContact
        ) {
            record.preEventContact = "blank";

            setCalendarEntryRecord({
                ...record,
            });
            let contact = prepareName(record.preEventContactName);
            contact.Phone = record.preEventContactTelephone;
            contact.MobilePhone = record.preEventContactMobilePhone;
            contact.Email = record.preEventContactEmail;

            createIfNotExistContact(contact, "preEventContact");
        }

        if (validateOnSiteContact() && !calendarEntryRecord.onsiteContactSameAs && !calendarEntryRecord.onsiteContact) {
            record.onsiteContact = "blank";

            setCalendarEntryRecord({
                ...record,
            });
            let contact = prepareName(record.onsiteContactName);
            contact.Phone = record.onsiteContactTelephone;
            contact.MobilePhone = record.onsiteContactMobilePhone;
            contact.Email = record.onsiteContactEmail;

            createIfNotExistContact(contact, "onsiteContact");
        }
    };

    const prepareName = (value) => {
        let contact = {};
        let splittedName = value?.trim()?.split(" ");
        if (splittedName.length === 1) {
            contact.LastName = value;
        } else if (splittedName.length === 2) {
            contact.FirstName = splittedName[0];
            contact.LastName = splittedName[1];
        } else if (splittedName.length === 3) {
            contact.FirstName = splittedName[0];
            contact.MiddleName = splittedName[1];
            contact.LastName = splittedName[2];
        } else if (splittedName.length > 3) {
            contact.FirstName = splittedName[0];
            contact.MiddleName = splittedName[1];
            contact.LastName = splittedName.slice(-2).join(" ");
        }

        return contact;
    };

    const createIfNotExistContact = (contact, field) => {
        document.querySelector("#request-to-book-save")?.setAttribute("disabled", true);
        document.querySelector("#request-to-book-save")?.classList.add("Mui-disabled");
        savingContactRef.current = true;
        if (field === "contractIssuedTo") {
            setSavingContactIssueTo(true);
        } else if (field === "preEventContact") {
            setSavingPreEventContact(true);
        } else if (field === "onsiteContact") {
            setSavingOnSiteContact(true);
        }
        post("opportunity/createIfNotExistContact", {
            opportunityId: calendarEntryRecord.opportunityId,
            contact: contact,
        }).then(async (result) => {
            if (result.isSuccess && result.data) {
                calendarEntryRecord[field] = result.data.Id;

                setCalendarEntryRecord({
                    ...calendarEntryRecord,
                });
            } else {
                calendarEntryRecord[field] = "";

                setCalendarEntryRecord({
                    ...calendarEntryRecord,
                });
            }
            setSavingContactIssueTo(false);
            setSavingPreEventContact(false);
            setSavingOnSiteContact(false);

            document.querySelector("#request-to-book-save")?.removeAttribute("disabled");
            document.querySelector("#request-to-book-save")?.classList.remove("Mui-disabled");
            savingContactRef.current = false;
        });
    };

    const handleVenueChange = (value, type) => {
        let record = { ...calendarEntryRecord };
        if (type === "venue") {
            record.venue = value.label?.substring(0, 254);
            record.venueStreet1 = value.street1;
            record.venueStreet2 = value.street2;
            record.locationCity = value.city;
            record.locationState = value.state;
            record.venueCountry = value.country;
            record.locationCountry = value.country;
            record.venueZip = value.zipcode;
        } else {
            record.hotel = value.label.substring(0, 254);
            record.hotelStreet1 = value.street1;
            record.hotelStreet2 = value.street2;
            record.hotelCity = value.city;
            record.hotelState = value.state;
            record.hotelCountry = value.country;
            record.hotelCountryGlobal = value.country;
            record.hotelZip = value.zipcode;
        }

        setCalendarEntryRecord({
            ...record,
        });
    };

    const setBookingCompleted = () => {
        let validated = validateShowAllDataAndSubmitButton();

        if (!validated) {
            document.querySelector("#request-to-book-submit")?.setAttribute("disabled", true);
            document.querySelector("#request-to-book-submit")?.classList.add("Mui-disabled");
        } else {
            if (!savingContactRef.current) {
                document.querySelector("#request-to-book-submit")?.removeAttribute("disabled");
                document.querySelector("#request-to-book-submit")?.classList.remove("Mui-disabled");
            }
        }
    };

    const setShowAllDataVisible = () => {
        let validated = validateShowAllDataAndSubmitButton(initialEntryRecord);

        if (validated) {
            // if all data filled show all fields
            !showAllData && setShowAllData(true);
        }
    };

    const getLocationName = (record, type) => {
        let data = [];
        if (type === "venue") {
            data.push(record.venue?.split(",")[0]);
            if (record.venueStreet1) {
                data.push(record.venueStreet1);
            }
            if (record.venueStreet2) {
                data.push(record.venueStreet2);
            }
            if (record.locationCity) {
                data.push(record.locationCity);
            }
            if (record.locationState) {
                data.push(record.locationState);
            }
            if (record.venueCountry) {
                data.push(record.venueCountry);
            }
            if (record.venueZip) {
                data.push(record.venueZip);
            }
        } else {
            data.push(record.hotel?.split(",")[0]);
            if (record.hotelStreet1) {
                data.push(record.hotelStreet1);
            }
            if (record.hotelStreet2) {
                data.push(record.hotelStreet2);
            }
            if (record.hotelCity) {
                data.push(record.hotelCity);
            }
            if (record.hotelState) {
                data.push(record.hotelState);
            }
            if (record.hotelCountry) {
                data.push(record.hotelCountry);
            }
            if (record.hotelZip) {
                data.push(record.hotelZip);
            }
        }
        return data.join(", ");
    };

    const createMarkup = (text) => {
        return { __html: text };
    };

    const displayContactField = (enableBlur, required, type, label, fieldName, options, value, maxLength) => {
        return displayField(required, type, label, fieldName, options, value, maxLength, enableBlur);
    };

    const validateEmail = (value, fieldName, errorElementId) => {
        handleEmailChange(value, fieldName, errorElementId);
        if (value) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            timerRef.current = setTimeout(() => {
                let valid = emailRegex?.test(value);
                if (!valid) {
                    document.querySelector("#request-to-book-save")?.setAttribute("disabled", true);
                    document.querySelector("#request-to-book-save")?.classList.add("Mui-disabled");
                    setHasEmailError(true);
                    const errorElement = document.querySelector("#" + errorElementId);
                    if (errorElement) {
                        errorElement.style.display = "block";
                    }
                }
            }, 1000);
        }
    };

    const displayField = (required, type, label, fieldName, options, value, maxLength, enableBlur) => {
        if (!savingContactRef.current) {
            document.querySelector("#request-to-book-save")?.removeAttribute("disabled");
            document.querySelector("#request-to-book-save")?.classList.remove("Mui-disabled");
        }

        let field;
        let id = label.replaceAll(" ", "-").toLowerCase();
        if (calendarEntryRecord) {
            if (type === "text") {
                field = (
                    <TextField
                        fullWidth
                        required={required}
                        inputProps={maxLength ? { maxLength: maxLength } : { maxLength: 255 }}
                        defaultValue={value ? value : calendarEntryRecord[fieldName] || ""}
                        // value={value ? value : calendarEntryRecord[fieldName]}
                        value={value ? value : calendarEntryRecord[fieldName] || ""}
                        label={label}
                        InputLabelProps={{
                            shrink: calendarEntryRecord[fieldName] ? true : false,
                        }}
                        variant="outlined"
                        onChange={(event) => handleChange(event.target.value, fieldName)}
                        onBlur={enableBlur ? handleBlur : () => {}}
                    />
                );
            } else if (type === "email") {
                const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                let currentValue = calendarEntryRecord[fieldName];
                field = (
                    <>
                        <TextField
                            fullWidth
                            required={required}
                            inputProps={maxLength ? { maxLength: maxLength } : { maxLength: 255 }}
                            defaultValue={value ? value : calendarEntryRecord[fieldName] || ""}
                            // value={value ? value : calendarEntryRecord[fieldName]}
                            value={value ? value : calendarEntryRecord[fieldName] || ""}
                            label={label}
                            InputLabelProps={{
                                shrink: calendarEntryRecord[fieldName] ? true : false,
                            }}
                            variant="outlined"
                            onLoad={(event) => {
                                alert(value);
                                validateEmail(
                                    value ? value : calendarEntryRecord[fieldName] || "",
                                    fieldName,
                                    id + "-error"
                                );
                            }}
                            onChange={(event) => validateEmail(event.target.value, fieldName, id + "-error")}
                            onBlur={enableBlur ? handleBlur : () => {}}
                        />
                        {currentValue && currentValue.length > 3 && !emailRegex?.test(currentValue) && (
                            <Box className={"email-error"} id={id + "-error"} sx={{ fontSize: "13px", color: "red" }}>
                                Invalid Email
                            </Box>
                        )}
                    </>
                );
            } else if (type === "select") {
                field = (
                    <FormControl fullWidth>
                        <InputLabel
                            required={required}
                            shrink={calendarEntryRecord[fieldName] ? true : false}
                            id={`${id}-label`}>
                            {label}
                        </InputLabel>
                        <Select
                            required={required}
                            labelId={`${id}-label`}
                            id={`${id}-select`}
                            label={label}
                            placeholder="Select value"
                            value={value ? value : calendarEntryRecord[fieldName]}
                            onChange={(event) => handleChange(event.target.value, fieldName)}
                            onBlur={enableBlur ? handleBlur : () => {}}>
                            {options.map((item) => (
                                <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            } else if (type === "date") {
                field = (
                    <LocalizationProvider sx={{ width: "100%" }} fullWidth dateAdapter={AdapterDayjs}>
                        <DatePicker
                            required={required}
                            className="event-date"
                            fullWidth
                            labelId={`${id}-label`}
                            id={`${id}-date`}
                            style={{ width: "100%" }}
                            label={label + (required ? " *" : "")}
                            variant="outlined"
                            value={value ? value : calendarEntryRecord[fieldName]}
                            onChange={(newValue) => handleChange(newValue, fieldName)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                );
            } else if (type === "switch") {
                field = (
                    <FormControlLabel
                        required={required}
                        control={
                            <Switch
                                // defaultChecked={value ? value : calendarEntryRecord[fieldName]}
                                checked={value ? value : calendarEntryRecord[fieldName]}
                            />
                        }
                        label={label}
                        onChange={(event) => handleChange(event.target.checked, fieldName)}
                    />
                );
            } else if (type === "textarea") {
                field = (
                    <TextField
                        required={required}
                        fullWidth
                        multiline={true}
                        rows={3}
                        InputLabelProps={{
                            shrink: calendarEntryRecord[fieldName] ? true : false,
                        }}
                        value={value ? value : calendarEntryRecord[fieldName] || ""}
                        label={label}
                        variant="outlined"
                        onBlur={enableBlur ? handleBlur : () => {}}
                        onChange={(event) => handleChange(event.target.value, fieldName)}
                    />
                );
            } else if (type === "rich-textarea") {
                field = (
                    <>
                        <InputLabel>{label}</InputLabel>
                        <div
                            dangerouslySetInnerHTML={createMarkup(
                                value ? value : calendarEntryRecord[fieldName]
                            )}></div>
                    </>
                );
            }

            return field;
        }

        return "";
    };

    const openLocation = async (locationName) => {
        const url = "https://www.google.com/maps?q=" + locationName;
        window.open(url, "_blank");
    };

    const getSpeechTopicRecord = () => {
        if (speechTopics?.length > 0) {
            let options = speechTopics.map((item) => {
                return { value: item.speechTopic, label: item.customTopicName };
            });

            return [{ label: "Custom", value: "custom" }, ...options];
        }
        return [{ label: "Custom", value: "custom" }];
    };

    const validateFields = (fields, currentRecord) => {
        //document.querySelector("#request-to-book-save")?.removeAttribute("disabled");
        //document.querySelector("#request-to-book-save")?.classList.remove("Mui-disabled");
        const record = currentRecord || calendarEntryRecord;

        let valid = true;
        const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        for (let field of fields) {
            if (!record || !record[field]) {
                valid = false;
                break;
            }
            if (
                field === "preEventContactEmail" ||
                field === "contractIssuedToEmail" ||
                field === "onsiteContactEmail"
            ) {
                if (!emailRegex?.test(record[field])) {
                    valid = false;
                    break;
                }
            }
        }

        return valid;
    };

    const validateEventDetail = (currentRecord) => {
        // const record = currentRecord || calendarEntryRecord;

        let fields = ["eventName", "eventDate", "eventType", "mediaCoverage", "recordingType"];

        if (isVirtual()) {
            fields.push("platform");
            if (isOtherPlatform()) {
                fields.push("otherPlatform");
            }
        }
        return validateFields(fields, currentRecord);
    };

    const validateContractIssuedTo = (currentRecord, inputFieldsList) => {
        let fields = inputFieldsList
            ? inputFieldsList
            : [
                  "contractIssuedToName",
                  "contractIssuedToTelephone",
                  "contractIssuedToMobilePhone",
                  "contractIssuedToEmail",
                  "contractIssuedToStreet1",
                  "contractIssuedToCity",
                  "contractIssuedToState",
                  "contractIssuedToCountry",
                  "contractIssuedToZip",
              ];
        return validateFields(fields, currentRecord);
    };

    const validatePrevEventContract = (currentRecord) => {
        const record = currentRecord || calendarEntryRecord;

        let fields = [
            "preEventContactName",
            "preEventContactTelephone",
            "preEventContactMobilePhone",
            "preEventContactEmail",
        ];

        if (record.preEventContactSameAsContractIssue) {
            return true;
        } else {
            return validateFields(fields, currentRecord);
        }
    };

    const validateOnSiteContact = (currentRecord) => {
        const record = currentRecord || calendarEntryRecord;

        let fields = ["onsiteContactName", "onsiteContactTelephone", "onsiteContactMobilePhone", "onsiteContactEmail"];

        if (record.onsiteContactSameAs) {
            return true;
        } else {
            return validateFields(fields, currentRecord);
        }
    };

    const validateVenueDetails = (currentRecord) => {
        const record = currentRecord || calendarEntryRecord;

        if (record.eventType === "Virtual") {
            return true;
        }
        let fields = [
            "venueStreet1",
            "locationCity",
            "locationState",
            "locationCountry",
            "venueZip",
            "venuePhoneNumber",
        ];
        return validateFields(fields, currentRecord);
    };

    const validateHotelDetails = (currentRecord) => {
        const record = currentRecord || calendarEntryRecord;

        if (record.eventType === "Virtual") {
            return true;
        }

        let fields = ["hotelStreet1", "hotelCity", "hotelState", "hotelCountryGlobal", "hotelZIP", "hotelPhoneNumber"];

        if (record.sameAsVenue) {
            return true;
        }
        return validateFields(fields, currentRecord);
    };

    const validateSpeechTopic = (currentRecord) => {
        const record = currentRecord || calendarEntryRecord;

        if (record.speechTopic && record.speechTopic !== "custom") {
            return true;
        }
        if (record.speechTopic === "custom") {
            let fields = ["customSpeech"];
            return validateFields(fields, currentRecord);
        }
        return false;
    };

    const validateAdditionalDetails = (currentRecord) => {
        let fields = [
            "aboutTheEvent",
            "additionalSpeakers",
            "previousSpeakers",
            "audienceSize",
            "audienceProfile",
            "speakerAttire",
            "sponsors",
            "willTicketsBeSold",
        ];
        return validateFields(fields, currentRecord);
    };

    const validateShowAllDataAndSubmitButton = (currentRecord = null) => {
        if (
            validateEventDetail(currentRecord) &&
            validateContractIssuedTo(currentRecord) &&
            validatePrevEventContract(currentRecord) &&
            validateOnSiteContact(currentRecord) &&
            validateVenueDetails(currentRecord) &&
            validateHotelDetails(currentRecord) &&
            validateSpeechTopic(currentRecord) &&
            validateAdditionalDetails(currentRecord)
        ) {
            return true;
        }

        return false;
    };

    const showUIField = (UIField, fieldName) => {
        return (showAllData || !initialEntryRecord[fieldName]) && UIField;
    };

    const EventDetailSection = (
        <Grid fullWidth container spacing={2}>
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    <TextField
                        fullWidth
                        value={calendarEntryRecord?.accountName}
                        label="Client Name"
                        variant="outlined"
                        disabled={true}
                    />
                </Grid>,
                "accountName"
            )}

            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "text", "Event Name", "eventName")}
                </Grid>,
                "eventName"
            )}

            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "date", "Event Date", "eventDate")}
                </Grid>,
                "eventDate"
            )}

            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "select", "Event Type", "eventType", eventTypePicklist)}
                </Grid>,
                "eventDate"
            )}

            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "select", "Media", "mediaCoverage", mediaCoveragePicklist)}
                </Grid>,
                "mediaCoverage"
            )}

            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "select", "Recording", "recordingType", recordingTypePicklist)}
                </Grid>,
                "recordingType"
            )}

            {isVirtual() && (
                <>
                    {(showAllData ||
                        (!validateFields(
                            ["accountName", "eventName", "eventDate", "eventType", "mediaCoverage", "recordingType"],
                            initialEntryRecord
                        ) &&
                            !validateFields(["platform", "otherPlatform"], initialEntryRecord))) && (
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                    )}

                    <Grid item lg={4} md={4} xs={12}>
                        {displayField(true, "select", "Platform", "platform", platformPicklist)}
                    </Grid>

                    {isOtherPlatform() && (
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Other Platform", "otherPlatform")}
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );

    const ContractIssuedTo = (
        <>
            <Grid fullWidth container spacing={2}>
                <Grid item lg={12} md={12} xs={12}>
                    <b>Contract Contact Details</b>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" sx={{ mb: "30px" }}>
                        Please provide the contact details of the person the contract should be issued to. We may have
                        pre-filled this section for you with an existing contact's information, but please modify as
                        necessary.
                    </Typography>
                </Grid>

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "Name", "contractIssuedToName")}
                    </Grid>,
                    "contractIssuedToName"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "Office Phone", "contractIssuedToTelephone")}
                    </Grid>,
                    "contractIssuedToTelephone"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "Mobile Phone", "contractIssuedToMobilePhone")}
                    </Grid>,
                    "contractIssuedToMobilePhone"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "email", "Email", "contractIssuedToEmail")}
                    </Grid>,
                    "contractIssuedToEmail"
                )}

                {(showAllData ||
                    (!validateFields(
                        [
                            "contractIssuedToName",
                            "contractIssuedToTelephone",
                            "contractIssuedToMobilePhone",
                            "contractIssuedToEmail",
                        ],
                        initialEntryRecord
                    ) &&
                        !validateFields(
                            [
                                "contractIssuedToStreet1",
                                "contractIssuedToStreet2",
                                "contractIssuedToCity",
                                "contractIssuedToState",
                                "contractIssuedToCountry",
                                "contractIssuedToZip",
                            ],
                            initialEntryRecord
                        ))) && (
                    <>
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <b>Address</b>
                        </Grid>
                    </>
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "Street 1", "contractIssuedToStreet1")}
                    </Grid>,
                    "contractIssuedToStreet1"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, false, "text", "Street 2", "contractIssuedToStreet2")}
                    </Grid>,
                    "contractIssuedToStreet2"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "City", "contractIssuedToCity")}
                    </Grid>,
                    "contractIssuedToCity"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "State", "contractIssuedToState")}
                    </Grid>,
                    "contractIssuedToState"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(
                            true,
                            true,
                            "select",
                            "Country",
                            "contractIssuedToCountry",
                            countryPicklist
                        )}
                    </Grid>,
                    "contractIssuedToCountry"
                )}

                {showUIField(
                    <Grid item lg={4} md={4} xs={12}>
                        {displayContactField(true, true, "text", "Zip code", "contractIssuedToZip")}
                    </Grid>,
                    "contractIssuedToZip"
                )}
            </Grid>
        </>
    );

    const PreEventContact = (
        <>
            <Grid fullWidth container spacing={2}>
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(false, "switch", "Same as Contract Issued To", "preEventContactSameAsContractIssue")}
                </Grid>

                {!calendarEntryRecord.preEventContactSameAsContractIssue && (
                    <>
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <b>Pre-Event Contact Details</b>
                        </Grid>
                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "text", "Name", "preEventContactName")}
                            </Grid>,
                            "preEventContactName"
                        )}
                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "text", "Office Phone", "preEventContactTelephone")}
                            </Grid>,
                            "preEventContactTelephone"
                        )}
                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "text", "Mobile Phone", "preEventContactMobilePhone")}
                            </Grid>,
                            "preEventContactMobilePhone"
                        )}
                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "email", "Email", "preEventContactEmail")}
                            </Grid>,
                            "preEventContactEmail"
                        )}

                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );

    const OnSiteContactSection = (
        <>
            <Grid fullWidth container spacing={2}>
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(false, "switch", "Same as Contract Issued To", "onsiteContactSameAs")}
                </Grid>

                {!calendarEntryRecord.onsiteContactSameAs && (
                    <>
                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <b>On-Site Contact Details</b>
                        </Grid>

                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "text", "Name", "onsiteContactName")}
                            </Grid>,
                            "onsiteContactName"
                        )}

                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "text", "Office Phone", "onsiteContactTelephone")}
                            </Grid>,
                            "onsiteContactTelephone"
                        )}

                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "text", "Mobile Phone", "onsiteContactMobilePhone")}
                            </Grid>,
                            "onsiteContactMobilePhone"
                        )}

                        {showUIField(
                            <Grid item lg={4} md={4} xs={12}>
                                {displayContactField(true, true, "email", "Email", "onsiteContactEmail")}
                            </Grid>,
                            "onsiteContactEmail"
                        )}

                        <Grid item lg={12} md={12} xs={12}>
                            <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );

    const SpeechTopicSection = (
        <Grid fullWidth container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" sx={{ mb: "30px" }}>
                    Select a speech topic from the available speech topics below. Custom speech topics need to be
                    approved by the speaker and may not be available or may affect the final speaker fee.
                </Typography>
            </Grid>

            {showUIField(
                <Grid item lg={8} md={8} xs={12}>
                    {displayField(
                        true,
                        "select",
                        "Speech Topic",
                        "speechTopic",
                        speechTopics ? getSpeechTopicRecord() : []
                    )}
                </Grid>,
                "speechTopic"
            )}

            {calendarEntryRecord.speechTopic === "custom" &&
                showUIField(
                    <Grid item lg={12} md={12} xs={12}>
                        {displayField(true, "textarea", "Custom Speech Topic", "customSpeech")}
                    </Grid>,
                    "customSpeech"
                )}
        </Grid>
    );
    const AdditionalDetailsSection = (
        <Grid fullWidth container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" sx={{ mb: "30px" }}>
                    Please provide as much detail as possible about the event, including the most recent timetable.
                </Typography>
            </Grid>

            {(showAllData ||
                !initialEntryRecord["contractedTimeline"] ||
                initialEntryRecord["contractedTimeline"] === "<p></p>") && (
                <Grid item lg={12} md={12} xs={12}>
                    <RichTextArea
                        label={"Timetable"}
                        value={calendarEntryRecord.contractedTimeline}
                        onChange={(value) => (calendarEntryRecord.contractedTimeline = value)}
                    />
                </Grid>
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "textarea", "About the Event", "aboutTheEvent")}
                </Grid>,
                "aboutTheEvent"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "textarea", "Additional Speakers", "additionalSpeakers")}
                </Grid>,
                "additionalSpeakers"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "textarea", "Previous Speakers", "previousSpeakers")}
                </Grid>,
                "previousSpeakers"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "text", "Audience Size", "audienceSize")}
                </Grid>,
                "audienceSize"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "textarea", "Audience Profile", "audienceProfile")}
                </Grid>,
                "audienceProfile"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "text", "Speaker's Attire", "speakerAttire", "", "", 200)}
                </Grid>,
                "speakerAttire"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "textarea", "Sponsors", "sponsors")}
                </Grid>,
                "sponsors"
            )}

            {showUIField(
                <Grid item lg={12} md={12} xs={12}>
                    {displayField(true, "select", "Will tickets be sold?", "willTicketsBeSold", checkboxOption)}
                </Grid>,
                "willTicketsBeSold"
            )}
        </Grid>
    );

    const VenueDetailsSection = (
        <Grid fullWidth container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" sx={{ mb: "30px" }}>
                    Please use the address search to find the venue location address. Select from the list of suggested
                    addresses and then confirm the venue address is correct before submitting.
                </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
                <GoogleAutocomplete
                    label={"Venue Search"}
                    value={calendarEntryRecord["venue"]}
                    onChangeValue={(value) => handleVenueChange(value, "venue")}></GoogleAutocomplete>
            </Grid>
            {calendarEntryRecord.venue && (
                <Grid item lg={12} md={12} xs={12} sx={{ display: "flex" }}>
                    <LocationOnIcon sx={{ color: "#b8b8b8", mr: ".4rem" }}></LocationOnIcon>
                    <b>Venue:</b>{" "}
                    <Link
                        onClick={() => openLocation(calendarEntryRecord.venue)}
                        title="Open location in map"
                        sx={{ color: "#f56856", ml: ".7rem" }}
                        href="#">
                        {getLocationName(calendarEntryRecord, "venue")}
                    </Link>
                </Grid>
            )}

            {(showAllData ||
                !validateFields(
                    [
                        "venueStreet1",
                        "venueStreet2",
                        "locationCity",
                        "locationState",
                        "venueCountry",
                        "locationCountry",
                        "venueZip",
                        "venueFaxNumber",
                        "venuePhoneNumber",
                    ],
                    initialEntryRecord
                )) && (
                <>
                    <Grid item lg={12} md={12} xs={12}>
                        <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <b>Address</b>
                    </Grid>
                </>
            )}

            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "text", "Street 1", "venueStreet1")}
                </Grid>,
                "venueStreet1"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(false, "text", "Street 2", "venueStreet2")}
                </Grid>,
                "venueStreet2"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "text", "City", "locationCity")}
                </Grid>,
                "locationCity"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "text", "State", "locationState")}
                </Grid>,
                "locationState"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(false, "text", "Country", "venueCountry")}
                </Grid>,
                "venueCountry"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "select", "Country Global", "locationCountry", countryPicklist)}
                </Grid>,
                "locationCountry"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "text", "Zip code", "venueZip")}
                </Grid>,
                "venueZip"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(false, "text", "Fax Number", "venueFaxNumber")}
                </Grid>,
                "venueFaxNumber"
            )}
            {showUIField(
                <Grid item lg={4} md={4} xs={12}>
                    {displayField(true, "text", "Phone Number", "venuePhoneNumber")}
                </Grid>,
                "venuePhoneNumber"
            )}
        </Grid>
    );

    const HostelDetailsSection = (
        <Grid fullWidth container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" sx={{ mb: "30px" }}>
                    Please use the address search to find the hotel address. Select from the list of suggested addresses
                    and then confirm the hotel address is correct before submitting. If the hotel is the same as the
                    venue, check the option below.
                </Typography>
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
                {displayField(false, "switch", "Same as Venue", "sameAsVenue")}
            </Grid>

            {!calendarEntryRecord.sameAsVenue && (
                <>
                    <Grid item lg={12} md={12} xs={12}>
                        <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                        <GoogleAutocomplete
                            label={"Name"}
                            value={calendarEntryRecord["hotel"]}
                            onChangeValue={(value) => handleVenueChange(value, "hotel")}></GoogleAutocomplete>
                    </Grid>

                    {calendarEntryRecord.hotel && (
                        <Grid item lg={12} md={12} xs={12} sx={{ display: "flex" }}>
                            <LocationOnIcon sx={{ color: "#b8b8b8", mr: ".4rem" }}></LocationOnIcon>
                            <b>Hotel:</b>{" "}
                            <Link
                                onClick={() => openLocation(calendarEntryRecord.hotel)}
                                title="Open location in map"
                                sx={{ color: "#f56856", ml: ".7rem" }}
                                href="#">
                                {getLocationName(calendarEntryRecord, "hotel")}
                            </Link>
                        </Grid>
                    )}

                    {(showAllData ||
                        !validateFields(
                            [
                                "hotelStreet1",
                                "hotelStreet2",
                                "hotelCity",
                                "hotelState",
                                "hotelCountry",
                                "hotelCountryGlobal",
                                "hotelZIP",
                                "hotelFaxNumber",
                                "hotelPhoneNumber",
                            ],
                            initialEntryRecord
                        )) && (
                        <>
                            <Grid item lg={12} md={12} xs={12}>
                                <Divider sx={{ margin: "0.75rem 0" }}></Divider>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <b>Address</b>
                            </Grid>
                        </>
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Street 1", "hotelStreet1")}
                        </Grid>,
                        "hotelStreet1"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(false, "text", "Street 2", "hotelStreet2")}
                        </Grid>,
                        "hotelStreet2"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "City", "hotelCity")}
                        </Grid>,
                        "hotelCity"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "State", "hotelState")}
                        </Grid>,
                        "hotelState"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(false, "text", "Country", "hotelCountry")}
                        </Grid>,
                        "hotelCountry"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "select", "Country Global", "hotelCountryGlobal", countryPicklist)}
                        </Grid>,
                        "hotelCountryGlobal"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Zip code", "hotelZIP")}
                        </Grid>,
                        "hotelZIP"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(false, "text", "Fax Number", "hotelFaxNumber")}
                        </Grid>,
                        "hotelFaxNumber"
                    )}

                    {showUIField(
                        <Grid item lg={4} md={4} xs={12}>
                            {displayField(true, "text", "Phone Number", "hotelPhoneNumber")}
                        </Grid>,
                        "hotelPhoneNumber"
                    )}
                </>
            )}
        </Grid>
    );

    const SelectedFeesSection = (
        <>
            <Grid xs={12} md={3} sx={{ m: "15px 5px", mt: "15px" }}>
                <Box>
                    <h4 style={{ marginBottom: "15px" }}>Selected Fees</h4>
                </Box>
                {calendarEntryRecord?.selectedFeeId &&
                confirmBookingRecord?.speakerDetails?.filter(
                    (item) => item.type === "Fee" && item.id === calendarEntryRecord.selectedFeeId
                )?.length > 0
                    ? confirmBookingRecord.speakerDetails
                          .filter((item) => item.type === "Fee" && item.id === calendarEntryRecord.selectedFeeId)
                          .map((item) => (
                              <>
                                  <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      className="booking-fee-container">
                                      <Grid item lg={12}>
                                          <Grid container wrap="nowrap" spacing={0} justifyContent="space-between">
                                              <Grid xs={8} item>
                                                  <b>{item.feeName}</b>
                                              </Grid>
                                              <Grid item>
                                                  {new Intl.NumberFormat("en-US", {
                                                      style: "currency",
                                                      currency: "USD",
                                                      maximumFractionDigits: 0,
                                                  }).format(item.feeAmount ? item.feeAmount : 0)}
                                              </Grid>
                                          </Grid>
                                          <Box>
                                              {item.clientExtent && (
                                                  <>
                                                      Client to provide{" "}
                                                      {item.clientExtent.toLowerCase() === "custom"
                                                          ? item.customClientExtent
                                                          : item.clientExtent}
                                                  </>
                                              )}
                                          </Box>
                                      </Grid>
                                  </Grid>
                              </>
                          ))
                    : "No Fee Available"}
            </Grid>
            <Box sx={{ mt: "10px" }}>
                <TextField
                    onChange={(event) => setBookingConfirmNote(event.target.value)}
                    fullWidth
                    value={bookingConfirmNote}
                    label="Please share any additional details about this booking request not provided in the form above."
                    variant="outlined"
                    multiline
                    rows={3}
                    maxRows={5}
                    InputLabelProps={{
                        sx: {
                            whiteSpace: "normal",

                            "&:focus,&:focus:visible": {
                                whiteSpace: "nowrap",
                            },

                            "&.MuiInputLabel-root.Mui-focused": {
                                whiteSpace: "nowrap",
                            },
                        },
                    }}
                />
            </Box>
            <Grid item lg={12} md={12} xs={12}>
                <Typography variant="body1" sx={{ m: "30px 0 30px 0" }}>
                    Once you've provided all the details on the form you can submit it and we'll begin preparing the
                    speaker offer! If you're not ready to submit, feel free to click save and return at your earliest
                    convenience.
                </Typography>
            </Grid>
        </>
    );

    const ShowAllDataBtnUI = (
        <FormControlLabel
            // required={false}
            control={<Switch checked={showAllData} />}
            label={"Show All Details"}
            onChange={() =>
                !validateShowAllDataAndSubmitButton(initialEntryRecord) && setShowAllData((prevState) => !prevState)
            }
            labelPlacement="start"
            sx={{
                marginBottom: {
                    sm: "unset",
                    xs: "1rem",
                },
                marginLeft: {
                    sm: "16px",
                    xs: "0px",
                },
                "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
                    fontWeight: 500,
                    color: "#542358",
                },
            }}
        />
    );

    const showDataList = [
        {
            Section: EventDetailSection,
            label: "Event Detail",
            isCompleted: validateEventDetail(),
            shouldNotShow: !showAllData && validateEventDetail(initialEntryRecord),
        },
        {
            Section: ContractIssuedTo,
            label: "Contract Issued To",
            isCompleted: validateContractIssuedTo(),
            shouldNotShow: !showAllData && validateContractIssuedTo(initialEntryRecord),
        },
        {
            Section: PreEventContact,
            label: "Pre-Event Contact",
            isCompleted: validatePrevEventContract(),

            shouldNotShow: !showAllData && validatePrevEventContract(initialEntryRecord),
        },
        {
            Section: OnSiteContactSection,
            label: "On-site Contact",
            isCompleted: validateOnSiteContact(),

            shouldNotShow: !showAllData && validateOnSiteContact(initialEntryRecord),
        },
        !isVirtual() && {
            Section: VenueDetailsSection,
            label: "Venue Details",
            isCompleted: validateVenueDetails(),

            shouldNotShow: !showAllData && validateVenueDetails(initialEntryRecord),
        },
        !isVirtual() && {
            Section: HostelDetailsSection,
            label: "Hotel Details",
            isCompleted: validateHotelDetails(),

            shouldNotShow: !showAllData && validateHotelDetails(initialEntryRecord),
        },
        {
            Section: SpeechTopicSection,
            label: "Speech Topic",
            isCompleted: validateSpeechTopic(),

            shouldNotShow: !showAllData && validateSpeechTopic(initialEntryRecord),
        },
        {
            Section: AdditionalDetailsSection,
            label: "Additional Details",
            isCompleted: validateAdditionalDetails(),

            shouldNotShow: !showAllData && validateAdditionalDetails(initialEntryRecord),
        },
        {
            Section: SelectedFeesSection,
            label: "Selected Fees",
            noTag: true,
            isCompleted: true,
            shouldNotShow: !showAllData,
        },
    ];

    return (
        <form>
            {validateAllEmail()}
            <Box>
                {calendarEntryRecord && <div>{setBookingCompleted()}</div>}
                {initialEntryRecord && <div>{setShowAllDataVisible()}</div>}
                <Box
                    sx={{
                        display: {
                            sm: "flex",
                            xs: "block",
                        },
                        flexDirection: { sm: "row" },
                        justifyContent: { sm: "space-between" },
                        alignItems: { sm: "center" },
                        marginBottom: { sm: "0.2rem" },
                    }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontSize: {
                                sm: "2rem",
                                xs: "1.5rem",
                            },
                        }}>
                        {heading}
                    </Typography>
                    {ShowAllDataBtnUI}
                </Box>
                <Typography variant="body1" gutterBottom>
                    In order to move forward with booking {calendarEntryRecord?.speakerName}, we will need to prepare an
                    agreement. To be sure we have the most accurate details to prepare the offer, please fill out our
                    pre-booking questionnaire below.
                </Typography>
                <Box
                    sx={{
                        background: "#4524530A",
                        border: "1px solid #4524533D",
                        margin: "1rem 0 1.5rem 0",
                        padding: "0.5rem",
                        borderRadius: "0.5rem",
                        display: "flex",
                    }}>
                    <InfoIcon sx={{ fontSize: "1rem", marginTop: "0.3rem" }} />
                    <Typography variant="body1" sx={{ color: "#232747CC", marginLeft: "0.5rem" }}>
                        You may save and return to this form anytime you'd like. Each section will turn green as you
                        complete them and you'll be able to submit once all information is provided. If you have any
                        questions, feel free to reach out!
                    </Typography>
                </Box>
                <RequestFormInputs
                    showDataList={showDataList}
                    listItemLabel={listItemLabel}
                    setListItemLabel={setListItemLabel}
                    showAllData={showAllData}
                />
                <ResponsiveRequestForm showDataList={showDataList} />
            </Box>
        </form>
    );
});
