import React, { useEffect, useState } from "react";
import { RequestToBook } from "../Proposal/requestToBook";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getProposedSpeakerAsync,
    getRequestDataAsync,
    reset,
    saveRequestDataAsync,
    selectRequestToBookData,
    selectRequestToBookError,
    selectRequestToBookStatus,
    submitRequestDataAsync,
} from "./requestToBookSlice";
import { Alert, Backdrop, Box, CircularProgress } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";

function RequestToBookPage() {
    // redux useSelector
    const initialData = useSelector(selectRequestToBookData);
    const status = useSelector(selectRequestToBookStatus);
    const error = useSelector(selectRequestToBookError);

    const { getUserDetailsNotAsync } = useAuth();

    const [calendarEntryRecord, setCalendarEntryRecord] = useState(null);
    const [bookingConfirmNote, setBookingConfirmNote] = useState("");
    const [confirmBookingLoadingButton, setConfirmBookingLoadingButton] = useState(false);
    const [saveBookingLoadingButton, setSaveBookingLoadingButton] = useState(false);

    //  Ex: /proposal/request-to-book/:opportunityId/:recordId/:calendarEntryId
    let { opportunityId, recordId, calendarEntryId } = useParams();

    const initialCalendarDataEntry = useSelector((state) => state.requestToBook.data[opportunityId]?.calendarDataEntry);

    const dispatch = useDispatch();

    const userDetails = getUserDetailsNotAsync();

    useEffect(() => {
        setCalendarEntryRecord(null);
        dispatch(reset());
        dispatch(
            getRequestDataAsync({
                calendarEntryId,
                opportunityId,
            })
        );
        dispatch(
            getProposedSpeakerAsync({
                opportunityId,
                application: userDetails?.userType,
                contactId: userDetails?.contact?.id,
            })
        );
    }, [opportunityId, recordId, calendarEntryId]);

    useEffect(() => {
        setCalendarEntryRecord(initialCalendarDataEntry ? { ...initialCalendarDataEntry } : null);
        setBookingConfirmNote(initialCalendarDataEntry?.clientNote || "");
    }, [initialCalendarDataEntry]);

    const handleSaveBooking = async () => {
        setSaveBookingLoadingButton(true);
        let calenderEntryRecord = { ...calendarEntryRecord };
        calenderEntryRecord.clientNote = bookingConfirmNote;

        dispatch(
            saveRequestDataAsync({
                // recordId,
                data: calenderEntryRecord,
                opportunityId,
                callback: (result) => {
                    if (result.isSuccess) {
                        setBookingConfirmNote("");

                        const detailEvent = new CustomEvent("openToster", {
                            detail: {
                                variant: "warning",
                                message:
                                    "Your pre-booking questionnaire has been saved but not submitted. You can submit once you've provided all the required information",
                            },
                        });
                        document.dispatchEvent(detailEvent);
                    } else {
                        const detailEvent = new CustomEvent("openToster", {
                            detail: { variant: "error", message: result.errorMessage },
                        });
                        document.dispatchEvent(detailEvent);
                    }
                    setSaveBookingLoadingButton(false);
                },
            })
        );
    };

    // it can be use in future
    const handleConfirmBooking = () => {
        setConfirmBookingLoadingButton(true);
        dispatch(
            submitRequestDataAsync({
                recordId,
                data: { ...confirmBookingRecord, status: "Request To Book" },
                callback: (result) => {
                    console.log("request to book sent");
                },
            })
        );

        let calenderEntryRecord = { ...calendarEntryRecord };
        calenderEntryRecord.clientNote = bookingConfirmNote;
        calenderEntryRecord.type = "REQUEST TO BOOK";
        calenderEntryRecord.name = (calenderEntryRecord?.speakerName + " - HOLD - REQUEST TO BOOK").substring(0, 79);
        setCalendarEntryRecord(calenderEntryRecord);
        dispatch(
            saveRequestDataAsync({
                opportunityId,
                data: calenderEntryRecord,
                callback: (result) => {
                    if (result.isSuccess) {
                        setBookingConfirmNote("");
                    } else {
                        const detailEvent = new CustomEvent("openToster", {
                            detail: { variant: "error", message: result.errorMessage },
                        });
                        document.dispatchEvent(detailEvent);
                    }
                    setConfirmBookingLoadingButton(false);
                },
            })
        );
    };

    const confirmBookingRecord = initialData?.[opportunityId]?.records?.filter((cItem) => {
        return cItem.id === recordId;
    })?.[0];

    // UI Part

    if (status === "failed" || error) {
        return <div>{error}</div>;
    }

    return (
        <>
            {calendarEntryRecord && Object.keys(calendarEntryRecord).length > 0 && (
                <>
                    {calendarEntryRecord.type === "REQUEST TO BOOK APPROVED" && (
                        <Alert
                            severity="warning"
                            sx={{
                                color: "white",
                                backgroundColor: "rgb(239 107 25)",
                                mb: "10px",
                            }}
                            icon={<InfoIcon fontSize="inherit" sx={{ color: "white" }} />}>
                            Your Booking Request has been approved.
                        </Alert>
                    )}
                    <RequestToBook
                        currentPage={"RequestToBookPage"}
                        pageHeading="Pre-booking Questionnaire"
                        calendarEntryRecord={calendarEntryRecord}
                        setCalendarEntryRecord={setCalendarEntryRecord}
                        confirmBookingRecord={confirmBookingRecord}
                        setBookingConfirmNote={setBookingConfirmNote}
                        bookingConfirmNote={bookingConfirmNote}
                        initialCalendarDataEntry={initialCalendarDataEntry}></RequestToBook>

                    <Box
                        sx={{
                            borderTop: "1px solid #E6E8F0",
                            paddingTop: "0.5rem",
                            marginTop: "1rem",

                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}>
                        <LoadingButton
                            disabled={calendarEntryRecord.type === "REQUEST TO BOOK APPROVED"}
                            loading={saveBookingLoadingButton}
                            id="request-to-book-save"
                            sx={{ width: { md: "auto", xs: "100%", mt: "10px" } }}
                            onClick={handleSaveBooking}>
                            Save
                        </LoadingButton>
                        <LoadingButton
                            disabled={calendarEntryRecord.type === "REQUEST TO BOOK APPROVED"}
                            color="primary"
                            variant="contained"
                            id="request-to-book-submit"
                            sx={{ width: { lg: "auto", xs: "100%" } }}
                            loading={confirmBookingLoadingButton}
                            onClick={handleConfirmBooking}>
                            Submit
                        </LoadingButton>

                        {/* 
            // it can be use in future
            <LoadingButton
              id="request-to-book-submit-loading-btn"
              sx={{ width: { md: "auto", xs: "100%" } }}
              // loading={false}
              // onClick={handleConfirmBooking}
              disabled
            >
              Submit
            </LoadingButton> */}
                    </Box>
                </>
            )}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={status === "loading"}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default RequestToBookPage;
