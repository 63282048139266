import * as React from "react";
import { Autocomplete, Box, Button, Chip, Grid, ListItemText, TextField, Typography } from "@mui/material";
import "./style.css";
import { ShareMenu } from "../ShareMenu";
import { LoadingButton } from "@mui/lab";

const ShareAutocomplete = (props) => {
    const [accessValue, setAccessValue] = React.useState("can view");

    const [selectedValues, setSelectedValues] = React.useState([]);
    const [hasError, setHasError] = React.useState(false);
    const [loadingAdd, setLoadingAdd] = React.useState(false);
    const handleAccessChange = (value) => {
        setAccessValue(value);
    };
    const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const filterOptions = (options, state) => {
        let newOptions = [];
        options.forEach((element) => {
            if (
                element.title.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                element.email?.toLowerCase().includes(state.inputValue.toLowerCase())
            ) {
                newOptions.push(element);
            }
        });
        return newOptions;
    };

    const handleSelect = (event, value) => {
        let values = value.map((item) => {
            let record = {};
            if (typeof item === "string") {
                record = { title: item, email: item };
            } else {
                record = item;
            }
            return record;
        });
        setHasError(false);
        setSelectedValues(values);
    };

    const handleAddClick = () => {
        let invalid = selectedValues.find((selectedItem) => {
            if (!selectedItem.id) {
                if (!emailRegex.test(selectedItem.title)) {
                    return true;
                }
                let filteredValue = selectedValues.filter((item) => item.title === selectedItem.title);
                if (filteredValue.length > 1) {
                    return true;
                }
                let find = props.selectedEmails.find((item) => item === selectedItem.title);
                if (find) {
                    return true;
                }
            }
            return false;
        });
        if (invalid) {
            setHasError(true);
        } else {
            setLoadingAdd(true);
            props.onAddClick([...selectedValues], accessValue, (isSuccess) => {
                if (isSuccess) {
                    setSelectedValues([]);
                }
                setLoadingAdd(false);
            });
        }
    };
    const handleBlur = (event) => {
        if (event.target.value) {
            const enterEvent = new KeyboardEvent("keydown", {
                key: "Enter",
                code: "Enter",
                keyCode: 13,
                which: 13,
                bubbles: true,
            });

            // Dispatch the event on the input element
            document.getElementById("tags-filled")?.dispatchEvent(enterEvent);
        }
    };
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    minHeight: "40px",
                    flexDirection: { xs: "column", md: "row" },
                }}>
                <Box
                    className="ShareAutocomplete"
                    sx={{ border: "1px solid #d4d4d4", borderRadius: "8px", width: "100%", pl: "4px", mr: "8px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            minHeight: "40px",
                            width: "100%",
                        }}>
                        <Autocomplete
                            multiple
                            filterOptions={filterOptions}
                            sx={{
                                width: {
                                    xs: accessValue === "can view" ? "calc(100% - 80px)" : "calc(100% - 120px)",
                                    md: "100%",
                                },
                            }}
                            value={selectedValues}
                            disableClearable={true}
                            onChange={(event, value) => handleSelect(event, value)}
                            id="tags-filled"
                            getOptionDisabled={(option) => option.disabled}
                            options={props.options?.map((option) => option)}
                            freeSolo
                            renderOption={(props, item) => (
                                <li {...props} key={item.title}>
                                    <ListItemText>{item.title}</ListItemText>
                                </li>
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    let isValid = true;
                                    let errorMessage = "";
                                    if (!option.id) {
                                        isValid = emailRegex.test(option.title) ? true : false;
                                        errorMessage = !isValid ? "Not a valid Email" : "";
                                        if (isValid) {
                                            let find = selectedValues.filter((item) => item.title === option.title);
                                            isValid = find?.length > 1 ? false : true;
                                            errorMessage = !isValid ? "Duplicate Email" : "";
                                        }

                                        if (isValid) {
                                            let find = props.selectedEmails.find((item) => item === option.title);
                                            isValid = find ? false : true;
                                            errorMessage = !isValid ? "Email already exist" : "";
                                        }
                                    }

                                    if (isValid) {
                                        return <Chip variant="outlined" label={option.title} key={key} {...tagProps} />;
                                    } else {
                                        return (
                                            <Chip
                                                variant="outlined"
                                                color="error"
                                                title={errorMessage}
                                                label={option.title}
                                                key={key.title}
                                                {...tagProps}
                                            />
                                        );
                                    }
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    id="autocomplete"
                                    {...params}
                                    onBlur={handleBlur}
                                    sx={{
                                        fontSize: "12px",
                                        "& input::placeholder": {
                                            fontSize: "0.8rem",
                                        },
                                    }}
                                    variant="standard"
                                    placeholder="Enter emails or select contact"
                                />
                            )}
                        />
                        <Box sx={{ minWidth: accessValue === "can view" ? "80px" : "120px", mt: "6px" }}>
                            <ShareMenu
                                id="autocomplete-input"
                                defaultValue="can view"
                                onChange={handleAccessChange}></ShareMenu>
                        </Box>
                    </Box>
                </Box>
                <LoadingButton
                    loading={loadingAdd}
                    variant="contained"
                    sx={{ height: "41px", background: "#4F46E5", mt: { xs: "10px", md: "0px" } }}
                    onClick={handleAddClick}>
                    Add
                </LoadingButton>
            </Box>
            {hasError && (
                <Typography color="error" sx={{ fontSize: "12px" }}>
                    Invalid entry, please verify before adding
                </Typography>
            )}
        </>
    );
};

export { ShareAutocomplete };
