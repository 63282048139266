import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { format } from "date-fns";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Checkbox,
    IconButton,
    Divider,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Switch,
    Link,
    Tooltip,
    Card,
    CardContent,
    Skeleton,
} from "@mui/material";
import { config, styles } from "../../style";

import { get, post } from "../../api";
import PollIcon from "@mui/icons-material/Poll";
import { ReactComponent as CastVoteIcon } from "../../assets/images/icons/cast-vote.svg";
import { ReactComponent as AboutUserIcon } from "../../assets/images/icons/about-icon.svg";
import { ReactComponent as UserVotedIcon } from "../../assets/images/icons/user-voted.svg";
import { LoadingButton } from "@mui/lab";
import { Modal } from "../../components/Modal";
import thankyouImage from "../../assets/images/thankyou.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const Poll = forwardRef((props, ref) => {
    const {
        opportunity,
        speakers,
        userDetails,
        displayFeeDetails,
        handleAboutClick,
        onFinish,
        setDisplayPollResultModal,
    } = props;
    const [userPollResults, setUserPollResults] = useState([]);
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayRemoveVoteMessage, setDisplayRemoveVoteMessage] = useState(false);

    const [speakerCategory, setSpeakerCategory] = useState({});
    const [actionLoading, setActionLoading] = useState(true);
    useEffect(() => {
        get("proposal/getUserPollDetails", { contactId: userDetails?.contact?.id, opportunityId: opportunity.id }).then(
            (result) => {
                if (result.isSuccess) {
                    setUserPollResults(result.data);
                }
                setActionLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        let mapRecords = {};
        if (speakers?.length > 0) {
            for (let record of speakers) {
                if (
                    record.status !== "Removed" &&
                    record?.speakerDetails?.filter((item) => item.type === "Fee").length > 0
                ) {
                    if (!mapRecords[record.proposalGroupId]) {
                        mapRecords[record.proposalGroupId] = {
                            id: record.proposalGroupId,
                            name: record.proposalGroupName === "Uncategorized" ? "" : record.proposalGroupName,
                            description: record.proposalGroupDescription,
                            speakers: [],
                        };
                    }

                    mapRecords[record.proposalGroupId].speakers.push(record);
                }
            }

            setSpeakerCategory(mapRecords);
        }
    }, [speakers]);

    const getAgentNotes = (record) => {
        if (record?.speakerDetails?.filter((item) => item.type === "Agent Notes")?.length > 0) {
            return record?.speakerDetails?.filter((item) => item.type === "Agent Notes")[0].agentNotes;
        }
        return "";
    };

    const isSpeakerSelected = (record) => {
        let findData = userPollResults.find((item) => item.speaker === record.speakerId);
        return findData ? true : false;
    };

    const selectedPollRecord = (record) => {
        let findData = userPollResults.find((item) => item.speaker === record.speakerId);
        return findData;
    };

    const handleVoteCast = (record) => {
        if (userPollResults.length < opportunity.numberOfVotesPerParticipant) {
            //setActionLoading(true);
            let data = [...userPollResults, { speaker: record.speakerId }];
            setUserPollResults(data);
            if (data.length === opportunity.numberOfVotesPerParticipant) {
                setDisplaySuccessMessage(true);
            }
            post("proposal/savePollResult", {
                contactId: userDetails?.contact?.id,
                opportunityId: opportunity.id,
                speakerId: record.speakerId,
            }).then((result) => {
                if (result.isSuccess) {
                    // let data = [...userPollResults, result.data];
                    // setUserPollResults(data);
                    // if (data.length === opportunity.numberOfVotesPerParticipant) {
                    //     setDisplaySuccessMessage(true);
                    // }
                }
                //setActionLoading(false);
            });
        } else {
            setDisplayRemoveVoteMessage(true);
        }
    };

    const removeVoting = (record) => {
        let pollRecord = selectedPollRecord(record);
        setUserPollResults([...userPollResults.filter((item) => item.speaker !== record.speakerId)]);
        if (pollRecord) {
            post("proposal/removePollResult", {
                contactId: userDetails?.contact?.id,
                opportunityId: opportunity.id,
                speakerId: record.speakerId,
            }).then((result) => {
                // if (result.isSuccess) {
                //     setUserPollResults([...userPollResults.filter((item) => item.id !== pollRecord?.id)]);
                // }
                // setActionLoading(false);
            });
        }
    };

    const displayCard = (records) => {
        return (
            <Box sx={{ pb: "30px", borderBottom: "1px solid lightgray" }}>
                {records.map((record) => (
                    <Card
                        sx={{
                            ...styles.card,
                            mt: "15px",
                            border: isSpeakerSelected(record) ? "1px solid #4F46E5" : "1px solid #d1d1d1",
                            background: isSpeakerSelected(record) ? "#F6F5FD" : "white",
                            "&:hover": { boxShadow: "aliceblue", border: "1px solid #4F46E5" },
                        }}
                        id={`card-${record.id}`}
                        key={`card-${record.id}`}>
                        <CardContent elevation={0}>
                            <Grid container spacing={3} sx={{ flexGrow: 1, m: "-15px" }}>
                                <Grid xs={12} md={6} mdOffset={0} sx={{ borderRight: config.border }}>
                                    <Grid
                                        sx={{ m: "-10px", flexWrap: { xs: "wrap", md: "nowrap" } }}
                                        container
                                        spacing={0}
                                        justifyContent="start">
                                        <Grid item sx={{ mr: "20px", marginTop: "6px" }} xs={12} md={3}>
                                            {record.speakerImageURL && (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        objectFit: "cover",
                                                        width: "150px",
                                                        height: "177px",
                                                        objectPosition: "top",
                                                        borderRadius: "4px",
                                                        mr: "20px",
                                                    }}
                                                    src={`${record.speakerImageURL}`}></Box>
                                            )}
                                            {!record.speakerImageURL && (
                                                <Skeleton
                                                    variant="rounded"
                                                    width={150}
                                                    height={177}
                                                    sx={{ mr: "20px" }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            sx={{ width: "100%", marginLeft: { xs: "0px", md: "40px" } }}
                                            item
                                            xs={12}
                                            md={9}>
                                            <Grid
                                                sx={{ m: "-10px" }}
                                                container
                                                wrap="nowrap"
                                                spacing={0}
                                                justifyContent="space-between">
                                                <Grid item>
                                                    <Typography sx={styles.cardHeading}>
                                                        {record.speakerName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item></Grid>
                                            </Grid>
                                            <Box sx={{ mb: "10px", mt: "-5px" }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "16px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: "2",
                                                        WebkitBoxOrient: "vertical",
                                                    }}>
                                                    {record.speakerPromotionalTitle}
                                                </Typography>
                                            </Box>

                                            <Box>
                                                {getAgentNotes(record) && (
                                                    <div className="notes note-sent">
                                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                            <h3>Agent notes</h3>
                                                        </Box>

                                                        <Grid
                                                            sx={{ m: "-10px" }}
                                                            container
                                                            wrap="nowrap"
                                                            spacing={0}
                                                            justifyContent="space-between">
                                                            <Grid item sx={{ height: "100%" }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        display: "-webkit-box",
                                                                        WebkitLineClamp: "3",
                                                                        WebkitBoxOrient: "vertical",
                                                                    }}>
                                                                    {getAgentNotes(record)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={3} sx={{ borderRight: config.border }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Fees</Typography>
                                    </Box>
                                    {displayFeeDetails(record)}
                                </Grid>
                                {
                                    <Grid xs={12} md={3} mdOffset={0}>
                                        {/* <IconButton
                                            size="small"
                                            onClick={(event) =>
                                                handleAboutClick(event, record.speakerId, record)
                                            }></IconButton> */}
                                        <Box>
                                            {!isSpeakerSelected(record) ? (
                                                <LoadingButton
                                                    sx={{
                                                        ...styles.outlinedErrorButton,
                                                        width: "100%",
                                                        mb: "10px",
                                                        color: "#059669",
                                                    }}
                                                    loading={actionLoading}
                                                    startIcon={<CastVoteIcon fontSize="small" />}
                                                    variant="outlined"
                                                    onClick={() => handleVoteCast(record)}
                                                    id={`cast-vote-${record.speakerId}`}>
                                                    {"Cast Vote"}
                                                </LoadingButton>
                                            ) : (
                                                <LoadingButton
                                                    sx={{
                                                        ...styles.outlinedErrorButton,
                                                        width: "100%",
                                                        mb: "10px",
                                                        color: "#232323",
                                                    }}
                                                    loading={actionLoading}
                                                    startIcon={<UserVotedIcon fontSize="small" />}
                                                    onClick={() => removeVoting(record)}
                                                    variant="outlined"
                                                    color="primary"
                                                    id={`voted-${record.speakerId}`}>
                                                    {"Voted +1"}
                                                </LoadingButton>
                                            )}
                                        </Box>
                                        <Box>
                                            <LoadingButton
                                                sx={{
                                                    ...styles.outlinedErrorButton,
                                                    width: "100%",
                                                    mb: "10px",
                                                    color: "#232323",
                                                }}
                                                loading={actionLoading}
                                                startIcon={<AboutUserIcon fontSize="small" />}
                                                variant="outlined"
                                                color="primary"
                                                onClick={(event) => handleAboutClick(event, record.speakerId, record)}
                                                id={`about-${record.speakerId}`}>
                                                {"About Speaker"}
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        );
    };

    const cardComponent = (records, group, showGroupName = false) => {
        if (records && records.length > 0) {
            return (
                <Box sx={{ mb: "50px" }}>
                    {showGroupName && group?.name && (
                        <Grid item lg={12} sx={{ mt: "15px" }}>
                            <Box sx={{ borderLeft: "5px solid #20335b", pl: "10px" }}>
                                <Box sx={{ fontSize: "14px", fontWeight: "500" }}>
                                    <h2>{group?.name}</h2>
                                </Box>
                                <Box sx={{ fontSize: "12px", color: "gray" }}>{group?.description}</Box>
                            </Box>
                        </Grid>
                    )}
                    {displayCard(records)}
                </Box>
            );
        }
        return "";
    };

    const handleClose = () => {
        onFinish();
        setDisplayPollResultModal(true);
    };

    return (
        <>
            <Box>
                <Typography>
                    Your team is currently running a speaker poll for your event. You may cast one vote per speaker and
                    up to{" "}
                    <b style={{ textDecoration: "underline", color: "#4F46E5" }}>
                        {opportunity.numberOfVotesPerParticipant} votes
                    </b>{" "}
                    total. Click on “About Speaker” to learn more about the proposed speaker. Click on “Cast Vote” to
                    indicate your preference for the speaker and click again to undo your vote.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                        mt: "1rem",
                    }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box sx={{ borderRadius: "8px", border: "1px solid gray", padding: "16px" }}>
                            <Box sx={{ color: "#20335B", fontSize: "32px", fontWeight: "600", lineHeight: "20px" }}>
                                {opportunity.numberOfVotesPerParticipant}
                            </Box>
                            <Box
                                sx={{
                                    mt: "10px",
                                    color: "gray",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "20px",
                                }}>
                                Total Available Votes
                            </Box>
                        </Box>
                        <Box sx={{ ml: "10px", borderRadius: "8px", border: "1px solid gray", padding: "16px" }}>
                            <Box sx={{ color: "#20335B", fontSize: "32px", fontWeight: "600", lineHeight: "20px" }}>
                                {userPollResults.length}
                            </Box>
                            <Box
                                sx={{
                                    mt: "10px",
                                    color: "gray",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "20px",
                                }}>
                                Your Votes Cast
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ mt: { xs: "10px", md: "0px" }, width: { xs: "100%", md: "auto" } }}>
                        <Button
                            fullWidth
                            onClick={() => setDisplayPollResultModal(true)}
                            startIcon={<PollIcon />}
                            variant="outlined"
                            color="primary">
                            View Poll Result
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Divider sx={{ my: ".8rem", mx: "-1rem" }}></Divider>
            {Object.keys(speakerCategory).map((group) => {
                return cardComponent(speakerCategory[group]?.speakers, speakerCategory[group], true);
            })}

            <Modal
                open={displaySuccessMessage}
                title="Speaker details"
                onClose={() => setDisplaySuccessMessage(false)}
                hideActions
                hideTitle
                maxWidth="sx"
                removeTabIndex
                sx={{ padding: "0px" }}
                contentSx={{ overflowY: "hidden" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "300px" }}>
                    {/* <img src={thankyouImage} alt="thank you" style={{ width: "70px" }}></img> */}
                    <CheckIcon sx={{ width: "50px", height: "50px", mb: "10px", color: "green" }}></CheckIcon>
                    <Typography variant="h5">Thank you !</Typography>

                    <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "400", fontSize: "14px" }}>
                        You have successfully completed the Poll.
                    </Typography>
                    <Button variant="contained" sx={{ mt: "1rem" }} onClick={handleClose} fullWidth color="primary">
                        View result
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={displayRemoveVoteMessage}
                title="Speaker details"
                onClose={() => setDisplayRemoveVoteMessage(false)}
                hideActions
                hideTitle
                maxWidth="sx"
                removeTabIndex
                sx={{ padding: "0px" }}
                contentSx={{ overflowY: "hidden" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "300px" }}>
                    {/* <img src={thankyouImage} alt="thank you" style={{ width: "70px" }}></img> */}
                    <CloseIcon sx={{ width: "50px", height: "50px", mb: "10px", color: "red" }}></CloseIcon>

                    <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "400", fontSize: "14px" }}>
                        You’ve cast all your votes. To change your votes you must remove a vote you’ve already cast.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ mt: "1rem" }}
                        onClick={() => setDisplayRemoveVoteMessage(false)}
                        fullWidth
                        color="primary">
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
});
