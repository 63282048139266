import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { format } from "date-fns";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Checkbox,
    IconButton,
    Divider,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Switch,
    Link,
    Tooltip,
    Card,
    CardContent,
    Skeleton,
} from "@mui/material";
import { config, styles } from "../../style";

import { get, post } from "../../api";
import PollIcon from "@mui/icons-material/Poll";
import { ReactComponent as CastVoteIcon } from "../../assets/images/icons/cast-vote.svg";
import { ReactComponent as AboutUserIcon } from "../../assets/images/icons/about-icon.svg";
import { ReactComponent as UserVotedIcon } from "../../assets/images/icons/user-voted.svg";
import { LoadingButton } from "@mui/lab";
import { Modal } from "../../components/Modal";
import thankyouImage from "../../assets/images/thankyou.png";
import { CustomAccordion } from "../../components/Accordion";

export const PollResult = forwardRef((props, ref) => {
    const { opportunity, speakers, userDetails, shareProposalDetails } = props;
    const [pollResults, setPollResults] = useState([]);
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [speakerCategory, setSpeakerCategory] = useState({});
    const [actionLoading, setActionLoading] = useState(true);

    const [loadCompleted, setLoadCompleted] = useState(true);
    const [dataBySpeakerId, setDataBySpeakerId] = useState({});
    const [participants, setParticipants] = useState([]);
    const [topRankedSpeakers, setTopRankedSpeakers] = useState([]);
    useEffect(() => {
        get("proposal/getAllPollResult", { opportunityId: opportunity.id }).then((result) => {
            if (result.isSuccess) {
                setPollResults(result.data);
                let mapBySpeakerId = {};
                let mapByContactId = {};
                for (let record of result.data) {
                    if (!mapBySpeakerId[record.speakerId]) {
                        mapBySpeakerId[record.speakerId] = [];
                    }
                    mapBySpeakerId[record.speakerId].push(record);

                    if (!mapByContactId[record.contactId]) {
                        mapByContactId[record.contactId] = [];
                    }
                    mapByContactId[record.contactId].push(record);
                }
                setDataBySpeakerId(mapBySpeakerId);
                let participantsRecords = [];
                for (let participant of shareProposalDetails?.participants) {
                    participantsRecords.push({
                        contactId: participant.contactId,
                        name: participant.name,
                        quantity: mapByContactId[participant.contactId]
                            ? mapByContactId[participant.contactId].length
                            : 0,
                    });
                }

                for (let participant of shareProposalDetails?.contactRoles) {
                    participantsRecords.push({
                        contactId: participant.contactId,
                        name: participant.firstName + " " + participant.lastName,
                        quantity: mapByContactId[participant.contactId]
                            ? mapByContactId[participant.contactId].length
                            : 0,
                    });
                }
                participantsRecords = [...participantsRecords].sort((a, b) => b.quantity - a.quantity);
                setParticipants(participantsRecords);
            }
        });
    }, []);

    useEffect(() => {
        let mapRecords = {};
        if (speakers?.length > 0) {
            for (let record of speakers) {
                if (
                    record.status !== "Removed" &&
                    record?.speakerDetails?.filter((item) => item.type === "Fee").length > 0
                ) {
                    if (!mapRecords[record.proposalGroupId]) {
                        mapRecords[record.proposalGroupId] = {
                            id: record.proposalGroupId,
                            name: record.proposalGroupName === "Uncategorized" ? "" : record.proposalGroupName,
                            description: record.proposalGroupDescription,
                            speakers: [],
                        };
                    }

                    mapRecords[record.proposalGroupId].speakers.push(record);
                }
            }
            setActionLoading(false);
            setSpeakerCategory(mapRecords);
        }
    }, [speakers]);

    useEffect(() => {
        let topRatedSpeakers = [];
        if (
            speakerCategory &&
            Object.keys(speakerCategory).length > 0 &&
            dataBySpeakerId &&
            Object.keys(dataBySpeakerId).length > 0
        ) {
            if (speakers?.length > 0) {
                for (let record of speakers) {
                    if (
                        record.status !== "Removed" &&
                        record?.speakerDetails?.filter((item) => item.type === "Fee").length > 0
                    ) {
                        if (dataBySpeakerId[record.speakerId]?.length > 0) {
                            topRatedSpeakers.push({
                                speaker: record,
                                quantity: dataBySpeakerId[record.speakerId].length,
                            });
                        } else {
                            topRatedSpeakers.push({
                                speaker: record,
                                quantity: 0,
                            });
                        }
                    }
                }
                if (topRatedSpeakers.length > 0) {
                    topRatedSpeakers.sort((a, b) => {
                        // First, sort by quantity (descending order)
                        if (a.quantity !== b.quantity) {
                            return b.quantity - a.quantity;
                        }
                        // If quantity is the same, sort by speaker name alphabetically (case-insensitive)
                        return a.speaker.speakerName.localeCompare(b.speaker.speakerName);
                    });
                    topRatedSpeakers = topRatedSpeakers.map((item) => item.speaker);

                    setTopRankedSpeakers(topRatedSpeakers);

                    // topRatedSpeakers.sort((a, b) => b.quantity - a.quantity);
                    // topRatedSpeakers = topRatedSpeakers.map((item) => item.speaker);
                    // let mapRecords = speakerCategory;
                    // let data = {};
                    // data["topRanked"] = {
                    //     id: "topRanked",
                    //     name: "Top Ranked",
                    //     speakers: topRatedSpeakers,
                    // };
                    // mapRecords = { ...data, ...mapRecords };
                    // setSpeakerCategory(mapRecords);
                }
            }
            setLoadCompleted(false);
        }
    }, [speakerCategory, dataBySpeakerId]);

    const getTotalParticipants = () => {
        if (shareProposalDetails) {
            let total = 0;
            if (shareProposalDetails.participants?.length > 0) {
                total += shareProposalDetails.participants?.length;
            }
            if (shareProposalDetails.contactRoles?.length > 0) {
                total += shareProposalDetails.contactRoles?.length;
            }

            return total;
        }
        return 0;
    };

    const getProgress = (record) => {
        if (dataBySpeakerId[record.speakerId]?.length > 0 && pollResults?.length > 0) {
            const percentage = (dataBySpeakerId[record.speakerId].length / pollResults.length) * 100;
            const sub = (percentage / 100) * 54;
            return `calc(${percentage}% + ${sub}px)`;
        }
        return "0px";
    };

    const getSummary = (record, index) => {
        return (
            <Box sx={{ position: "relative", width: "100%" }}>
                <Box
                    sx={{
                        position: "absolute",
                        width: getProgress(record),
                        background: "rgba(68, 76, 231, 0.12)",
                        left: "-15px",
                        top: "-5px",
                        height: "48px",
                        borderRadius: "8px",
                    }}></Box>
                <Box
                    sx={{
                        zIndex: "1",
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "2px" }}>
                            {record.speakerImageURL && (
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: "cover",
                                        width: "35px",
                                        height: "35px",
                                        objectPosition: "top",
                                        borderRadius: "50px",
                                        mr: "10px",
                                    }}
                                    src={`${record.speakerImageURL}`}></Box>
                            )}
                            {!record.speakerImageURL && (
                                <Skeleton
                                    variant="circle"
                                    width={35}
                                    height={35}
                                    sx={{ mr: "10px", borderRadius: "50px" }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                color: "#2A2377",
                                fontWeight: "600",
                                fontSize: "14px",
                                textDecoration: "none",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: { xs: "158px", md: "inherit" },
                                overflow: "hidden",
                            }}>
                            {record.speakerName}
                        </Box>
                    </Box>
                    <Box sx={{ fontSize: "14px", border: "1px solid gray", padding: "0 5px", borderRadius: "5px" }}>
                        {dataBySpeakerId[record.speakerId] ? dataBySpeakerId[record.speakerId].length : 0}
                    </Box>
                </Box>
            </Box>
        );
    };

    const getDetails = (record, index) => {
        if (dataBySpeakerId[record.speakerId]?.length > 0) {
            return (
                <Box sx={{ mt: "5px" }}>
                    {dataBySpeakerId[record.speakerId].map((item) => (
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    minHeight: "40px",
                                    alignItems: "center",
                                }}>
                                <Box
                                    sx={{
                                        background: "#FFBCEC",
                                        height: "30px",
                                        width: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "50px",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                    }}>
                                    {item.contactName[0].toUpperCase()}
                                </Box>
                                <Box sx={{ marginLeft: "10px", fontSize: "14px" }}>{item.contactName}</Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            );
        }
        return <Box sx={{ mt: "5px" }}>No Vote</Box>;
    };

    const displaySpeakerPoll = (records) => {
        return (
            <Box sx={{ pb: "10px" }}>
                {records.map((record, index) => (
                    <Box sx={{ mb: "10px" }}>
                        <CustomAccordion summary={getSummary(record, index)} details={getDetails(record, index)} />
                    </Box>
                ))}
            </Box>
        );
    };

    const cardComponent = (records, group, showGroupName = false) => {
        if (records && records.length > 0) {
            return (
                <Box sx={{ mb: "10px" }}>
                    {showGroupName && group?.name && (
                        <Grid item lg={12} sx={{ mt: "15px" }}>
                            <Box sx={{}}>
                                <Box sx={{ fontSize: "12px", fontWeight: "500" }}>
                                    <h2>{group?.name}</h2>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                    {displaySpeakerPoll(records)}
                </Box>
            );
        }
        return "";
    };

    return (
        <>
            {!loadCompleted && (
                <Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box sx={{ borderRadius: "8px", border: "1px solid gray", padding: "16px" }}>
                                    <Box
                                        sx={{
                                            color: "#20335B",
                                            fontSize: "32px",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                        }}>
                                        {getTotalParticipants()}
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: "10px",
                                            color: "gray",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                        }}>
                                        Total Poll Participants
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    sx={{ ml: "10px", borderRadius: "8px", border: "1px solid gray", padding: "16px" }}>
                                    <Box
                                        sx={{
                                            color: "#20335B",
                                            fontSize: "32px",
                                            fontWeight: "600",
                                            lineHeight: "20px",
                                        }}>
                                        {pollResults.length}
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: "10px",
                                            color: "gray",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                        }}>
                                        Total Votes Cast
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: "15px" }}>{displaySpeakerPoll(topRankedSpeakers)}</Box>
                    <Divider sx={{ mx: "-1rem", my: ".8rem" }}></Divider>

                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                            <Box sx={{ fontSize: "12px", fontWeight: "500" }}>
                                <h2>Poll Participants</h2>
                            </Box>
                            <Box sx={{ fontSize: "12px", fontWeight: "500" }}>
                                <h2>Votes Cast</h2>
                            </Box>
                        </Box>
                        {participants.map((item) => {
                            return (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "start",
                                            minHeight: "40px",
                                            alignItems: "center",
                                        }}>
                                        <Box
                                            sx={{
                                                background: "#FFBCEC",
                                                height: "30px",
                                                width: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "50px",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                            }}>
                                            {item.name[0].toUpperCase()}
                                        </Box>
                                        <Box sx={{ marginLeft: "10px", fontSize: "14px" }}>{item.name}</Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            fontSize: "14px",
                                            border: "1px solid gray",
                                            padding: "0 5px",
                                            borderRadius: "5px",
                                        }}>
                                        {item.quantity}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            )}
        </>
    );
});
