import { Box, Button, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const RequestFormInputs = ({ showDataList = [], listItemLabel = "", setListItemLabel = null }) => {
    showDataList = showDataList.filter((cItem) => {
        if (cItem) {
            return cItem.shouldNotShow ? false : cItem;
        } else {
            return false;
        }
    });

    useEffect(() => {
        setListItemLabel((prevListItemLabel) => {
            const prevLabel = showDataList.filter((cItem) => cItem?.label === prevListItemLabel)?.[0]?.label;
            return prevLabel || showDataList[0]?.label;
        });
    }, [showDataList]);

    if (!listItemLabel || !showDataList || showDataList.length <= 0) {
        return null;
    }

    return (
        <Box
            sx={{
                display: { sm: "flex", xs: "none" },
                flexDirection: "row",
                height: "100%",
                minHeight: "444px",
            }}>
            <Box
                sx={{
                    flexGrow: 1,
                    px: "20px",
                    padding: 0,
                    margin: 0,
                    listStyleType: "none",
                    maxWidth: "250px",
                    minWidth: "210px",
                    flex: 1,

                    "& li:first-child a": {
                        borderRadius: "10px 10px 0 0",
                    },

                    "& li:last-child a": {
                        borderRadius: "0 0  10px 10px",
                    },
                }}
                component={"ul"}>
                {showDataList.map((cItem) => {
                    if (!cItem || cItem.shouldNotShow) {
                        return null;
                    }
                    return (
                        <ListItem
                            key={cItem.label}
                            disableGutters
                            sx={{
                                display: "flex",
                                mb: 0,
                                py: 0,
                            }}>
                            <Button
                                onClick={() => setListItemLabel(cItem.label)}
                                component="a"
                                disableRipple
                                sx={{
                                    border: "1px solid rgb(0,0,0,0.08)",
                                    backgroundColor: cItem.label === listItemLabel && "#1E28450D",
                                    borderRadius: "0",
                                    color: cItem.label === listItemLabel ? "#232747" : "#232747",
                                    fontWeight:
                                        //   (
                                        cItem.label === listItemLabel && "fontWeightBold",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    textAlign: "left",
                                    textTransform: "none",
                                    width: "100%",
                                    padding: "1.2rem 1rem",
                                    "& .MuiButton-startIcon": {
                                        color: cItem.label === listItemLabel ? "secondary.main" : "neutral.400",
                                    },
                                    "&:hover": {
                                        backgroundColor: "#1E28450D",
                                    },
                                }}>
                                <Typography sx={{ display: "flex", alignItems: "center" }}>
                                    {cItem.label}
                                    {!cItem.noTag && !cItem.isCompleted && (
                                        <Box
                                            sx={{
                                                height: "5px",
                                                width: "5px",
                                                borderRadius: "50%",
                                                display: "inline-flex",
                                                backgroundColor: "#E40E0E",
                                                marginLeft: "5px",
                                            }}></Box>
                                    )}
                                </Typography>

                                {cItem.isCompleted && (
                                    <CheckCircleOutlineIcon sx={{ mr: "10px", ml: "-8px", color: "#189212" }} />
                                )}
                            </Button>
                        </ListItem>
                    );
                })}
            </Box>

            <Box
                sx={(theme) => ({
                    flex: 3,
                    marginLeft: "1.5rem",
                    paddingTop: "0.5rem",
                    [theme.breakpoints.up("md")]: {
                        marginLeft: "2rem",
                    },
                })}>
                {showDataList.filter((cItem) => cItem.label === listItemLabel)?.[0]?.Section || null}
            </Box>
        </Box>
    );
};

export default RequestFormInputs;
