import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import { post, get } from "../../api";

function GoogleAutocomplete({ label, value, onChangeValue, disabled }) {
    const [selectedOption, setSelectedOption] = useState({ description: value ? value : "" });
    const [options, setOptions] = useState([]);

    const handleInputChange = async (event, value) => {
        if (value === "") {
            setOptions([]);
            return;
        }

        try {
            post("googleMap/autocomplete", { input: value })
                .then((result) => {
                    console.log("location", result.data);
                    setOptions(result.data.predictions || []);
                })
                .catch((error) => console.log(error));
        } catch (error) {
            console.error("Error fetching autocomplete predictions:", error);
        }
    };

    const getAddressComponent = (placeDetails, type) => {
        if (!placeDetails || !placeDetails.address_components) return "";
        const addressComponent = placeDetails.address_components.find(
            (component) => component.types.indexOf(type) !== -1
        );
        return addressComponent ? addressComponent.long_name : "";
    };

    const getAddressComponentShortName = (placeDetails, type) => {
        if (!placeDetails || !placeDetails.address_components) return "";
        const addressComponent = placeDetails.address_components.find(
            (component) => component.types.indexOf(type) !== -1
        );
        return addressComponent ? addressComponent.short_name : "";
    };

    const handelChange = async (option) => {
        if (option) {
            setSelectedOption({ description: option?.description ? option?.description : "" });
            console.log(option);
            await get("googleMap/placeDetail", { placeId: option.place_id })
                .then((result) => {
                    if (result.isSuccess) {
                        const description =
                            option.structured_formatting?.main_text +
                            ", " +
                            option.structured_formatting?.secondary_text;
                        const streetNumber = getAddressComponent(result.data, "street_number");
                        const street = getAddressComponent(result.data, "route");
                        let street1 = streetNumber ? streetNumber + " " + street : street;
                        const street2 = getAddressComponent(result.data, "sublocality_level_1");
                        const city = getAddressComponent(result.data, "locality");
                        const state = getAddressComponent(result.data, "administrative_area_level_1");
                        const stateShort = getAddressComponentShortName(result.data, "administrative_area_level_1");

                        const country = getAddressComponent(result.data, "country");
                        const zipcode = getAddressComponent(result.data, "postal_code");
                        onChangeValue({
                            label: description,
                            street1,
                            street2,
                            city,
                            state,
                            stateShort,
                            country,
                            zipcode,
                        });
                    }
                })
                .catch((error) => console.log(error));
        }
    };

    return (
        <Autocomplete
            options={options}
            disabled={disabled}
            filterOptions={() => options}
            value={selectedOption}
            getOptionLabel={(option) => option?.description}
            onInputChange={handleInputChange}
            onChange={(event, option) => handelChange(option)}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth />}
        />
    );
}

export default GoogleAutocomplete;
