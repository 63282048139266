import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { format } from "date-fns";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Checkbox,
    IconButton,
    Divider,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Switch,
    Link,
} from "@mui/material";

import { get, post } from "../../api";
import { ShareAutocomplete } from "../../components/ShareAutocomplete";
import { ShareMenu } from "../../components/ShareMenu";
import { QuantityInput } from "../../components/QuantityInput";

export const ShareProposal = forwardRef((props, ref) => {
    const { shareProposalDetails, pollDetail, onChange, opportunityId, userDetails } = props;
    const [noOfVote, setNoOfVote] = useState(1);
    const [pollDetailLocal, setPollDetailLocal] = useState({ ...pollDetail });
    const [mapEmails, setMapEmails] = useState({});

    // useEffect(() => {
    //     setPollDetailLocal({ ...pollDetail });
    // }, [pollDetail]);

    useImperativeHandle(ref, () => ({
        getPollDetail() {
            return pollDetailLocal;
        },
    }));

    useEffect(() => {
        let mapRecord = {};
        if (shareProposalDetails.contactRoles) {
            for (let i = 0; i < shareProposalDetails.contactRoles.length; i++) {
                mapRecord[shareProposalDetails.contactRoles[i].email] = shareProposalDetails.contactRoles[i];
            }
        }
        if (shareProposalDetails.participants) {
            for (let i = 0; i < shareProposalDetails.participants.length; i++) {
                mapRecord[shareProposalDetails.participants[i].email] = shareProposalDetails.participants[i];
            }
        }
        setMapEmails(mapRecord);
    }, [shareProposalDetails]);

    const handleAddClick = (records, accessValue, callback) => {
        let participants = records.map((item) => {
            return {
                name: item.Name ? item.Name : item.id ? item.title : getNameFromEmail(item.title),
                accessLabel: accessValue === "can view" ? "View" : "Collaborate",
                contactId: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                opportunityId: opportunityId,
            };
        });

        saveParticipants(participants, true, callback);
    };

    function getNameFromEmail(email) {
        // Extract the part before the "@" symbol
        let localPart = email.split("@")[0];

        // Split the local part using "." or "_" as delimiters
        let nameParts = localPart.split(/[\._+]/);

        // Capitalize the first letter of each part
        for (let i = 0; i < nameParts.length; i++) {
            nameParts[i] = nameParts[i].charAt(0).toUpperCase() + nameParts[i].slice(1);
        }

        // Join the parts with a space
        return nameParts.join(" ");
    }

    const removeParticipants = (recordId) => {
        get("proposal/removeParticipants", { id: recordId }).then((result) => {});
    };

    const saveParticipants = (records, isNaw, callback) => {
        post("proposal/saveParticipant", records).then((result) => {
            if (result.isSuccess) {
                if (isNaw) {
                    let participants = [...result.data, ...shareProposalDetails.participants];
                    onChange({ ...shareProposalDetails, participants: participants });
                } else {
                    let participants = shareProposalDetails.participants.map((item) => {
                        if (item.id === records[0].id) {
                            return records[0];
                        }
                        return item;
                    });
                    onChange({ ...shareProposalDetails, participants: participants });
                }
            }
            if (callback) {
                callback(result.isSuccess);
            }
        });
    };

    const getAutocompleteOptions = () => {
        let records = [];
        let existingEmails = [];
        if (shareProposalDetails.contacts) {
            for (let i = 0; i < shareProposalDetails.contacts.length; i++) {
                if (existingEmails.indexOf(shareProposalDetails.contacts[i].email) === -1) {
                    records.push({
                        ...shareProposalDetails.contacts[i],
                        disabled: mapEmails[shareProposalDetails.contacts[i].email] ? true : false,
                        title: shareProposalDetails.contacts[i].firstName
                            ? `${shareProposalDetails.contacts[i].firstName} ${shareProposalDetails.contacts[i].lastName}`
                            : shareProposalDetails.contacts[i].email,
                    });
                    existingEmails.push(shareProposalDetails.contacts[i].email);
                }
            }
        }
        if (shareProposalDetails.contactRoles) {
            for (let i = 0; i < shareProposalDetails.contactRoles.length; i++) {
                if (existingEmails.indexOf(shareProposalDetails.contactRoles[i].email) === -1) {
                    records.push({
                        ...shareProposalDetails.contactRoles[i],
                        disabled: true,
                        title: shareProposalDetails.contactRoles[i].firstName
                            ? `${shareProposalDetails.contactRoles[i].firstName} ${shareProposalDetails.contactRoles[i].lastName}`
                            : shareProposalDetails.contactRoles[i].email,
                    });
                    existingEmails.push(shareProposalDetails.contactRoles[i].email);
                }
            }
        }
        if (shareProposalDetails.participants) {
            for (let i = 0; i < shareProposalDetails.participants.length; i++) {
                if (existingEmails.indexOf(shareProposalDetails.participants[i].email) === -1) {
                    records.push({
                        ...shareProposalDetails.participants[i],
                        disabled: true,
                        title: shareProposalDetails.participants[i].firstName
                            ? `${shareProposalDetails.participants[i].firstName} ${shareProposalDetails.participants[i].lastName}`
                            : shareProposalDetails.participants[i].name
                            ? shareProposalDetails.participants[i].name
                            : shareProposalDetails.participants[i].email,
                    });
                    existingEmails.push(shareProposalDetails.participants[i].email);
                }
            }
        }

        records = records.sort((a, b) => a.title < b.title);
        return records;
    };

    const handleParticipantChange = (record, newValue) => {
        if (newValue === "remove access") {
            removeParticipants(record.id);
            let participants = shareProposalDetails.participants.filter((item) => item.id !== record.id);
            onChange({ ...shareProposalDetails, participants: participants });
        } else {
            saveParticipants([{ ...record, accessLabel: newValue === "can view" ? "View" : "Collaborate" }], false);
        }
    };

    function generateRandomString(length) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const displaySharedContact = (record, name, accessLabel, disabled) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    minHeight: "40px",
                    alignItems: "center",
                    mb: ".2rem",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        minHeight: "40px",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>
                    <Box
                        sx={{
                            background: "#FFBCEC",
                            height: "30px",
                            minWidth: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50px",
                            fontWeight: "500",
                            fontSize: "14px",
                        }}>
                        {name[0].toUpperCase()}
                    </Box>
                    <Box sx={{ marginLeft: "10px", fontSize: "14px", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {name}
                    </Box>
                </Box>
                <Box>
                    <ShareMenu
                        id={record.id + generateRandomString(10)}
                        defaultValue={
                            accessLabel === "View" ? "can view" : accessLabel === "Owner" ? "Owner" : "can collaborate"
                        }
                        disabled={disabled}
                        onChange={(value) => handleParticipantChange(record, value)}
                        displayDelete={
                            userDetails.participant?.accessLevel === "ContactRole" ||
                            !userDetails.participant?.accessLevel
                                ? true
                                : false
                        }></ShareMenu>
                </Box>
            </Box>
        );
    };

    const getSharedContacts = () => {
        let records = [];
        if (shareProposalDetails.participants) {
            for (let i = 0; i < shareProposalDetails.participants.length; i++) {
                records.push(
                    displaySharedContact(
                        shareProposalDetails.participants[i],
                        shareProposalDetails.participants[i].firstName
                            ? `${shareProposalDetails.participants[i].firstName} ${shareProposalDetails.participants[i].lastName}`
                            : shareProposalDetails.participants[i].email,
                        shareProposalDetails.participants[i].accessLabel,
                        userDetails?.contact?.id === shareProposalDetails.participants[i].contactId ? true : false
                    )
                );
            }
        }
        if (shareProposalDetails.contactRoles) {
            for (let i = 0; i < shareProposalDetails.contactRoles.length; i++) {
                records.push(
                    displaySharedContact(
                        shareProposalDetails.contactRoles[i],
                        shareProposalDetails.contactRoles[i].firstName
                            ? `${shareProposalDetails.contactRoles[i].firstName} ${shareProposalDetails.contactRoles[i].lastName}`
                            : shareProposalDetails.contactRoles[i].email,
                        "Owner",
                        true
                    )
                );
            }
        }

        return records;
    };

    const getSharedContactsEmail = () => {
        let records = [];
        if (shareProposalDetails.participants) {
            for (let i = 0; i < shareProposalDetails.participants.length; i++) {
                records.push(records.push(shareProposalDetails.participants[i].email));
            }
        }
        if (shareProposalDetails.contactRoles) {
            for (let i = 0; i < shareProposalDetails.contactRoles.length; i++) {
                records.push(records.push(shareProposalDetails.contactRoles[i].email));
            }
        }

        return records;
    };

    const handlePollEnabledChanged = (value) => {
        let newValue = { ...pollDetailLocal, pollEnabled: value };
        setPollDetailLocal(newValue);
    };

    const handleVoteCountChanged = (value) => {
        let newValue = { ...pollDetailLocal, noOfVote: value };
        setPollDetailLocal(newValue);
    };

    return (
        <>
            <Box className="header">
                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                    Share this proposal with your team members. Viewers are limited to browsing the proposed speakers
                    and vote on their preferred speakers if you enable polls for this proposal.
                </Typography>
            </Box>
            <Divider sx={{ my: ".8rem", mx: "-1rem" }}></Divider>
            <ShareAutocomplete
                options={getAutocompleteOptions()}
                onAddClick={handleAddClick}
                selectedEmails={getSharedContactsEmail()}></ShareAutocomplete>
            <Box sx={{ my: "1rem", maxHeight: "40vh", overflow: "scroll" }}>{getSharedContacts()}</Box>
            <Divider sx={{ my: ".8rem", mx: "-1rem" }}></Divider>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <Typography variant="h6">Enable Proposed Speaker Poll</Typography>
                    <Switch
                        defaultChecked={false}
                        checked={pollDetailLocal?.pollEnabled}
                        onChange={(event) => handlePollEnabledChanged(event.target.checked)}
                        color="brand"
                    />
                </Box>
                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                    Polls make it easy for you to collect your team’s preferences on proposed speakers. Select the
                    number of votes each person gets to cast. Poll results are only visible to collaborators.
                </Typography>
            </Box>
            <Box sx={{ mt: "1rem" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <Typography variant="h6" sx={{ fontWeight: "400", fontSize: "1rem" }}>
                        Number of votes per participant
                    </Typography>
                    <QuantityInput
                        value={pollDetailLocal.noOfVote}
                        min={1}
                        max={10}
                        onKeyup={(event, newValue) => handleVoteCountChanged(newValue)}
                        onChange={(event, newValue) => handleVoteCountChanged(newValue)}></QuantityInput>
                </Box>
            </Box>
        </>
    );
});
