import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordion = (props) => {
    const { summary, details, defaultExpanded } = props;

    return (
        <Accordion
            sx={{
                width: "100%",
                bgcolor: "white",
                borderRadius: 2,
                m: 0,
                p: 0,
                background: "#f8f8f8",
                "& .MuiAccordionSummary-content": {
                    m: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    m: 0,
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "48px",
                },
                "& .MuiAccordionDetails-root": {
                    background: "white",
                },
            }}
            defaultExpanded={defaultExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ m: 0 }}
                aria-controls="panel1-content"
                id="panel1-header">
                {summary}
            </AccordionSummary>
            <AccordionDetails>{details}</AccordionDetails>
        </Accordion>
    );
};

export { CustomAccordion };
