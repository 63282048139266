import { Routes, Route } from "react-router-dom";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { NoMatch } from "../pages/NoMatch";
import { ProtectedLayout } from "../layouts/ProtectedLayout";
import { PublicLayout } from "../layouts/PublicLayout";
import { Signout } from "../pages/Signout";
import { Answer } from "../pages/Answer";
import { ListPage } from "../pages/ListPage";
import { DetailPage } from "../pages/DetailPage";
import { Proposal } from "../pages/Proposal";

import { SharedProposal } from "../pages/SharedProposal";
import { Contact } from "../pages/Contact";
import { Events } from "../pages/Events";
import { EventDetail } from "../pages/EventDetail";
import { Help } from "../pages/Help";
import { Calendar } from "../pages/Calendar";
import { SpeakerHolds } from "../pages/SpeakerHolds";
import { RequiredLoginLayout } from "../layouts/RequiredLoginLayout";
import { Document } from "../pages/Document";
import { FormContainer } from "../pages/FormContainer";
import { SpeakerSearch } from "../pages/SpeakerSearch";
import { ContactDetails } from "../pages/ContactDetails";
import { Dashboard } from "../pages/Dashboard";
import { useAuth } from "../hooks/useAuth";
import RequestToBookPage from "../pages/RequestToBookPage";

const Router = ({ application }) => {
    const { user, isAuthenticated, getUserDetailsNotAsync } = useAuth();
    const userData = getUserDetailsNotAsync();
    if (application === "preview") {
        return (
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="/login" element={<Login application={application} />} />
                    <Route path="/answer" element={<Answer application={application} />}></Route>
                    <Route
                        path="/share/event-detail/:recordId"
                        element={
                            <DashboardLayout application={application} tab="my-events" publicPage={true}>
                                <EventDetail shared={true} />
                            </DashboardLayout>
                        }
                    />
                    <Route path="/signout" element={<Signout />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
                <Route element={<RequiredLoginLayout />}>
                    <Route
                        path="/preview/proposal/:recordId/:proposalId"
                        element={
                            <DashboardLayout application={application} tab="my-proposals" publicPage={true}>
                                <Proposal application={application} />
                            </DashboardLayout>
                        }
                    />
                </Route>
            </Routes>
        );
    } else if (application === "speaker") {
        return (
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="/login" element={<Login application={application} />} />
                    <Route path="/answer" element={<Answer application={application} />}></Route>
                    <Route
                        path="/share/event-detail/:recordId"
                        element={
                            <DashboardLayout application={application} tab="my-events" publicPage={true}>
                                <EventDetail shared={true} />
                            </DashboardLayout>
                        }
                    />
                    <Route path="/signout" element={<Signout />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>

                <Route path="/" element={<ProtectedLayout />}>
                    <Route
                        path="/"
                        element={
                            <DashboardLayout application={application} tab="my-events">
                                <Events />
                            </DashboardLayout>
                        }></Route>
                    <Route
                        path="/event-detail/:recordId"
                        element={
                            <DashboardLayout application={application} tab="my-events">
                                <EventDetail />
                            </DashboardLayout>
                        }></Route>
                    <Route
                        path="/help"
                        element={
                            <DashboardLayout application={application} tab="help">
                                <Help application={application} />
                            </DashboardLayout>
                        }></Route>
                    <Route
                        path="/calendar"
                        element={
                            <DashboardLayout application={application} tab="calendar">
                                <Calendar />
                            </DashboardLayout>
                        }></Route>
                    <Route
                        path="/speaker-holds"
                        element={
                            <DashboardLayout application={application} tab="speaker-holds">
                                <SpeakerHolds />
                            </DashboardLayout>
                        }></Route>
                    <Route
                        path="/formContainer"
                        element={
                            <DashboardLayout application={application}>
                                <FormContainer />
                            </DashboardLayout>
                        }></Route>
                    <Route path="*" element={<NoMatch />}></Route>
                </Route>
                <Route element={<RequiredLoginLayout />}>
                    <Route
                        path="/shared/event-detail/:recordId"
                        element={
                            <DashboardLayout application={application} tab="my-events" publicPage={true}>
                                <EventDetail />
                            </DashboardLayout>
                        }
                    />
                    <Route
                        path="/shared/formContainer"
                        element={
                            <DashboardLayout application={application} tab="form" publicPage={true}>
                                <FormContainer />
                            </DashboardLayout>
                        }></Route>
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        );
    }
    return (
        <Routes>
            <Route element={<PublicLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/answer" element={<Answer />}></Route>
                <Route path="/signout" element={<Signout />} />

                <Route path="*" element={<NoMatch />} />
            </Route>

            <Route path="/" element={<ProtectedLayout />}>
                <Route
                    path="/"
                    element={
                        userData?.contact?.dashboardEnabled ? (
                            <DashboardLayout application={application} tab="my-dashboard">
                                <Dashboard />
                            </DashboardLayout>
                        ) : (
                            <DashboardLayout application={application} tab="my-proposals">
                                <Home />
                            </DashboardLayout>
                        )
                    }></Route>
                <Route
                    path="/my-proposals"
                    element={
                        <DashboardLayout application={application} tab="my-proposals">
                            <Home />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/proposal/:recordId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals">
                            <Proposal application={application} />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/proposal/:recordId/:proposalId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals">
                            <Proposal application={application} />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/pre-booking-questionnaire/:opportunityId/:recordId/:calendarEntryId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals">
                            <RequestToBookPage application={application} />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/sharedProposal"
                    element={
                        <DashboardLayout application={application}>
                            <SharedProposal />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/contact"
                    element={
                        <DashboardLayout application={application} tab="contact">
                            <Contact />
                        </DashboardLayout>
                    }></Route>
                {userData?.contact?.eventsEnabled && (
                    <Route
                        path="/events"
                        element={
                            <DashboardLayout application={application} tab="my-events">
                                <Events />
                            </DashboardLayout>
                        }></Route>
                )}
                {userData?.contact?.eventsEnabled && (
                    <Route
                        path="/event-detail/:recordId"
                        element={
                            <DashboardLayout application={application} tab="my-events">
                                <EventDetail />
                            </DashboardLayout>
                        }></Route>
                )}
                <Route
                    path="/calendar"
                    element={
                        <DashboardLayout application={application} tab="calendar">
                            <Calendar />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/help"
                    element={
                        <DashboardLayout application={application} tab="help">
                            <Help />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/documents"
                    element={
                        <DashboardLayout application={application} tab="documents">
                            <Document />
                        </DashboardLayout>
                    }></Route>
                <Route
                    path="/formContainer"
                    element={
                        <DashboardLayout application={application}>
                            <FormContainer />
                        </DashboardLayout>
                    }></Route>
                {userData?.contact?.speakerSearchEnabled && (
                    <Route
                        path="/speaker-search"
                        element={
                            <DashboardLayout application={application} tab="speaker-search">
                                <SpeakerSearch application={application} />
                            </DashboardLayout>
                        }></Route>
                )}
                <Route path="*" element={<NoMatch />} />
            </Route>

            <Route element={<RequiredLoginLayout />}>
                <Route
                    path="/preview/proposal/:recordId/:proposalId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals" publicPage={true}>
                            <Proposal application={application} />
                        </DashboardLayout>
                    }
                />
                <Route
                    path="/shared/proposal/:recordId/:proposalId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals" publicPage={true}>
                            <Proposal
                                shared={userData?.userType === "public" ? true : false}
                                application={application}
                            />
                        </DashboardLayout>
                    }
                />
                <Route
                    path="/shared/proposal/:recordId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals" publicPage={true}>
                            <Proposal
                                shared={userData?.userType === "public" ? true : false}
                                application={application}
                            />
                        </DashboardLayout>
                    }
                />
                <Route
                    path="/shared/event-detail/:recordId"
                    element={
                        <DashboardLayout application={application} tab="my-events" publicPage={true}>
                            <EventDetail
                                shared={userData?.userType === "public" ? true : false}
                                application={application}
                            />
                        </DashboardLayout>
                    }
                />
                <Route
                    path="/shared/formContainer"
                    element={
                        <DashboardLayout application={application} tab="form" publicPage={true}>
                            <FormContainer />
                        </DashboardLayout>
                    }></Route>
                <Route path="/contact-details/:recordId/:proposalId" element={<ContactDetails />}></Route>
                <Route path="/contact-details/:recordId" element={<ContactDetails />}></Route>
                <Route
                    path="/share/proposal/:recordId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals" publicPage={true}>
                            <Proposal shared={true} application={application} />
                        </DashboardLayout>
                    }
                />
                <Route
                    path="/share/proposal/:recordId/:proposalId"
                    element={
                        <DashboardLayout application={application} tab="my-proposals" publicPage={true}>
                            <Proposal shared={true} application={application} />
                        </DashboardLayout>
                    }
                />
                <Route
                    path="/share/event-detail/:recordId"
                    element={
                        <DashboardLayout application={application} tab="my-events" publicPage={true}>
                            <EventDetail shared={true} application={application} />
                        </DashboardLayout>
                    }
                />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    );
};

export { Router };
