import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const Modal = (props) => {
    let { onClose, removeTabIndex, open, contentSx, ...other } = props;
    contentSx = contentSx ? contentSx : {};
    useEffect(() => {
        if (open === true && removeTabIndex) {
            setTimeout(() => {
                let red = document.querySelector(".MuiDialog-container");
                if (red) {
                    red.removeAttribute("tabindex");
                }
            }, 100);
        }
    }, [open, removeTabIndex]);

    return (
        <div>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={props.maxWidth}
                {...props}
                tabIndex={false}>
                {!other.hideTitle && (
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                        {other.header ? other.header : props.title}
                    </BootstrapDialogTitle>
                )}
                <DialogContent dividers sx={{ minWidth: "300", overflowX: "hidden", ...contentSx }}>
                    {props.children}
                </DialogContent>
                {props.hideActions ? (
                    ""
                ) : (
                    <DialogActions>
                        {other.actions ? (
                            other.actions
                        ) : (
                            <>
                                <Button onClick={props.onClose}>Cancel</Button>
                                <Button onClick={props.onSave}>Save</Button>
                            </>
                        )}
                    </DialogActions>
                )}
            </BootstrapDialog>
        </div>
    );
};

export { Modal };
